
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import CommunityUser from '@/models/graphql/CommunityUser';
import GenericEvent from '@/utils/types/GenericEvent';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import PollRegisterStore from '@/components/poll/PollRegisterStore';
import PollListComponent from '@/components/poll/PollListComponent.vue';
import Poll from '@/models/graphql/Poll';
import PollAnswer from '@/models/graphql/PollAnswer';
import ActionType from '@/utils/enums/ActionType';
import {
  addDays, addHours, addMinutes, addSeconds, format, getUnixTime,
} from 'date-fns';
import ChoiceParams from '@/utils/types/ChoiceParams';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';
import GUUID from '@/utils/GUUID';
import Community from '@/models/graphql/Community';

/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */

const pollAnswerStore = namespace('PollAnswerStore');
const pollUserAnswerStore = namespace('PollUserAnswerStore');
const notificationStore = namespace('NotificationStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: { PollListComponent },
})
export default class PollComponent extends mixins(VueBaseNotify, PollRegisterStore) {
  @Prop({
    required: false,
    default: null,
  })
  private readonly sessionUid!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isSlideMode!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly sessionId!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly schemaCode!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly teleportMenu!: string | null;

  @Prop({
    required: false,
    default: 'LoadPaginatedPolls',
  })
  private readonly gqlOperationName!: string;

  @Prop({ required: true })
  private readonly storeName!: string;

  storeContext = this.storeName;

  @Prop({
    required: false,
    default: false,
  })
  private hideActions!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private canCreate!: boolean;

  @notificationStore.Mutation
  private unsubscribeGenericEventPointer!: (channel: string) => void;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @permissionManagerStore.Getter
  private canModerate!: boolean;

  @pollAnswerStore.Action(ActionType.DELETE)
  private deletePollAnswer!: (uid: string) => Promise<PollAnswer | undefined>;

  @pollUserAnswerStore.Action(ActionType.CREATE)
  private createPollUserAnswer!: (payload: {
    userUid: string;
    pollAnswerUid: string;
  }) => Promise<PollUserAnswer | undefined>;

  @pollUserAnswerStore.Action(ActionType.DELETE)
  private deletePollUserAnswer!: (payload: {
    pollAnswerUid: string;
  }) => Promise<PollUserAnswer | undefined>;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  private authUser!: CommunityUser;

  @State
  private selectedTzName!: string;

  @Getter
  private community!: Community;

  private componentKey = GUUID.uuidv4();

  private resetList = +new Date();

  private pollSubscriptionChannel = `poll-channel-${this.sessionUid}`;

  private stopSlideLoading = false;

  private setIntervalId: NodeJS.Timer | null = null;

  private now = new Date();

  private get polls(): Poll[] {
    if (!this.isModerate
      && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_AUTO_HIDE_CLOSED_POLLS)
      && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_AUTO_HIDE_CLOSED_POLLS).enabled) {
      return this.getter<Poll[]>('polls')
        .filter((poll) => this.canShowPoll(poll));
    }
    return this.getter<Poll[]>('polls')
      .filter((poll) => this.isModerate || this.showPublishedPoll(poll));
  }

  private get isModerate(): boolean {
    return this.canModerate
      || (!!this.authUser
        && this.authUser.speakers.length > 0
        && this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE).enabled);
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.POLL_PUBLISH,
      NotificationEventType.POLL_DELETE,
      NotificationEventType.POLL_CLOSE,
      NotificationEventType.POLL_ANSWER,
      NotificationEventType.POLL_RESET,
    ];
    this.setIntervalId = setInterval(() => {
      this.now = DateTimeHelper.getCurrentDateTime();
    }, 950);
  }

  notificationCallback(event: GenericEvent): void {
    if (event) {
      const extraData = JSON.parse(event.extra || '{}');
      if (extraData && extraData.componentKey !== this.componentKey) {
        if (event.type === NotificationEventType.POLL_ANSWER) {
          const pollAnswer = extraData.pollAnswer ? PollAnswer.hydrate(extraData.pollAnswer) : null;
          const { myAnswer } = extraData;
          const { pollUid } = extraData;
          const oldPollAnswer = extraData.oldPollAnswer ? PollAnswer.hydrate(extraData.oldPollAnswer) : null;
          if (this.authUser && this.authUser.uid === extraData.user.uid) {
            this.commit('setMyAnswer', {
              pollUid,
              myAnswer,
              myOldAnswer: oldPollAnswer,
            });
          } else {
            this.commit('answerUpdate', {
              pollUid: event.entityId,
              pollAnswer,
              oldPollAnswer,
            });
          }
        } else if (event.type === NotificationEventType.POLL_PUBLISH) {
          this.resetList += 1;
        } else if (event.type === NotificationEventType.POLL_CLOSE) {
          this.commit('closePoll', event.entityId);
        } else if (event.type === NotificationEventType.POLL_DELETE) {
          this.commit('removePoll', event.entityId);
        } else if (event.type === NotificationEventType.POLL_RESET) {
          this.commit('resetPoll', event.entityId);
        }
      }
    }
  }

  mounted(): void {
    if (!this.sessionUid && this.sessionId && this.schemaCode) {
      this.pollSubscriptionChannel = `poll-channel-${this.sessionId}`;
    }
    this.genericEvent({ channel: this.pollSubscriptionChannel });
  }

  beforeDestroy(): void {
    this.unsubscribeGenericEventPointer(this.pollSubscriptionChannel);
    if (this.setIntervalId) {
      clearInterval(this.setIntervalId);
    }
  }

  private showPublishedPoll(poll: Poll): boolean {
    return !this.isModerate && !!(poll.startTime || poll.startTimestamp);
  }

  private isPollClosed(poll: Poll): boolean {
    if (!poll.endTime && poll.startTime) {
      return false;
    }
    const endTime = poll.endTime ? DateTimeHelper.toLocal(new Date(poll.endTime)) : null;
    if (endTime) {
      return this.now > endTime;
    }
    return true;
  }

  private canShowPoll(poll: Poll): boolean {
    if (this.isPollClosed(poll) && poll.startTime && poll.endTime && poll.session) {
      let sessionStartTime = DateTimeHelper.utcToZonedTimeDate(
        `${poll.session.startTime}Z`,
        this.selectedTzName,
      );
      let sessionEndTime = DateTimeHelper.utcToZonedTimeDate(
        `${poll.session.endTime}Z`,
        this.selectedTzName,
      );
      if (poll.session.parentSession) {
        sessionStartTime = DateTimeHelper.utcToZonedTimeDate(
          `${poll.session.parentSession.startTime}Z`,
          this.selectedTzName,
        );
        sessionEndTime = DateTimeHelper.utcToZonedTimeDate(
          `${poll.session.parentSession.endTime}Z`,
          this.selectedTzName,
        );
      }
      const pollStartTime = DateTimeHelper.toLocal(new Date(poll.startTime));
      const pollEndTime = DateTimeHelper.toLocal(new Date(poll.endTime));
      const isPollStartDateInRange = sessionStartTime.getTime() <= pollStartTime.getTime()
        && pollStartTime.getTime() <= sessionEndTime.getTime();
      const isPollEndDateInRange = sessionStartTime.getTime() <= pollEndTime.getTime()
        && pollEndTime.getTime() <= sessionEndTime.getTime();
      return isPollStartDateInRange && isPollEndDateInRange;
    }
    return !(!poll.startTime && !poll.endTime);
  }

  // eslint-disable-next-line class-methods-use-this
  private triggerGenericEventChannels(poll: Poll): string[] {
    const channels = [`poll-channel-${poll.session?.uid}`];
    if (poll.session?.parentSession) {
      channels.push(`poll-channel-${poll.session.parentSession.uid}`);
    }
    return channels;
  }

  private onCreateEdit(pollUid: string, sessionId: string, parentSessionId: string): void {
    this.triggerGenericEvent({
      channels: [`poll-channel-${sessionId}`, `poll-channel-${parentSessionId}`],
      type: NotificationEventType.POLL_PUBLISH,
      entityId: pollUid,
      extra: JSON.stringify({
        user: this.authUser.uid,
        componentKey: this.componentKey,
      }),
    });
    this.resetList += 1;
  }

  private onPublish({
    selectedPoll,
    duration,
  }: {
    selectedPoll: Poll;
    duration: {
      days: ChoiceParams;
      hours: ChoiceParams;
      minutes: ChoiceParams;
      seconds: ChoiceParams;
      chooseTime: boolean;
      showLiveAnswer: boolean;
    };
  }): void {
    if (selectedPoll) {
      const startTime = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
      const endTime = this.getPollEndTime(startTime, duration);
      const poll = {
        uid: selectedPoll.uid,
        realTimeAnswers: duration.showLiveAnswer,
      } as Poll;
      poll.startTime = format(startTime, DateTimeHelper.TIME_FORMAT_ISO_8601);
      poll.endTime = endTime
        ? format(addSeconds(endTime, this.community?.votingBufferTime ?? 0), DateTimeHelper.TIME_FORMAT_ISO_8601) : null;
      const localPollIndex = this.polls.findIndex((p) => p.uid === poll.uid);
      if (localPollIndex >= 0) {
        this.commit('editPoll', {
          uid: selectedPoll.uid,
          startTime: poll.startTime,
          endTime: poll.endTime,
          startTimestamp: getUnixTime(startTime),
          endTimestamp: endTime ? getUnixTime(endTime) : null,
          realTimeAnswers: duration.showLiveAnswer,
        });
      }
      this.dispatch<Poll | undefined>('update', poll)
        .then((publishedPoll) => {
          if (publishedPoll) {
            this.commit('editPoll', publishedPoll);
            this.triggerGenericEvent({
              channels: this.triggerGenericEventChannels(publishedPoll),
              type: NotificationEventType.POLL_PUBLISH,
              entityId: publishedPoll.uid,
              extra: JSON.stringify({
                user: this.authUser.uid,
                componentKey: this.componentKey,
              }),
            });
          }
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getPollEndTime(startTime: Date, duration: {
    days: ChoiceParams;
    hours: ChoiceParams;
    minutes: ChoiceParams;
    seconds: ChoiceParams;
    chooseTime: boolean;
  }): Date | null {
    if (!duration.chooseTime) {
      return null;
    }
    return addDays(
      addHours(
        addMinutes(
          addSeconds(
            startTime,
            parseInt(duration.seconds.value, 10),
          ),
          parseInt(duration.minutes.value, 10),
        ),
        parseInt(duration.hours.value, 10),
      ),
      parseInt(duration.days.value, 10),
    );
  }

  private onReset(poll: Poll): void {
    const localPoll = JSON.parse(JSON.stringify(poll));
    this.commit('resetPoll', poll.uid);
    this.dispatch<boolean>('reset', localPoll)
      .then((success) => {
        if (success) {
          this.triggerGenericEvent({
            channels: this.triggerGenericEventChannels(poll),
            type: NotificationEventType.POLL_RESET,
            entityId: poll.uid,
            extra: JSON.stringify({
              componentKey: this.componentKey,
            }),
          });
        }
      });
  }

  private onClose(poll: Poll): void {
    this.commit('closePoll', poll.uid);
    this.dispatch<Poll | undefined>('update', {
      uid: poll.uid,
      endTime: format(
        DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
        DateTimeHelper.TIME_FORMAT_ISO_8601,
      ),
    })
      .then((closedPoll) => {
        if (closedPoll) {
          this.triggerGenericEvent({
            channels: this.triggerGenericEventChannels(closedPoll),
            type: NotificationEventType.POLL_CLOSE,
            entityId: closedPoll.uid,
            extra: JSON.stringify({
              endTime: closedPoll.endTime,
              endTimestamp: closedPoll.endTimestamp,
              componentKey: this.componentKey,
            }),
          });
        }
      });
  }

  private async onAnswer({
    poll,
    pollAnswer,
  }: { poll: Poll; pollAnswer: PollAnswer }): Promise<void> {
    let oldPollAnswer: PollAnswer | undefined;
    const localPoll = this.polls.find((p) => p.uid === poll.uid);
    // delete my previous answers
    if (localPoll && localPoll._myAnswer && localPoll._myAnswer.length > 0) {
      oldPollAnswer = localPoll._myAnswer[0].pollAnswer;
      await Promise.all(localPoll._myAnswer.map((answer) => {
        if (answer.uid) {
          return this.deletePollUserAnswer({
            pollAnswerUid: answer.uid,
          });
        }
        return Promise.resolve(undefined);
      }));
    }

    this.createPollUserAnswer({
      userUid: this.authUser.uid,
      pollAnswerUid: pollAnswer.uid,
    })
      .then((pollUserAnswer) => {
        if (pollUserAnswer && pollUserAnswer.pollAnswer) {
          if (localPoll) {
            this.commit('setMyAnswer', {
              pollUid: poll.uid,
              myAnswer: pollUserAnswer,
              myOldAnswer: oldPollAnswer,
            });
          }
          this.triggerGenericEvent({
            channels: this.triggerGenericEventChannels(poll),
            type: NotificationEventType.POLL_ANSWER,
            entityId: poll.uid,
            extra: JSON.stringify({
              componentKey: this.componentKey,
              user: {
                uid: this.authUser.uid,
                firstName: this.authUser.firstName,
                lastName: this.authUser.lastName,
                pictureFileResource: this.authUser.pictureFileResource,
              },
              pollUid: poll.uid,
              pollAnswer,
              myAnswer: pollUserAnswer,
              oldPollAnswer: oldPollAnswer || null,
            }),
          });
        }
      });
  }

  private onDelete(poll: Poll): void {
    const answerPromises: Promise<PollAnswer | undefined>[] = [];
    if (poll.pollAnswers && poll.pollAnswers.length > 0) {
      poll.pollAnswers.forEach((pollAnswer) => {
        answerPromises.push(this.deletePollAnswer(pollAnswer.uid));
      });
    }
    this.commit('removePoll', poll.uid);
    if (answerPromises.length > 0) {
      Promise.all(answerPromises)
        .then(() => this.dispatch<Poll | undefined>('delete', poll.uid))
        .then(() => {
          this.triggerGenericEvent({
            channels: this.triggerGenericEventChannels(poll),
            type: NotificationEventType.POLL_DELETE,
            entityId: poll.uid,
            extra: JSON.stringify({
              componentKey: this.componentKey,
            }),
          });
        });
    }
  }

  private resetStates(): void {
    this.dispatch<void>('resetStates');
  }

  private loadPolls(infiniteLoading: Vue): void {
    const filter = {
      pollAnswers: { uid_not: null },
      ...(!this.isModerate ? { startTimestamp_not: null } : {}),
      OR: this.sessionId && this.schemaCode
        ? [
          {
            session: {
              id: parseInt(this.sessionId, 10),
              schemaCode: this.schemaCode,
            },
          },
          {
            session: {
              parentSession: {
                id: parseInt(this.sessionId, 10),
                schemaCode: this.schemaCode,
              },
            },
          },
        ]
        : [
          { session: { uid: this.sessionUid } },
          { session: { parentSession: { uid: this.sessionUid } } },
        ],
    };
    this.dispatch<number | null>('loadPolls', {
      operationName: this.gqlOperationName,
      filter,
      authUser: this.authUser?.uid,
      usePagination: !!infiniteLoading,
    })
      .then((pollsCount) => {
        if (pollsCount === this.state<number>('itemsPerPage')) {
          if (!this.isSlideMode) {
            infiniteLoading.$emit('$InfiniteLoading:loaded');
          }
        } else if (!this.isSlideMode) {
          infiniteLoading.$emit('$InfiniteLoading:complete');
        } else {
          this.stopSlideLoading = true;
        }
      })
      .catch(() => {
        if (!this.isSlideMode) {
          infiniteLoading.$emit('$InfiniteLoading:complete');
        } else {
          this.stopSlideLoading = true;
        }
      });
  }
}
