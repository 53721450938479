import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import CommunityUser from '@/models/graphql/CommunityUser';
import { BasicTypes } from '@/utils/types/BasicTypes';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
/* eslint-disable @typescript-eslint/camelcase */
@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ExhibitorPortalUserDetailChatWidgetStore extends WidgetBaseStore {
  chatReps: CommunityUser[] = [];

  creatorUser: CommunityUser | null = null;

  private memberUid: string | null = null;

  private companyUid: string | null = null;

  @Action
  setDataConfigs(): void {
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    if (this.payload && this.payload.memberUid && this.payload.companyUid) {
      this.context.commit('setMemberId', WidgetHelper.entityCode(
        this.payload.memberUid as string,
        args.route as unknown as Location,
        null,
      ));
      this.context.commit('setCompanyId', WidgetHelper.entityCode(
        this.payload.companyUid as string,
        args.route as unknown as Location,
        null,
      ));
      configs.push(...[{
        alias: `${this.widgetStoreName}_chatReps`,
        operation: 'communityUser',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
            value: {
              companyRoles: {
                company: {
                  uid: this.companyUid,
                },
                OR: [
                  { role: { code: 'owner' } },
                  {
                    state: 'ACCEPTED',
                    role: { code: 'administrator' },
                    moderator: true,
                  },
                ],
              },
            },
          },
        }),
        fragmentName: 'communityUserBaseFragment',
      },
      {
        alias: `${this.widgetStoreName}_creatorUser`,
        operation: 'communityUser',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
            value: { uid: this.memberUid },
          },
        }),
        fragmentName: 'communityUserBaseFragment',
      }]);
    }
    this.context.commit('setGqlQueryConfig', configs);
    this.context.commit(
      'WidgetDispatcherStore/setGqlQueryConfigs',
      {
        key: this.widgetStoreName,
        values: configs,
      },
      { root: true },
    );
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      chatReps: this.chatReps,
      creatorUser: this.creatorUser,
      companyUid: this.companyUid,
      memberUid: this.memberUid,
    };
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.key.endsWith('_chatReps')) {
      this.chatReps = data.values as unknown as Array<CommunityUser>;
    }
    if (data.key.endsWith('_creatorUser') && data.values.length > 0) {
      this.creatorUser = data.values[0] as unknown as CommunityUser;
    }
  }

  @Mutation
  setCompanyId(val: string): void {
    this.companyUid = val;
  }

  @Mutation
  setMemberId(val: string): void {
    this.memberUid = val;
  }
}
