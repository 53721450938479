import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import Exhibitor from '@/models/graphql/Exhibitor';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import Category from '@/models/graphql/Category';
import EntityType from '@/utils/enums/EntityType';
import Product from '@/models/graphql/Product';
import Session from '@/models/graphql/Session';
import Speaker from '@/models/graphql/Speaker';
import Deal from '@/models/graphql/Deal';
import SubEdition from '@/models/graphql/SubEdition';
import CommunityUser from '@/models/graphql/CommunityUser';
import Handout from '@/models/graphql/Handout';
import LargeProduct from '@/models/graphql/LargeProduct';
import SponsorRole from '@/models/graphql/SponsorRole';
import Sponsor from '@/models/graphql/Sponsor';
import Topic from '@/models/graphql/Topic';
import { DataWithNull } from '@/utils/types/WidgetDataWithNull';
import SalesPackage from '@/models/graphql/SalesPackage';
import Article from '@/models/graphql/Article';
import Channel from '@/models/graphql/Channel';
import Geozone from '@/models/graphql/Geozone';
import ExhibitHall from '@/models/graphql/ExhibitHall';

/* eslint-disable no-underscore-dangle */
export default class EntityListWidgetHelper {
  static mapData = (data: DataWithNull[]): DataWithNull[] => {
    const list: DataWithNull[] = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        switch (item.__typename) {
          case EntityType.ARTICLE:
            list.push(EntityListWidgetHelper.articleMapData(item as unknown as Article));
            break;
          case EntityType.EXHIBITOR:
            list.push(EntityListWidgetHelper.exhibitorMapData(item as unknown as Exhibitor));
            break;
          case EntityType.PRODUCT:
            list.push(EntityListWidgetHelper.productMapData(item as unknown as Product));
            break;
          case EntityType.LARGE_PRODUCT:
            list.push(EntityListWidgetHelper.largeProductMapData(item as unknown as LargeProduct));
            break;
          case EntityType.SESSION:
            list.push(EntityListWidgetHelper.sessionsMapData(item as unknown as Session));
            break;
          case EntityType.SUB_EDITION:
            list.push(EntityListWidgetHelper.subEditionMapData(item as unknown as SubEdition));
            break;
          case EntityType.DEAL:
            list.push(EntityListWidgetHelper.dealMapData(item as unknown as Deal));
            break;
          case EntityType.SPEAKER:
            list.push(EntityListWidgetHelper.speakerMapData(item as unknown as Speaker));
            break;
          case EntityType.USER:
            list.push(EntityListWidgetHelper.communityUserMapData(item as unknown as CommunityUser));
            break;
          case EntityType.HANDOUT:
            list.push(EntityListWidgetHelper.handoutMapData(item as unknown as Handout));
            break;
          case EntityType.SPONSOR:
            list.push(EntityListWidgetHelper.sponsorMapData(item as unknown as SponsorRole));
            break;
          case EntityType.SPONSOR_ROLE:
            list.push(EntityListWidgetHelper.sponsorRoleMapData(item as unknown as SponsorRole));
            break;
          case EntityType.TOPIC:
            list.push(EntityListWidgetHelper.topicMapData(item as unknown as Topic));
            break;
          case EntityType.CHANNEL:
            list.push(EntityListWidgetHelper.channelMapData(item as unknown as Channel));
            break;
          case EntityType.SALES_PACKAGE:
            list.push(EntityListWidgetHelper.salesPackageMapData(item as unknown as SalesPackage));
            break;
          case EntityType.CATEGORY:
            list.push(EntityListWidgetHelper.categoryMapData(item as unknown as Category));
            break;
          case EntityType.GEOZONE:
            list.push(EntityListWidgetHelper.geozoneMapData((item as unknown as Geozone)));
            break;
          default:
            break;
        }
      });
    }
    return list;
  };

  static articleMapData(item: Article): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      articleSubTitle: item.subtitle as string,
      logo: item.images.length > 0 ? FileResourceHelper.getFullPath(item.images[0] as FileResource, 'w40') : null,
      type: EntityType.ARTICLE,
      checkbox: false,
      bookmarked: item._isBookmarked as (string | null),
      followed: item._isFollowed as (string | null),
    };
  }

  static exhibitorMapData(item: Exhibitor): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      categories: (item.categoriesInContext as Category[] || []).map((c) => c.name)
        .join(', ') as string,
      logo: item.logoFileResource ? FileResourceHelper.getFullPath(item.logoFileResource as FileResource, 'w40') : null,
      type: EntityType.EXHIBITOR,
      checkbox: false,
      bookmarked: item._isBookmarked as (string | null),
      followed: item._isFollowed as (string | null),
      visited: item._isVisited as (string | null),
      actions: item._actions || null,
    };
  }

  static productMapData(item: Product): DataWithNull {
    let image = '';
    if (item.images && item.images.length > 0 && item.images[0].fullFileResource) {
      image = FileResourceHelper
        .getFullPath(item.images[0].fullFileResource as FileResource, 'w40');
    }
    return {
      uid: item.uid as string,
      title: item.name as string,
      companyId: item.exhibitor ? item.exhibitor.uid : null,
      company: item.exhibitor && item.exhibitor.name ? item.exhibitor.name as string : null,
      logo: image,
      type: EntityType.PRODUCT,
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static largeProductMapData(item: LargeProduct): DataWithNull {
    let image = '';
    if (item.images && item.images.length > 0 && item.images[0].fullFileResource) {
      image = FileResourceHelper
        .getFullPath(item.images[0].fullFileResource as FileResource, 'w40');
    }
    return {
      uid: item.uid as string,
      title: item.name as string,
      companyId: item.exhibitor ? item.exhibitor.uid : null,
      company: item.exhibitor && item.exhibitor.name ? item.exhibitor.name as string : null,
      logo: image,
      type: EntityType.LARGE_PRODUCT,
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static sessionsMapData(item: Session): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      startTimestamp: item.startTimestamp as number,
      endTimestamp: item.endTimestamp as number,
      logo: item.imageFileResource ? FileResourceHelper.getFullPath(item.imageFileResource as FileResource, 'w40') : null,
      type: 'Session',
      bookmarked: item._isBookmarked as (string | null),
      agenda: item._isInAgenda as (string | null),
      actions: item._actions || null,
    };
  }

  static subEditionMapData(item: SubEdition): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      startTimestamp: item.startTimestamp as number,
      endTimestamp: item.endTimestamp as number,
      code: item.code as string,
      logo: item.logoFileResource ? FileResourceHelper.getFullPath(item.logoFileResource as FileResource, 'w40') : null,
      type: EntityType.SUB_EDITION,
      bookmarked: item._isBookmarked as (string | null),
      followed: item._isFollowed as (string | null),
      archived: item.archived as boolean,
    };
  }

  static dealMapData(item: Deal): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      companyId: item.exhibitor ? item.exhibitor.uid : null,
      startTimestamp: item.startTimestamp as number,
      endTimestamp: item.endTimestamp as number,
      logo: item.displayFileResource ? FileResourceHelper.getFullPath(item.displayFileResource as FileResource, 'w40') : null,
      type: EntityType.DEAL,
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static speakerMapData(item: Speaker): DataWithNull {
    return {
      uid: item.uid as string,
      title: `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`,
      jobTitle: `${item.jobTitle ? item.jobTitle : ''}`,
      employerName: `${item.employerName ? item.employerName : ''}`,
      firstName: `${item.firstName ? item.firstName : ''}`,
      lastName: `${item.lastName ? item.lastName : ''}`,
      logo: item.photoFileResource
        ? FileResourceHelper.getFullPath(item.photoFileResource as FileResource, 'w40') : null,
      type: EntityType.SPEAKER,
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static communityUserMapData(item: CommunityUser): DataWithNull {
    let companyName = '';
    const companyRoles: CompanyUserRole[] = item.companyRoles as CompanyUserRole[];
    if (companyRoles && companyRoles.length > 0) {
      const selectedCompanyUserRole = companyRoles
        .find((companyRole: CompanyUserRole) => companyRole.defaultCompany);
      if (selectedCompanyUserRole
        && selectedCompanyUserRole.company
        && selectedCompanyUserRole.company.name) {
        companyName = (selectedCompanyUserRole as CompanyUserRole).company?.name as string;
      }
    }
    return {
      uid: item.uid as string,
      title: `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`,
      jobTitle: `${item.jobTitle ? item.jobTitle : ''}`,
      employerName: `${item.employerName ? item.employerName : ''}`,
      firstName: `${item.firstName ? item.firstName : ''}`,
      lastName: `${item.lastName ? item.lastName : ''}`,
      logo: item.pictureFileResource
        ? FileResourceHelper.getFullPath(item.pictureFileResource as FileResource, 'w40') : null,
      isProfileVisible: CommunityUser.hydrate(item).isProfileVisible,
      type: EntityType.USER,
      companyName,
      ourConnection: item._ourConnection ? item._ourConnection : null,
      actions: item._actions || null,
      followed: item._isFollowed as (string | null),
    };
  }

  static handoutMapData(item: Handout): DataWithNull {
    const fileResource = item.handoutFileResource as FileResource;
    return {
      uid: item.uid as string,
      title: `${item.name}`,
      fileUrl: `${item.fileUrl}`,
      type: EntityType.HANDOUT,
      fileResourcePath: fileResource && fileResource.path
        ? FileResourceHelper.getFullPath(fileResource, '', '', true)
        : 'null',
    };
  }

  static sponsorMapData(item: Sponsor): DataWithNull {
    const fileResource = item.logoFileResource as FileResource;
    return {
      uid: item.uid as string,
      title: `${item.name}`,
      fileUrl: `${item.url}`,
      type: EntityType.SPONSOR,
      logo: fileResource
        ? FileResourceHelper.getFullPath(fileResource as FileResource, 'w72') : null,
    };
  }

  static sponsorRoleMapData(item: SponsorRole): DataWithNull {
    const fileResource = item.sponsor.logoFileResource as FileResource;
    return {
      uid: item.sponsor.uid as string,
      title: `${item.sponsor.name}`,
      companySubTitle: `${item.sponsor.description}`,
      fileUrl: `${item.sponsor.url}`,
      type: EntityType.SPONSOR,
      logo: fileResource
        ? FileResourceHelper.getFullPath(fileResource as FileResource, 'w72') : null,
    };
  }

  static topicMapData(item: Topic): DataWithNull {
    const fileResource = item.imageFileResource as FileResource;
    return {
      uid: item.uid,
      title: `${item.name}`,
      companySubTitle: `${item.description}`,
      type: EntityType.TOPIC,
      logo: fileResource
        ? FileResourceHelper.getFullPath(fileResource as FileResource, 'w72') : null,
      followed: item._isFollowed as (string | null),
    };
  }

  static channelMapData(item: Channel): DataWithNull {
    const fileResource = item.imageFileResource as FileResource;
    return {
      uid: item.uid,
      title: `${item.name}`,
      type: EntityType.CHANNEL,
      logo: fileResource
        ? FileResourceHelper.getFullPath(fileResource as FileResource, 'w72') : null,
      followed: item._isFollowed as (boolean | null),
      bookmarked: item._isBookmarked as (boolean | null),
    };
  }

  static salesPackageMapData(item: SalesPackage): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.title as string,
      logo: item.fileResource ? FileResourceHelper.getFullPath(item.fileResource as FileResource, 'w40') : null,
      type: EntityType.SALES_PACKAGE,
    };
  }

  static categoryMapData(item: Category): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      genericSubTitle: item.description || '',
      logo: item.bannerFileResource ? FileResourceHelper.getFullPath(item.bannerFileResource as FileResource, 'w40') : null,
      type: EntityType.CATEGORY,
    };
  }

  static geozoneMapData(item: Geozone): DataWithNull {
    let title = '';
    let description = '';
    const { booth } = item;
    if (item.exhibitHall) {
      title += item.exhibitHall.name;
    }
    if (booth) {
      title += item.objectType === 'Room'
        ? ` · Room ${booth.number}`
        : ` · Booth ${booth.number}`;
    }
    if (item.objectType === 'Booth') {
      if (booth) {
        const { exhibitors } = booth;
        const { largeProducts } = booth;
        if (exhibitors && exhibitors.length > 0) {
          description += `${exhibitors[0].name}`;
          description += exhibitors.length > 1 ? ` + ${exhibitors.length - 1} more` : '';
        } else if (largeProducts && largeProducts.length > 0) {
          description += `${largeProducts[0].name}`;
          description += largeProducts.length > 1 ? ` + ${largeProducts.length - 1} more` : '';
        }
      }
    }

    return {
      id: item.id as number,
      uid: item.uid as string,
      title,
      genericSubTitle: description as string,
      objectType: item.objectType as string,
      exhibitHall: item.exhibitHall as ExhibitHall,
      geozoneSessions: item.sessions as Session[],
      logo: null,
      type: EntityType.GEOZONE,
    };
  }
}
