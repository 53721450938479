









































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import Order from '@/models/graphql/Order';
import { Data } from '@/utils/types/WidgetData';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { formatInTimeZone } from 'date-fns-tz';
import { namespace, State } from 'vuex-class';
import OrderStatus from '@/utils/enums/OrderStatus';
import FormatHelper from '@/utils/helpers/FormatHelper';
import StripeIntentStatusEnum from '@/utils/enums/StripeIntentStatusEnum';
import OrderPaymentCharge from '@/models/graphql/OrderPaymentCharge';

const orderStore = namespace('OrderStore');

@Component({
  components: { ButtonComponent },
})
export default class OrderCompletedWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: () => null })
  protected readonly data!: Data[];

  @Prop({ required: false, default: () => null })
  protected readonly uid!: string;

  @State
  private dateLocale!: Locale;

  @orderStore.Getter('order')
  private stateOrder!: Order;

  @orderStore.Action
  private getPaymentIntentCharge!: (payload: { orderUid: string }) => Promise<OrderPaymentCharge>;

  private orderStatus = OrderStatus;

  private entityCode = '';

  private paymentCharge: OrderPaymentCharge | null = null;

  get paymentStatementDescriptor(): string {
    return this.paymentCharge?.calculated_statement_descriptor || 'Sherpa Capital inc.';
  }

  private get order(): Order | null {
    if (this.validateStripeStatus() === StripeIntentStatusEnum.SUCCEEDED) {
      if (this.stateOrder) {
        this.stateOrder.status = OrderStatus.PAID;
      }
    }
    if (this.stateOrder) return this.stateOrder;
    if (this.data && this.data.length > 0) {
      return Order.hydrate(this.data[0]);
    }
    return null;
  }

  private get formattedCreatedTime(): string {
    if (this.order && this.order.creationTime) {
      const creationTime = DateTimeHelper
        .toLocal(new Date((this.order.creationTime as unknown) as string));
      return `${formatInTimeZone(creationTime, DateTimeHelper.currentTimeZone(), 'PP', { locale: this.dateLocale })}`;
    }
    return '';
  }

  private get orderNumber(): string {
    if (!this.order) return '';
    return FormatHelper.formatOrderNumber(this.order.id || 0);
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode) {
      this.entityCode = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'order',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.ORDER_FILTER,
            value: {
              uid: this.entityCode,
            },
          },
        }),
        fragmentName: 'orderBaseFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  @Watch('data')
  setPaymentIntentCharge(): void {
    if (this.order?.paymentIntentId) {
      this.getPaymentIntentCharge({ orderUid: this.entityCode })
        .then((paymentCharge: OrderPaymentCharge) => {
          this.paymentCharge = paymentCharge;
        });
    }
  }

  private onContinueShoppingClick(): void {
    this.$router.push({
      name: 'cms-package-list',
      params: { companyId: this.$route.params.companyId },
    });
  }

  private onOrderDetailViewClick(): void {
    this.$router.push({
      name: 'order-detail',
      params: { orderId: this.entityCode },
    });
  }

  private validateStripeStatus(): string {
    if (this.$route.query && this.$route.query.redirect_status) {
      if (this.stateOrder.paymentIntentId !== this.$route.query.payment_intent) {
        this.$router.push({ path: '/' });
      } else {
        return this.$route.query.redirect_status.toString();
      }
    }
    return '';
  }

  private onInvoiceBtnClick(): void {
    const salesInvoiceUrl = this.$router.resolve({
      name: 'sales-invoice', params: { orderId: this.entityCode },
    }).href;
    window.open(salesInvoiceUrl, '_blank');
  }
}
