









































import { Component, Prop } from 'vue-property-decorator';
import EntityListWidgetHelper from '@/utils/helpers/widgets/EntityListWidgetHelper';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EntityListItemComponent from '@/components/EntityListItemComponent.vue';
import { State } from 'vuex-class';

@Component({
  components: { EntityListItemComponent, ButtonComponent },
})
export default class SearchResultSectionComponent extends VueBaseWidget {
  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private readonly list!: Data[];

  @Prop({ required: true })
  private readonly count!: number;

  @Prop({ required: true })
  private readonly type!: string;

  @Prop({ required: true })
  private readonly entityTitle!: string;

  @Prop({ required: true })
  private readonly entityRoute!: string;

  @Prop({ required: true })
  private readonly btnTitle!: string;

  @Prop({ required: true })
  private readonly btnRoute!: string;

  @Prop({ required: false })
  private readonly search!: string;

  @Prop({ required: false })
  private readonly edition!: string | null;

  private get mappedData(): Record<string, string | boolean | number | object | null>[] {
    return EntityListWidgetHelper.mapData(this.list);
  }

  private handleBaseUrl(): void {
    if (this.btnRoute) {
      let external = this.btnRoute.startsWith('//');
      if (external) {
        window.open(this.btnRoute, '_blank');
      } else {
        try {
          const url = new URL(this.btnRoute);
          external = !!url.protocol;
          if (external) {
            window.open(this.btnRoute, '_blank');
          }
        } catch {
          let r = this.btnRoute;
          if (this.btnRoute.includes('%search%')) {
            r = r.replace('%search%', `search=${this.search}`);
          }
          const matches = this.btnRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '').trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          this.$router.push(r);
        }
      }
    }
  }
}
