





















































































































import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Component, Watch } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import PickerDateComponent from '@/components/calendar/PickerDateComponent.vue';
import PickerTimeComponent from '@/components/calendar/PickerTimeComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import Meeting from '@/models/graphql/Meeting';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addMinutes, getHours, getMinutes, parseISO, startOfDay,
} from 'date-fns';
import UserSelectorField from '@/components/UserSelectorField.vue';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import Event from '@/utils/types/Event';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';

const toastStore = namespace('ToastStore');
const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    FontAwesomeComponent,
    UserSelectorField,
    PickerDateComponent,
    PickerTimeComponent,
    TextAreaComponent,
    InputText,
    ButtonComponent,
  },
})
/* eslint-disable max-len */
export default class CreateEditView extends BreakpointWrapper {
  @State
  selectedTzName!: string;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected FeatureKeys = FeatureKeys;

  @agendaStore.Getter
  private fetchViewMode!: string;

  @agendaStore.Getter
  private fetchEventEdit!: Event;

  @agendaStore.State
  private createStartTimeUpdated!: number;

  @agendaStore.Getter
  private fetchDayBeingViewed!: string;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Action
  private createMeeting!: (payload: {
    meeting: Partial<Meeting>;
    userUid: string;
    meetingRequestUid?: string;
  }) => Promise<Meeting>;

  @agendaStore.Action
  private updateMeeting!: (payload: {
    meeting: Partial<Meeting>;
    toAdd: string[];
    toDelete: string[];
  }) => Promise<void>;

  @agendaStore.Getter
  private fetchMeetingSaveLoading!: boolean;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @Getter
  private readonly authUser!: CommunityUser;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  private moderatorSelected: CommunityUser | undefined | null = null;

  private ViewMode = ViewMode;

  private pickerDateInput = this.fetchDayBeingViewed;

  private selectedPickerStartTime = 0;

  private selectedPickerEndTime = 0;

  private title = '';

  private location = '';

  private message = '';

  private participants: CommunityUser[] = [];

  private participantsToAdd: string[] = [];

  private participantsToDelete: string[] = [];

  private get selectedParticipants(): CommunityUser[] {
    if (this.fetchEventEdit && this.fetchEventEdit.participants) {
      return (this.fetchEventEdit.participants as Array<MeetingParticipant>)
        .filter((participant) => !!(participant && participant.user))
        .map((participant) => participant.user as CommunityUser);
    }
    return [];
  }

  @Watch('createStartTimeUpdated')
  updateSelectedDates(): void {
    if (this.fetchViewMode === ViewMode.CREATE) {
      let startTime = this.next15minuteInterval(DateTimeHelper.getCurrentDateTime());
      if (this.fetchEventEdit && this.fetchEventEdit.tzStartTime) {
        const PREVIOUS_15_MINUTE_INTERVAL = 15;
        startTime = this.next15minuteInterval(this.fetchEventEdit.tzStartTime) - PREVIOUS_15_MINUTE_INTERVAL;
      }
      this.selectedPickerStartTime = startTime;
      this.selectedPickerEndTime = this.selectedPickerStartTime + 60;
      this.setEventEdited({
        tzStartTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerStartTime),
        tzEndTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerEndTime),
        timeZoneName: this.selectedTzName,
      });
    }
  }

  beforeDestroy(): void {
    this.setViewMode(ViewMode.DAILY_VIEW);
  }

  private created(): void {
    this.title = this.fetchEventEdit.title || '';
    this.location = this.fetchEventEdit.location || '';
    this.message = this.fetchEventEdit.message || '';
    this.pickerDateInput = this.fetchDayBeingViewed;
    if (this.fetchViewMode === ViewMode.CREATE) {
      let startTime = this.next15minuteInterval(DateTimeHelper.getCurrentDateTime());
      if (this.fetchEventEdit && this.fetchEventEdit.tzStartTime) {
        const PREVIOUS_15_MINUTE_INTERVAL = 15;
        startTime = this.next15minuteInterval(this.fetchEventEdit.tzStartTime) - PREVIOUS_15_MINUTE_INTERVAL;
      }
      this.selectedPickerStartTime = startTime;
      this.selectedPickerEndTime = this.selectedPickerStartTime + 60;
      if (this.fetchEventEdit && this.fetchEventEdit.tzEndTime) {
        const PREVIOUS_15_MINUTE_INTERVAL = 15;
        this.selectedPickerEndTime = this.next15minuteInterval(this.fetchEventEdit.tzEndTime) - PREVIOUS_15_MINUTE_INTERVAL;
      }
      this.setEventEdited({
        title: this.title,
        message: this.message,
        location: this.location,
        tzStartTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerStartTime),
        tzEndTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerEndTime),
        timeZoneName: this.selectedTzName,
      });
    } else if (this.fetchViewMode === ViewMode.EDIT) {
      const PREVIOUS_15_MINUTE_INTERVAL = 15;
      this.selectedPickerStartTime = this.next15minuteInterval(this.fetchEventEdit.tzStartTime) - PREVIOUS_15_MINUTE_INTERVAL;
      this.selectedPickerEndTime = this.next15minuteInterval(this.fetchEventEdit.tzEndTime) - PREVIOUS_15_MINUTE_INTERVAL;
    }
    ['title', 'message', 'location'].forEach((w) => {
      this.$watch(w, (t) => this.setEventEdited({ [w]: t }));
    });
    if (this.fetchEventEdit.participants) {
      this.participants = (this.fetchEventEdit.participants as MeetingParticipant[]).map((participant: MeetingParticipant) => participant.user as CommunityUser);
      this.moderatorSelected = this.fetchEventEdit.creator;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private next15minuteInterval(date: Date): number {
    const currentMinutes = (getHours(date) * 60) + getMinutes(date);
    const diff = currentMinutes % 15;
    return currentMinutes + (15 - diff);
  }

  private selectedDate(timestamps: string[]): void {
    const [selectedDate] = timestamps;
    this.setDayBeingViewed(selectedDate);
    this.pickerDateInput = selectedDate;
    this.setEventEdited({
      tzStartTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerStartTime),
      tzEndTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerEndTime),
    });
  }

  private selectedStartTime(minutes: number): void {
    this.selectedPickerStartTime = minutes;
    if (this.selectedPickerEndTime < this.selectedPickerStartTime) {
      this.selectedPickerEndTime = this.selectedPickerStartTime + 60;
    }
    this.setEventEdited({
      tzStartTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerStartTime),
      tzEndTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerEndTime),
    });
  }

  private selectedEndTime(minutes: number): void {
    this.selectedPickerEndTime = minutes;
    this.setEventEdited({
      tzEndTime: addMinutes(startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerEndTime),
    });
  }

  private onUserListChange(userList: CommunityUser[]): void {
    const toDeleteUid: string[] = [];
    const toAddUid: string[] = [];
    if (this.fetchViewMode === ViewMode.CREATE) {
      userList.forEach((user: CommunityUser) => {
        toAddUid.push(user.uid);
      });
    }

    if (this.fetchViewMode === ViewMode.EDIT && this.fetchEventEdit && this.fetchEventEdit.participants) {
      userList.forEach((user: CommunityUser) => {
        if (this.fetchEventEdit.participants
          && (this.fetchEventEdit.participants as Array<MeetingParticipant>)
            .find((participant: MeetingParticipant) => user.uid === participant.user?.uid) === undefined) {
          toAddUid.push(user.uid);
        }
      });
      (this.fetchEventEdit.participants as Array<MeetingParticipant>).forEach((participant: MeetingParticipant) => {
        if (userList.find((user: CommunityUser) => user.uid === participant.user?.uid) === undefined) {
          toDeleteUid.push(participant.uid);
        }
      });
    }

    this.participantsToAdd = toAddUid;
    this.participantsToDelete = toDeleteUid;
    this.participants = userList;
  }

  private onSave(): void {
    const participants = this.participants.map((user: CommunityUser) => ({
      user,
    } as MeetingParticipant));
    const meeting: Partial<Meeting> = {
      subject: this.fetchEventEdit.title,
      description: this.fetchEventEdit.message,
      location: this.fetchEventEdit.location,
      startTime: DateTimeHelper.toISO8601(
        DateTimeHelper.zonedToUTCTimeDate(
          this.fetchEventEdit.tzStartTime,
          this.selectedTzName,
        ),
      ),
      endTime: DateTimeHelper.toISO8601(
        DateTimeHelper.zonedToUTCTimeDate(
          this.fetchEventEdit.tzEndTime,
          this.selectedTzName,
        ),
      ),
      timeZoneName: this.selectedTzName,
      participants,
    };

    if (this.fetchViewMode === ViewMode.CREATE) {
      if (this.fetchEventEdit.meetingRequest && this.moderatorSelected) {
        this.createMeeting({
          meeting,
          userUid: this.moderatorSelected?.uid,
          meetingRequestUid: this.fetchEventEdit.meetingRequest.uid,
        })
          .finally(() => {
            this.$eventsBus.emit('meeting-assigned-to-meeting-request', this.fetchEventEdit.meetingRequest?.uid);
            this.$emit('meeting-created');
            this.setViewMode(ViewMode.DAILY_VIEW);
            this.setEventEdited({});
            this.$eventsBus.emit('close-toolbox');
            this.addNewAction({
              message: `${this.$t('toolbox.agenda.create-edit.meeting-created')}`,
              delay: 3500,
            });
          });
      } else {
        this.createMeeting({
          meeting,
          userUid: this.authUser.uid,
        })
          .finally(() => {
            this.setViewMode(ViewMode.DAILY_VIEW);
            this.setEventEdited({});
            this.addNewAction({
              message: `${this.$t('toolbox.agenda.create-edit.meeting-created')}`,
              delay: 3500,
            });
          });
      }
    } else if (this.fetchViewMode === ViewMode.EDIT) {
      this.updateMeeting({
        meeting: {
          ...meeting,
          uid: this.fetchEventEdit.uid,
        },
        toAdd: this.participantsToAdd,
        toDelete: this.participantsToDelete,
      })
        .finally(() => {
          this.setViewMode(ViewMode.DAILY_VIEW);
          this.setEventEdited({});
          this.addNewAction({
            message: `${this.$t('toolbox.agenda.create-edit.meeting-updated')}`,
            delay: 3500,
          });
        });
    }
  }

  private onCancel(): void {
    this.setViewMode(ViewMode.DAILY_VIEW);
    this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(DateTimeHelper.getCurrentDateTime()));
    this.setEventEdited({});
  }
}
