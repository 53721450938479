import { render, staticRenderFns } from "./MessageBoxPanel.vue?vue&type=template&id=f57026ee&scoped=true&"
import script from "./MessageBoxPanel.vue?vue&type=script&lang=ts&"
export * from "./MessageBoxPanel.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/chat/MessageBoxPanel.scss?vue&type=style&index=0&id=f57026ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f57026ee",
  null
  
)

export default component.exports