






































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import CommunityUser from '@/models/graphql/CommunityUser';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import Meeting from '@/models/graphql/Meeting';
import ListCalendarComponent from '@/components/full-calendar/ListCalendarComponent.vue';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import Event from '@/utils/types/Event';
import { namespace, State } from 'vuex-class';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    ListCalendarComponent,
    InputSearchComponent,
    FontAwesomeComponent,
    CheckboxSwitchComponent,
  },
})
export default class ExhibitorPortalUserDetailMeetingWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseWidget,
) {
  protected baseStoreName = 'ExhibitorPortalUserDetailMeetingWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private companyUid!: string;

  @Prop({
    required: false,
    default: null,
  })
  private memberUid!: string;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  @State
  private selectedTzName!: string;

  private get selectedUsers(): string[] {
    return this.$store.state.WidgetDispatcherStore[this.storeName].selectedUsers || [];
  }

  private get companyReps(): CommunityUser[] {
    return this.$store.state.WidgetDispatcherStore[this.storeName].companyReps || [];
  }

  private get meetings(): Meeting[] {
    return this.$store.state.WidgetDispatcherStore[this.storeName].meetings || [];
  }

  private get search(): string {
    return this.$store.state.WidgetDispatcherStore[this.storeName].search || '';
  }

  private get filterText(): string {
    // eslint-disable-next-line no-nested-ternary
    return this.selectedUsers.length === 0
      ? `${this.$t('full-calendar.assignees')}`
      : this.selectedUsers.length === 1
        ? `${CommunityUser.hydrate(this.companyReps.find((cr) => cr.uid === this.selectedUsers[0]) || {}).fullName}`
        : `${this.$tc('full-calendar.nb-selected', 0, { count: this.selectedUsers.length })}`;
  }

  created(): void {
    this.setDataConfig();
  }

  private setSearchableString(str: string): void {
    this.$store.commit(`${this.widgetStorePath}/setSearchableString`, str);
  }

  private setSelectedUsers(users: string[]): void {
    this.$store.commit(`${this.widgetStorePath}/setSelectedUsers`, users);
  }

  private updateSelectedReps(uid: string | null): void {
    if (uid) {
      const doesExist = this.selectedUsers.find((u) => u === uid);
      if (!doesExist) {
        this.setSelectedUsers([...this.selectedUsers, uid]);
      } else {
        this.setSelectedUsers(this.selectedUsers.filter((u) => u !== uid));
      }
    } else {
      this.setSelectedUsers([]);
    }
    this.loadMeetings();
  }

  private loadMeetings(): Promise<void> {
    return this.$store.dispatch(`${this.widgetStorePath}/loadMeetings`);
  }

  private onSearch(payload: { query: string }): void {
    if (this.search !== payload.query) {
      if (payload.query.length > 2) {
        this.setSearchableString(payload.query);
      } else {
        this.setSearchableString('');
      }
      this.loadMeetings();
    }
  }

  private openAgendaDetail(uid: string | null): void {
    const meeting = this.meetings.find((m) => m.uid === uid);
    if (meeting) {
      this.setViewMode(ViewMode.DETAIL);
      this.setEventViewed(AgendaStoreHelper.convertMeetingToEvent(meeting, this.selectedTzName));
      this.$eventsBus.emit('ontoolbox', {
        view: ToolbarMenuActions.TOOLBAR_AGENDA,
      });
    }
  }
}
