var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay)?_c('div',{staticClass:"checkout-widget container"},[(!_vm.orderLoading)?_c('div',{staticClass:"checkout-body d-flex  flex-wrap flex-lg-nowrap justify-content-between w-100 mt-5"},[(_vm.needsApproval)?_c('div',{staticClass:"col-lg-7 col-12 px-0 checkout-payment-container text-left"},[_c('p',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(_vm.$t('cart.modal.description'))+" ")])]):_c('div',{staticClass:"col-lg-7 col-12 px-0 checkout-payment-container text-left"},[(_vm.stripeError)?_c('div',{staticClass:"stripe-error error d-flex text-red-r-3-primary-red justify-content-start p-3 mb-3"},[_c('font-awesome-component',{staticClass:"mr-3",attrs:{"container-classes":"icon-24","icon":"fa-solid fa-circle-minus"}}),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('checkout.stripe-error'))+" "),_c('span',[_vm._v(" "+_vm._s(_vm.stripeErrorMessage)+" ")])])],1):_vm._e(),_c('div',{staticClass:"choose-payment-method w-100"},[(_vm.isBothCreditCardAndBillMeLaterEnabled)?_c('div',{class:[
            'd-flex justify-content-start',
            {'flex-column row-gap-4': _vm.isMobile}
          ]},[_c('div',{class:[{'w-100': _vm.isMobile}]},[_c('button',{class:[
                'font-weight-bold select-payment d-flex flex-column p-3',
                {'selected': _vm.currentTypeOfPayment === _vm.typeOfPaymentEnum.CARD} ],attrs:{"disabled":_vm.isPaying},on:{"click":function($event){return _vm.setPaymentMethod(_vm.typeOfPaymentEnum.CARD)}}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-credit-card","icon-classes":"icon-16"}}),_c('p',{staticClass:"mb-0 p-0 text-left"},[_vm._v(" "+_vm._s(_vm.$t('checkout.buttons.pay-now'))+" ")])],1)]),_c('div',{class:[{'w-100': _vm.isMobile}]},[_c('button',{class:[
                'font-weight-bold select-payment d-flex flex-column p-3',
                {'selected': _vm.currentTypeOfPayment === _vm.typeOfPaymentEnum.BILL_ME_LATER},
                {'ml-1': !_vm.isMobile}
              ],attrs:{"disabled":_vm.isPaying},on:{"click":function($event){return _vm.setPaymentMethod(_vm.typeOfPaymentEnum.BILL_ME_LATER)}}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-file","icon-classes":"icon-16"}}),_c('p',{staticClass:"mb-0 p-0 text-left"},[_vm._v(" "+_vm._s(_vm.$t('checkout.buttons.pay-later'))+" ")])],1)])]):_vm._e()]),(_vm.isCreditCardEnable)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTypeOfPayment === _vm.typeOfPaymentEnum.CARD),expression:"currentTypeOfPayment === typeOfPaymentEnum.CARD"}],staticClass:"stripe-container mt-3"},[(!!(_vm.stripe))?_c('checkout-billing-information-component',{attrs:{"stripe-elements":_vm.stripeElements},on:{"set-element":_vm.setElement}}):_vm._e(),(_vm.salesPackages)?_c('package-list-component',{staticClass:"d-flex mb-5 cart-body w-100 px-0",attrs:{"edit":false,"sales-packages":_vm.salesPackages}}):_vm._e()],1):_vm._e(),(_vm.isBillMeLaterEnable)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTypeOfPayment === _vm.typeOfPaymentEnum.BILL_ME_LATER),expression:"currentTypeOfPayment === typeOfPaymentEnum.BILL_ME_LATER"}],staticClass:"bill-me-later mt-3"},[_vm._v(" "+_vm._s(_vm.$t('checkout.pay-later-description'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-lg-4 mb-4 order-1 order-lg-2 col-12 px-0"},[(!_vm.needsApproval)?_c('checkout-order-summary-component',{attrs:{"btn-text":(_vm.currentTypeOfPayment === _vm.typeOfPaymentEnum.BILL_ME_LATER)
          ? ("" + (_vm.$t('checkout.buttons.pay-later-text'))): ("" + (_vm.$t('checkout.pay'))),"company-uid":_vm.companyUid,"disable":!_vm.canPay && _vm.currentTypeOfPayment !== _vm.typeOfPaymentEnum.BILL_ME_LATER,"loading-checkout":_vm.isPaying,"order-is-new":_vm.order.status === _vm.orderStatus.NEW_PENDING,"total":_vm.totalValue,"mode":"checkout"},on:{"order-navigate":_vm.pay,"edit-order":_vm.goToCart}}):_c('checkout-order-summary-component',{attrs:{"btn-text":("" + (_vm.$t('cart.checkout-approval'))),"company-uid":_vm.companyUid,"disable":false,"loading-checkout":_vm.isPaying,"order-is-new":_vm.order.status === _vm.orderStatus.NEW_PENDING,"total":_vm.totalValue,"mode":"checkout"},on:{"order-navigate":_vm.onRequestApproval,"edit-order":_vm.goToCart}})],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }