















import { Component } from 'vue-property-decorator';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import CompanyResources from '@/components/company/CompanyResources.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { namespace } from 'vuex-class';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';

const salesPackageLimitStore = namespace('SalesPackageLimitStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    CompanyResources,
  },
})
export default class CompanyCmsProfileOtherContactWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget) {
  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string }) => Promise<{
    type: string;
    limit: number;
  }[]>;

  @permissionManagerStore.Getter
  private canManageCompanyContent!: (companyUid: string) => boolean;

  private contactUsLimit = -1;

  private get canManageCompanyInformation(): boolean {
    if (this.adminPanelExhibitor) {
      return this.canManageCompanyContent(this.adminPanelExhibitor.uid);
    }
    return false;
  }

  private get isNewLimitCalculationFeatureEnabled(): boolean {
    const salesServiceFeature = this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE);
    return salesServiceFeature && salesServiceFeature.enabled;
  }

  created(): void {
    this.setDataConfig();
    if (this.isNewLimitCalculationFeatureEnabled) {
      this.filterSlots({
        exhibitorUid: this.$route.params.companyId,
        code: SlotTypeEnum.CONTACT_SLOT,
      })
        .then((response) => {
          if (response && response[0]) {
            this.contactUsLimit = response[0].limit;
          }
        });
    }
  }

  mounted(): void {
    if (!this.isNewLimitCalculationFeatureEnabled) {
      // eslint-disable-next-line no-underscore-dangle
      this.contactUsLimit = this.company._effectiveLimitNumContactUs ?? -1;
    }
  }
}
