import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import { utcToZonedTime } from 'date-fns-tz';
import {
  format, getUnixTime, Locale, startOfHour,
} from 'date-fns';
import Category from '@/models/graphql/Category';
import Session from '@/models/graphql/Session';

export type GroupedByStartTimestamp = {
  startTimestamp: number;
  startTimeFormatted: string;
  categories: Category[];
  events: ScheduleEvent[];
  items: Array<Category | ScheduleEvent>;
};

export default class ProgramEntitySearchWidgetHelper {
  static formatTime = (timestamp: number | null, timezone: string, locale: Locale, f = 'ha'): string => {
    if (timestamp) {
      const date = utcToZonedTime(timestamp * 1000, timezone);
      return format(date, f, { locale });
    }
    return '';
  };

  static groupToTimeline = (
    sessions: Session[],
    scheduleEvents: ScheduleEvent[],
    timezone: string,
    locale: Locale,
  ): Record<number, GroupedByStartTimestamp> => {
    const timeline: Record<number, GroupedByStartTimestamp> = {};

    // Add sessions to timeline and group them by category
    sessions.forEach((session) => {
      if (session.startTimestamp) {
        const sessionStartTime = getUnixTime(startOfHour(session.startTimestamp * 1000));
        if (!timeline[sessionStartTime]) {
          timeline[sessionStartTime] = {
            startTimestamp: sessionStartTime,
            startTimeFormatted: ProgramEntitySearchWidgetHelper.formatTime(sessionStartTime, timezone, locale),
            categories: [],
            events: [],
            items: [],
          };
        }
      }

      session.categories.forEach((category) => {
        if (session && session.startTimestamp) {
          const categoryStartTime = getUnixTime(startOfHour(session.startTimestamp * 1000));
          let categoryEntry = timeline[categoryStartTime].categories.find((cat) => cat.uid === category.uid);
          if (!categoryEntry) {
            categoryEntry = {
              ...category,
              sessions: [],
              typename: 'Category',
            };
            timeline[categoryStartTime].categories.push(categoryEntry);
          }
          // Add the session to the category
          ((categoryEntry as Category).sessions as Session[]).push(session);
        }
      });
    });

    // Add schedule events to timeline
    scheduleEvents.forEach((event) => {
      if (event.startTimestamp) {
        const eventStartTime = getUnixTime(startOfHour(event.startTimestamp * 1000));
        if (!timeline[eventStartTime]) {
          timeline[eventStartTime] = {
            startTimestamp: eventStartTime,
            startTimeFormatted: ProgramEntitySearchWidgetHelper.formatTime(eventStartTime, timezone, locale),
            categories: [],
            events: [],
            items: [],
          };
        }
        timeline[eventStartTime].events.push({
          ...event,
          typename: 'ScheduleEvent',
        });
      }
    });

    // Build the `items` array for combined categories and events
    Object.values(timeline)
      .forEach((entry) => {
        entry.items.push(...entry.events, ...entry.categories);
      });

    // Sort timeline by startTimestamp
    const resultTimeline = Object.fromEntries(
      Object.entries(timeline)
        .sort(([a], [b]) => Number(a) - Number(b)),
    );
    return ProgramEntitySearchWidgetHelper.deduplicateCategories(resultTimeline);
  };

  static deduplicateCategories = (timeline: Record<number, GroupedByStartTimestamp>): Record<number, GroupedByStartTimestamp> => {
    Object.values(timeline)
      .forEach((entry) => {
        const seenSessions = new Map<string, string>(); // Map to track session UID to category UID

        entry.categories = entry.categories.filter((category) => {
          const filteredSessions = (category.sessions || []).filter((session) => {
            if (seenSessions.has(session.uid)) {
              // Session is already associated with a category, skip it
              return false;
            }
            // Mark the session as seen
            seenSessions.set(session.uid, category.uid);
            return true;
          });

          // Update category's sessions to only include the filtered ones
          category.sessions = filteredSessions;

          // If the category now has no sessions, remove it
          return category.sessions.length > 0;
        });

        // Rebuild the items array with the deduplicated categories
        entry.items = [...entry.categories, ...entry.events];
      });

    return timeline;
  };
}
