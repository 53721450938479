




































































import { Component, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import MessageBoxHeader from '@/views/chat/MessageBoxHeader.vue';
import MessageBoxContent from '@/views/chat/MessageBoxContent.vue';
import ToolboxMessageContainer from '@/components/toolbox/message/ToolboxMessageContainer.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import { ChatErrorType } from '@/utils/types/chat/ChatError';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import MessageBoxStatus from '@/utils/enums/chat/MessageBoxActions';
import Exhibitor from '@/models/graphql/Exhibitor';

const chatStore = namespace('ChatDispatcherStore/ChatStore');
const exhibitorStore = namespace('ExhibitorStore');

@Component({
  components: {
    IllustrationComponent,
    ToolboxMessageContainer,
    MessageBoxContent,
    MessageBoxHeader,
  },
})
export default class MessageBoxPanel extends BreakpointWrapper {
  @Getter
  private readonly authUser!: CommunityUser;

  @chatStore.State
  private chatErrors!: ChatErrorList;

  @chatStore.Getter
  private isClosed!: boolean;

  @chatStore.State
  private status!: MessageBoxStatus;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  private renderMessageBox = 1;

  private renderHeader = +new Date();

  private ChatErrorType = ChatErrorType;

  private IllustrationType = IllustrationType;

  private get chatReps(): string[] {
    if (this.adminPanelExhibitor) {
      // eslint-disable-next-line no-underscore-dangle
      return (this.adminPanelExhibitor._chatReps || []).map((rep) => rep.user?.uid || '');
    }
    return [];
  }

  @Watch('status')
  private reRenderHeader(): void {
    this.renderHeader += 1;
  }

  @Watch('isClosed')
  private preventScroll(): void {
    this.renderMessageBox += 1;
    if (!this.isClosed) {
      if (!this.isDesktop) {
        const el = document.querySelector('.main-header.shadows-md');
        if (el) {
          el.classList.remove('shadows-md');
        }
        document.body.style.overflow = 'hidden';
      }
    } else {
      const el = document.querySelector('.main-header');
      if (el && window.scrollY >= 2) {
        el.classList.add('shadows-md');
      }
      document.body.style.overflow = 'auto';
    }
  }
}
