/* eslint-disable no-continue */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/camelcase */
import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import { Data } from '@/utils/types/WidgetData';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import { BasicTypes } from '@/utils/types/BasicTypes';
import CompanyUserRoleRepository from '@/repositories/CompanyUserRoleRepository';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class SearchResultWidgetStore extends WidgetBaseStore {
  private searchResults: Record<string, {
    list: Data[];
    count: number;
    type: string;
    entityTitle: string;
    entityRoute: string;
    btnTitle: string;
    btnRoute: string;
    edition: string;
  }> = {};

  private resultCount = 0;

  private readonly repository = new WidgetBaseRepository();

  private readonly companyUserRoleRepository = new CompanyUserRoleRepository();

  private get results(): object {
    return this.searchResults;
  }

  private get totalCount(): number {
    return this.resultCount;
  }

  @Action
  async setDataConfigs(): Promise<void> {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      const { amount } = JSON.parse(this.widget.payload || '{}');
      const first = {};
      if (amount && amount > 0) {
        Object.assign(first, { first: parseInt(amount, 10) });
      }
      // eslint-disable-next-line no-restricted-syntax
      for await (const subWidget of this.widget.subWidgets) {
        const index = this.widget.subWidgets.indexOf(subWidget);
        const {
          type,
          orderBy,
          title,
          route,
          btnTitle,
          btnRoute,
          edition,
        } = JSON.parse(subWidget.payload || '{}');
        let { filter } = JSON.parse(subWidget.payload || '{}');
        const key = type + index;
        this.context.commit('setInitSearchResult', {
          [key]: {
            type: key,
            entityTitle: title,
            entityRoute: route,
            btnTitle,
            btnRoute,
            list: [],
            count: 0,
            edition,
          },
        });
        let filterType = '';
        let orderByType = '';
        let operation = '';
        let fragmentName = '';
        let fragmentCountName = '';
        const alias = `${type}${index}_data`;
        const aliasCount = `${type}${index}_count`;
        switch (type) {
          case 'article':
            filterType = GqlEntityFilterType.ARTICLE_FILTER;
            orderByType = GqlEntityOrderingType.ARTICLE_ORDERING;
            fragmentName = 'articleEntityListFragment';
            fragmentCountName = 'articleCountFragment';
            operation = 'article';
            break;
          case 'communityUser':
            filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
            orderByType = GqlEntityOrderingType.COMMUNITY_USER_ORDERING;
            fragmentName = 'communityUserEntityListFragment';
            fragmentCountName = 'communityUserCountFragment';
            operation = 'communityUser';
            break;
          case 'exhibitor':
            filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
            orderByType = GqlEntityOrderingType.EXHIBITOR_ORDERING;
            fragmentName = 'exhibitorEntityListFragment';
            fragmentCountName = 'exhibitorCountFragment';
            operation = 'exhibitor';
            break;
          case 'session':
            filterType = GqlEntityFilterType.SESSION_FILTER;
            orderByType = GqlEntityOrderingType.SESSION_ORDERING;
            fragmentName = 'sessionEntityListFragment';
            fragmentCountName = 'sessionCountFragment';
            operation = 'session';
            break;
          case 'product':
            filterType = GqlEntityFilterType.PRODUCT_FILTER;
            orderByType = GqlEntityOrderingType.PRODUCT_ORDERING;
            fragmentName = 'productEntityListFragment';
            fragmentCountName = 'productCountFragment';
            operation = 'product';
            break;
          case 'largeProduct':
            filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
            orderByType = GqlEntityOrderingType.LARGE_PRODUCT_ORDERING;
            fragmentName = 'largeProductEntityListFragment';
            fragmentCountName = 'largeProductCountFragment';
            operation = 'largeProduct';
            break;
          case 'deal':
            filterType = GqlEntityFilterType.DEAL_FILTER;
            orderByType = GqlEntityOrderingType.DEAL_ORDERING;
            fragmentName = 'dealEntityListFragment';
            fragmentCountName = 'dealCountFragment';
            operation = 'deal';
            break;
          case 'speaker':
            filterType = GqlEntityFilterType.SPEAKER_FILTER;
            orderByType = GqlEntityOrderingType.SPEAKER_ORDERING;
            fragmentName = 'speakerEntityListFragment';
            fragmentCountName = 'speakerCountFragment';
            operation = 'speaker';
            break;
          case 'subEdition':
            filterType = GqlEntityFilterType.SUB_EDITION_FILTER;
            orderByType = GqlEntityOrderingType.SUB_EDITION_ORDERING;
            fragmentName = 'subEditionEntityListFragment';
            fragmentCountName = 'subEditionCountFragment';
            operation = 'subEdition';
            break;
          case 'category':
            filterType = GqlEntityFilterType.CATEGORY_FILTER;
            orderByType = GqlEntityOrderingType.CATEGORY_ORDERING;
            fragmentName = 'categoryEntityListFragment';
            fragmentCountName = 'categoryCountFragment';
            operation = 'category';
            break;
          case 'geozone':
            filterType = GqlEntityFilterType.GEOZONE_FILTER;
            orderByType = GqlEntityOrderingType.GEOZONE_ORDERING;
            fragmentName = 'geozoneEntityListFragment';
            fragmentCountName = 'geozoneCountFragment';
            operation = 'geozone';
            filter = {
              schemaCode: edition,
              objectType_in: ['Room', 'Booth'],
              exhibitHall: { visible: true },
              OR: [
                { entityFts: '%entityFts%' },
                { name_contains: '%entityFts%' },
                { booth_some: { exhibitors_some: { name_contains: '%entityFts%' } } },
                { sessions_some: { name_contains: '%entityFts%' } },
              ],
              ...JSON.parse(filter || '{}'),
            };
            break;
          case 'package':
            // Check if user has manageSales permission before showing him the packages
            const companyUserRoles = await this.companyUserRoleRepository.filter({
              definition: buildQueryDefinition({
                filter: {
                  value: {
                    user: {
                      uid: this.context.rootGetters.authUser.uid,
                    },
                    manageSales: true,
                  },
                  type: GqlEntityFilterType.COMPANY_USER_ROLE_FILTER,
                },
              }),
            });
            if (companyUserRoles.length === 0) {
              continue;
            }
            filterType = GqlEntityFilterType.SALES_PACKAGE_FILTER;
            orderByType = GqlEntityOrderingType.SALES_PACKAGE_ORDERING;
            fragmentName = 'salesPackageSearchResultFragment';
            fragmentCountName = 'salesPackageCountFragment';
            operation = 'salesPackage';
            break;
          default:
            Sentry.withScope((scope) => {
              scope.setLevel(Severity.Error);
              Sentry.captureException(`Unknown Search entity type: ${type}`);
            });
            break;
        }
        let order = {};
        if (orderBy
          && orderBy.length > 0
          && !orderBy.includes('default')) {
          order = {
            orderBy: {
              value: orderBy.split(','),
              type: orderByType,
            },
          };
        }
        let fullFilter = {};
        if (type === 'geozone') {
          fullFilter = {
            schemaCode: edition,
            ...filter,
          };
        } else {
          fullFilter = {
            schemaCode: '%community%',
            entityFts: '%entityFts%',
            ...JSON.parse(filter || '{}'),
          };
        }
        const gqlDefinition = buildQueryDefinition({
          filter: {
            value: fullFilter,
            type: filterType,
          },
          ...order,
          ...first,
        });
        const gqlCountDefinition = buildQueryDefinition({
          filter: {
            value: fullFilter,
            type: filterType,
          },
        });
        configs.push(...[{
          gqlDefinition,
          operation,
          fragmentName,
          alias,
        },
        {
          gqlDefinition: gqlCountDefinition,
          operation,
          fragmentName: fragmentCountName,
          alias: aliasCount,
        }]);
      }
      this.context.commit('setGqlQueryConfig', configs);
    }
  }

  @Action
  private loadSearchResultsData(entityFts = ''): Promise<void> {
    if (this.gqlQueryConfig.length > 0) {
      this.context.commit('resetSearchResult');
      const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
      return this.repository.load({
        params: {
          operationName: 'LoadSearchResults',
          definitions: this.gqlQueryConfig,
          magicArgs: { entityFts, ...args },
        },
        queries: '',
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              const keys = k.split('_');
              if (keys[1] === 'data') {
                this.context.commit(
                  'setSearchResult',
                  {
                    key: keys[0],
                    data: { list: response[k] },
                  },
                );
              }
              if (keys[1] === 'count') {
                const { count } = (response[k] as { count: number }[])[0];
                this.context.commit(
                  'setSearchResult',
                  {
                    key: keys[0],
                    data: { count },
                  },
                );
              }
            });
        });
    }
    return Promise.resolve();
  }

  @Mutation
  private setSearchResult(payload: {
    key: string;
    data: { list: Data[] } | { count: number };
  }): void {
    if (!Object.keys(this.searchResults)
      .includes(payload.key)) {
      Object.assign(this.searchResults, { [payload.key]: {} });
    }
    Object.assign(this.searchResults[payload.key], { ...payload.data });
    if ('count' in payload.data && payload.data.count) {
      this.resultCount += payload.data.count;
    }
  }

  @Mutation
  private resetSearchResult(): void {
    this.resultCount = 0;
    Object.values(this.searchResults)
      .forEach((value) => {
        value.count = 0;
        value.list = [];
      });
  }

  @Mutation
  private setInitSearchResult(values: object): void {
    Object.assign(this.searchResults, values);
  }
}
