var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"exhibitor-card d-flex flex-column align-items-stretch",class:[{'blue-border': _vm.featured && _vm.displayFeaturedBorder}],attrs:{"to":!_vm.urlOpenNewWindow ? _vm.handleBaseUrl : null},on:{"click":function($event){return _vm.onCardClick(_vm.handleBaseUrl)}}},[_c('div',{staticClass:"d-flex header justify-content-center position-relative"},[_c('div',{staticClass:"banner position-absolute overflow-y-hidden",style:({backgroundImage: _vm.bannerSrc})},[_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.ActionRuleService.isRuleValid(_vm._actions, _vm.ActionRule.CAN_BOOKMARK))?_c('button-icon-component',{staticClass:"w-fit ml-auto mr-2 mt-2",attrs:{"id":("bookmark-" + _vm.uid),"variant":_vm.bookmarked ? 'alt-2' : 'tertiary',"shape":"round","size":"md"},on:{"on-click":_vm.toggleBookmark},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.bookmarked
                ? 'fa-solid fa-bookmark'
                : 'fa-regular fa-bookmark',"icon-classes":!_vm.bookmarked ? 'text-neutral-n-8-dark-ink' : '',"container-classes":"icon-24"}})]},proxy:true}],null,false,423977814)}):_vm._e()],1)]),_c('avatar-solo-widget',{attrs:{"default-icon":'far fa-buildings',"recommended-for-you":_vm._isRecommendedForMe && _vm.displayRecommendedBadge,"src":_vm.logoSrc,"with-borders":true,"size":"96"}})],1),_c('div',{staticClass:"exhibitor-info px-3 d-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center column-gap-2"},[_c('div',{staticClass:"d-inline exhibitor-name"},[_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.window",value:(_vm.name),expression:"name",modifiers:{"hover":true,"bottom":true,"window":true}}],staticClass:"text-neutral-n-8-dark-ink title mb-0"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),(_vm.countryFlag)?_c('div',{staticClass:"country-container",attrs:{"id":'tool-item-'+_vm.uid}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.window",value:(_vm.countryName),expression:"countryName",modifiers:{"hover":true,"bottom":true,"window":true}}],attrs:{"alt":(_vm.countryFlag + " flag"),"src":("https://cdn.ipregistry.co/flags/emojitwo/" + (_vm.countryFlag.toLowerCase()) + ".svg"),"height":"12"}})]):_vm._e()])]),_c('div',{staticClass:"mt-auto"},[(_vm.boothNumbers)?_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.window",value:(_vm.boothNumbers),expression:"boothNumbers",modifiers:{"hover":true,"bottom":true,"window":true}}],class:[
        'text-neutral-n-6-label label-2 booth-number ellipsis-1 text-center',
        {'padding-lg': !_vm.mapNavigationUrl}
      ]},[_vm._v(" "+_vm._s(_vm.boothNumbers)+" ")]):_vm._e(),(_vm.mapNavigationUrl)?_c('div',{staticClass:"map-button"},[_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('component.exhibitor-card.show-on-map'))),"size":"md","variant":"secondary"},on:{"on-click":function($event){return _vm.$router.push(_vm.mapNavigationUrl)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-location-dot"}})]},proxy:true}],null,false,341275896)})],1):_vm._e()]),(_vm.authUser)?_c('b-tooltip',{attrs:{"target":("bookmark-" + _vm.uid),"title":_vm.bookmarked
      ? _vm.$t('actions.tooltip.bookmark.remove')
      : _vm.$t('actions.tooltip.bookmark.add'),"boundary":"document","custom-class":"tooltip-style","placement":"top","triggers":"hover"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }