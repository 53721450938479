
























import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  compareAsc, format, isToday, isTomorrow, subSeconds,
} from 'date-fns';
import { State } from 'vuex-class';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Meeting from '@/models/graphql/Meeting';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  components: {
    FontAwesomeComponent,
  },
})
export default class EpAgendaDashboardItemComponent extends Vue {
  @Prop({ required: true })
  private meeting!: Meeting;

  @Prop({ required: true })
  private selectedFilter!: string;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private isPastMeeting = false;

  get timeRange(): string {
    const event = AgendaStoreHelper.convertMeetingToEvent(this.meeting, this.selectedTzName);
    const std = event.tzStartTime;
    const edd = event.tzEndTime;
    const today = DateTimeHelper.getCurrentDateTime();
    this.isPastMeeting = compareAsc(subSeconds(today, 1), edd) !== -1;
    if (std && edd) {
      // eslint-disable-next-line no-nested-ternary
      return `${isToday(std)
        ? this.$t('ep-dashboard.agenda.today')
        : isTomorrow(std)
          ? this.$t('ep-dashboard.agenda.tomorrow')
          : format(
            std,
            `${this.$t('app.date.dayOfWeekShort')} ${this.$t('app.date.monthDayShort')}`,
            { locale: this.dateLocale },
          )} ${format(
        std,
        this.$t('app.date.defaultTimeFormat')
          .toString(),
        { locale: this.dateLocale },
      )
        .toLowerCase()
        .replace(' ', '')} - ${format(
        edd,
        this.$t('app.date.defaultTimeFormat')
          .toString(),
        { locale: this.dateLocale },
      )
        .toLowerCase()
        .replace(' ', '')}`;
    }
    return '';
  }

  private goToMeetingDetail(): void {
    this.$router.push({
      name: 'exhibitor-portal-agenda',
      params: {
        companyId: this.$route.params.companyId,
      },
      query: {
        meetingId: this.meeting.uid,
        meetingDate: format(utcToZonedTime(`${this.meeting.startTime}Z`, this.selectedTzName), 'yyyy-MM-dd'),
      },
    });
  }
}
