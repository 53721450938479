




















































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import EntityFilterRange from '@/utils/types/entity-search/EntityFilterRange';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { GroupedByStartTimestamp } from '@/utils/helpers/widgets/ProgramEntitySearchWidgetHelper';
import ProgramEventItemComponent from '@/components/program/ProgramEventItemComponent.vue';
import { format, parseISO } from 'date-fns';
import { namespace, State } from 'vuex-class';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import ProgramSessionItemComponent from '@/components/program/ProgramSessionItemComponent.vue';
import ProgramCategoryItemComponent from '@/components/program/ProgramCategoryItemComponent.vue';
import EntitySearchFilterWidget from '@/components/entity-search/EntitySearchFilterWidget.vue';
import CalendarDetailComponent from '@/components/full-calendar/CalendarDetailComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import GenericPage from '@/views/widget/GenericPage.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import UiPage from '@/models/graphql/UiPage';

const pageStateManagementStore = namespace('PageStateManagementStore');

@Component({
  components: {
    ButtonIconComponent,
    GenericPage,
    StandardModal,
    CalendarDetailComponent,
    EntitySearchFilterWidget,
    ProgramCategoryItemComponent,
    ProgramSessionItemComponent,
    ProgramEventItemComponent,
    LoadingSpinnerComponent,
    FontAwesomeComponent,
    ButtonComponent,
  },
})
export default class ProgramEntitySearchWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'ProgramEntitySearchWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private readonly searchPlaceholder!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly sessionCardRoute!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly listConfigs!: EntityFilterList[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly toggleConfigs!: EntityFilterToggle[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly rangeConfigs!: EntityFilterRange[];

  @pageStateManagementStore.Mutation
  private initPageStatesForPage!: (page: number) => void;

  @State
  private dateLocale!: Locale;

  @State
  private pages!: UiPage[];

  private selectedDateIndex = -1;

  private isDataLoading = false;

  private renderSessionView = +new Date();

  private parentPageName: string | null = null;

  private timeLabelTopPosition = 168;

  private get paginatedItems(): Record<number, GroupedByStartTimestamp> {
    if (this.isReadyToDisplay && this.widgetStoreCreated) {
      this.$eventsBus.emit('advertise-refresh', {
        uid: this.widget.uid,
        pageNum: 1,
      });

      return this.$store.getters[`${this.widgetStorePath}/items`];
    }
    return {};
  }

  private get sessionViewPage(): UiPage | null {
    const parentPage = this.pages.find((p) => p.id === this.widget.page?.id);
    if (parentPage) {
      this.parentPageName = parentPage.code as string;
      return parentPage.childPages.find((p) => p.code === 'program-session-view') || null;
    }
    return null;
  }

  private get startDates(): string[] {
    if (this.isReadyToDisplay && this.widgetStoreCreated) {
      return this.$store.getters[`${this.widgetStorePath}/startDates`];
    }
    return [];
  }

  private get selectedDate(): string {
    if (this.isReadyToDisplay && this.widgetStoreCreated) {
      return this.$store.state.WidgetDispatcherStore[this.storeName].selectedDate;
    }
    return '';
  }

  private get selectedSession(): string | null {
    if (this.isReadyToDisplay && this.widgetStoreCreated) {
      return this.$store.getters[`${this.widgetStorePath}/session`];
    }
    return null;
  }

  private get expandedCategory(): string | null {
    if (this.selectedSession) {
      let category: string | null = null;
      Object.values(this.paginatedItems)
        .some((item) => {
          category = item.categories.find((c) => !!(c.sessions || []).find((s) => s.uid === this.selectedSession))?.uid || null;
          return !!category;
        });
      return category;
    }
    return null;
  }

  private get showFilters(): boolean {
    return !!this.searchPlaceholder
      || this.toggleConfigs.length > 0
      || this.listConfigs.length > 0
      || this.rangeConfigs.length > 0;
  }

  created(): void {
    this.setDataConfig();
    if (this.$route.params.sessionId) {
      this.$store.commit(`${this.widgetStorePath}/setSelectedSession`, this.$route.params.sessionId);
    }
    window.addEventListener('scroll', this.onScrollEventTriggered);
  }

  mounted(): void {
    this.onScrollEventTriggered();
  }

  private updateTimeLabelPositions(): void {
    const groups = document.querySelectorAll('.group');
    const mainHeader = document.querySelector('.main-header');
    const datesWrapper = document.querySelector('.dates-wrapper');
    if (mainHeader && datesWrapper) {
      this.timeLabelTopPosition = mainHeader.getBoundingClientRect().height + datesWrapper.getBoundingClientRect().height;
    }

    groups.forEach((group) => {
      const timeLabel = group.querySelector('.time-label') as HTMLElement;
      const groupRect = group.getBoundingClientRect();

      if (groupRect.top < this.timeLabelTopPosition && groupRect.bottom > this.timeLabelTopPosition) {
        timeLabel.style.position = 'fixed';
      } else {
        timeLabel.style.position = 'sticky';
      }
    });
  }

  private onScrollEventTriggered(): void {
    this.updateTimeLabelPositions();
    this.updateDateSwitcherPosition();
  }

  private updateDateSwitcherPosition(): void {
    const mainHeaderEl = document.querySelector('div.main-header') as HTMLElement;
    let mainHeaderHeight = 64;
    if (mainHeaderEl) {
      mainHeaderHeight = mainHeaderEl.offsetHeight;
    }
    const dateSwitcherEl = this.$refs.dateSwitcher as HTMLElement;
    if (mainHeaderEl && dateSwitcherEl && dateSwitcherEl.firstElementChild) {
      if (dateSwitcherEl.getBoundingClientRect().top < mainHeaderHeight) {
        dateSwitcherEl.firstElementChild.classList.add('fixed');
        dateSwitcherEl.firstElementChild.classList.remove('shadows-line-down');
        dateSwitcherEl.style.height = `${dateSwitcherEl.firstElementChild.getBoundingClientRect().height}px`;
        if (mainHeaderEl) {
          mainHeaderEl.classList.remove('shadows-md');
        }
      }
      if (dateSwitcherEl.getBoundingClientRect().top > mainHeaderHeight) {
        dateSwitcherEl.firstElementChild.classList.remove('fixed');
        dateSwitcherEl.firstElementChild.classList.add('shadows-line-down');
        if (mainHeaderEl) {
          mainHeaderEl.classList.add('shadows-md');
        }
      }
    }
  }

  private initPageStatesForSessionViewPage(): void {
    if (this.sessionViewPage && this.sessionViewPage.id) {
      this.initPageStatesForPage(this.sessionViewPage.id);
    }
  }

  private initializeDates(): void {
    this.$store.commit(`${this.widgetStorePath}/initializeDates`);
  }

  private initializeData(): void {
    this.$store.commit(`${this.widgetStorePath}/initializeData`);
  }

  private setSelectedSession(session: string | null): void {
    this.initPageStatesForSessionViewPage();
    this.renderSessionView = +new Date();
    this.$store.commit(`${this.widgetStorePath}/setSelectedSession`, session);
    if (session !== null) {
      Object.assign(this.$route.params, { sessionId: session });
      window.history.pushState(
        null,
        document.title,
        this.$router.resolve({
          name: 'program-session-view',
          params: { sessionId: session },
          query: this.$route.query,
        }).href,
      );
    } else {
      delete this.$route.params.sessionId;
      window.history.pushState(
        null,
        document.title,
        this.$router.resolve({
          name: this.parentPageName as string,
          query: this.$route.query,
        }).href,
      );
    }
  }

  private doFullLoad(): Promise<void> {
    if (this.isReadyToDisplay) {
      this.isDataLoading = true;
      this.initializeDates();
      this.initializeData();
      return this.$store.dispatch(
        `${this.widgetStorePath}/loadDates`,
        this.$route.query,
      )
        .then(() => {
          if (this.startDates.length > 0) {
            return this.loadData();
          }
          return Promise.resolve();
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    }
    return Promise.resolve();
  }

  private loadData(): Promise<void> {
    if (this.isReadyToDisplay) {
      this.isDataLoading = true;
      this.initializeData();
      return this.$store.dispatch(
        `${this.widgetStorePath}/loadData`,
        this.$route.query,
      )
        .finally(() => {
          this.isDataLoading = false;
        });
    }
    return Promise.resolve();
  }

  private changeDay(increment: number): void {
    if (this.startDates.length > 0) {
      this.selectedDateIndex += increment;
      window.history.pushState(
        null,
        document.title,
        this.$router.resolve({
          path: this.$route.path,
          params: this.$route.params,
          query: Object.assign(this.$route.query, { selectedDate: this.startDates[this.selectedDateIndex] }),
        }).href,
      );
      this.loadData();
    }
  }

  private formatDate(d: string): string {
    return format(parseISO(d), 'EEE MMM dd', { locale: this.dateLocale })
      .toUpperCase();
  }

  @Watch('selectedDate')
  private updateSelectedDateIndex(): void {
    this.selectedDateIndex = this.startDates.indexOf(this.selectedDate);
  }

  private switchDate(index: number): Promise<void> {
    if (this.startDates.length > 0) {
      this.selectedDateIndex = index;
      window.history.pushState(
        null,
        document.title,
        this.$router.resolve({
          path: this.$route.path,
          params: this.$route.params,
          query: Object.assign(this.$route.query, { selectedDate: this.startDates[this.selectedDateIndex] }),
        }).href,
      );
      return this.loadData();
    }
    return Promise.resolve();
  }
}
