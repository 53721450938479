























































































































































import { Component, Inject, Watch } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import MenuModel from '@/models/MenuModel';
import ToolboxQueryParams from '@/utils/types/ToolboxQueryParams';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import { ToolbarMenuIcons } from '@/utils/enums/ToolbarMenuIcons';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ToolboxModal from '@/components/toolbox/ToolboxModal.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { getUnixTime } from 'date-fns';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import UiPage from '@/models/graphql/UiPage';
import { mixins } from 'vue-class-component';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';

const agendaStore = namespace('AgendaStore');
const communityUserNoteStore = namespace('CommunityUserNoteStore');
const communityUserTagStore = namespace('CommunityUserTagStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    ToolboxModal,
  },
})
export default class ToolboxComponent extends mixins(BreakpointWrapper, ChatModuleHelper, VueBaseNotify) {
  public currentToolboxModal = '';

  @Getter
  protected currentPage!: UiPage;

  @Inject({ from: 'windowWidth' })
  private readonly windowWidth!: { value: string };

  @State
  private selectedTzName!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserNoteStore.Action
  private loadNoteLinkedEntityCount!: (entityUid: string) => Promise<void>;

  @communityUserNoteStore.Mutation
  private resetNoteStates!: () => void;

  @communityUserNoteStore.State
  private noteLinkedEntityCount!: number;

  @communityUserTagStore.Action
  private loadTagLinkedEntityCount!: (entityUid: string) => Promise<void>;

  @communityUserTagStore.State
  private stateTagCount!: number;

  @agendaStore.Action
  private loadAgendaCounts!: (now: number) => void;

  @agendaStore.Getter
  private fetchPendingFilterCount!: number;

  private toolboxOpen = false;

  private openToolboxModal = false;

  private isScrolled = false;

  private ToolbarMenuActions = ToolbarMenuActions;

  private toolboxPayload: Partial<ToolboxQueryParams> = {
    view: ToolbarMenuActions.TOOLBAR_AGENDA,
    mode: 'list',
  };

  private get status(): MessageBoxActions {
    if (this.chatStoreCreated) {
      return this.state<MessageBoxActions>('status');
    }
    return MessageBoxActions.CONVERSATIONS;
  }

  private get selectedGroup(): MessageGroup | null {
    if (this.chatStoreCreated) {
      return this.getter<MessageGroup | null>('selectedGroup');
    }
    return null;
  }

  private get unReadCount(): number {
    if (this.chatStoreCreated) {
      return this.getter<number>('unReadCount');
    }
    return 0;
  }

  private get conversationsList(): MessageGroup[] {
    if (this.chatStoreCreated) {
      return this.getter<MessageGroup[]>('conversationsList');
    }
    return [];
  }

  private get isChatPanelFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled
      && (this.featureByKey(FeatureKeys.COMMUNITY_CHAT_PANEL)
        && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_PANEL).enabled);
  }

  private get isAgendaFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.AGENDA)
      && this.featureByKey(FeatureKeys.AGENDA).enabled;
  }

  private get isTagsFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.TAGS)
      && this.featureByKey(FeatureKeys.TAGS).enabled;
  }

  private get isNotesFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.NOTES)
      && this.featureByKey(FeatureKeys.NOTES).enabled;
  }

  private get menuList(): MenuModel[] {
    const menus: MenuModel[] = [];
    if (this.canShowDashboard) {
      menus.push({
        uid: FeatureKeys.DASHBOARD,
        action: ToolbarMenuActions.TOOLBAR_DASHBOARD,
        icon: ToolbarMenuIcons.TOOLBAR_DASHBOARD,
        name: 'toolbox.dashboard.title',
        dataCy: 'toolbox-dashboard',
      });
    }
    if (this.isAgendaFeatureEnabled) {
      menus.push({
        uid: FeatureKeys.AGENDA,
        action: ToolbarMenuActions.TOOLBAR_AGENDA,
        icon: ToolbarMenuIcons.TOOLBAR_AGENDA,
        name: 'toolbox.agenda.title',
        dataCy: 'toolbox-agenda',
      });
    }
    if (this.isChatPanelFeatureEnabled) {
      menus.push({
        uid: FeatureKeys.COMMUNITY_CHAT_FEATURE,
        action: ToolbarMenuActions.TOOLBAR_CHAT,
        icon: ToolbarMenuIcons.TOOLBAR_CHAT,
        name: 'toolbox.messages.title',
        dataCy: 'toolbox-chat',
      });
    }
    if (this.isNotesFeatureEnabled) {
      menus.push({
        uid: FeatureKeys.NOTES,
        action: ToolbarMenuActions.TOOLBAR_NOTES,
        icon: ToolbarMenuIcons.TOOLBAR_NOTES,
        name: 'toolbox.notes.title',
        dataCy: 'toolbox-notes',
      });
    }
    if (this.isTagsFeatureEnabled) {
      menus.push({
        uid: FeatureKeys.TAGS,
        action: ToolbarMenuActions.TOOLBAR_TAGS,
        icon: ToolbarMenuIcons.TOOLBAR_TAGS,
        name: 'toolbox.tags.title',
        dataCy: 'toolbox-tags',
      });
    }
    return menus;
  }

  private get canShowDashboard(): boolean {
    if (this.featureByKey(FeatureKeys.DASHBOARD)
      && this.featureByKey(FeatureKeys.DASHBOARD)?.enabled) {
      return !!((this.isAgendaFeatureEnabled)
        || (this.featureByKey(FeatureKeys.CONNECTIONS)
          && this.featureByKey(FeatureKeys.CONNECTIONS)?.enabled)
        || (this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
          && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)?.enabled));
    }
    return false;
  }

  private get entityPageUid(): string | null {
    if (this.currentPage && this.currentPage.paramName && this.currentSupportedEntityType) {
      return this.$route.params[this.currentPage.paramName] || '';
    }
    return null;
  }

  private get currentSupportedEntityType(): string | null {
    if (this.currentPage && this.currentPage.entityType?.code) {
      const isSupportedEntityType = ['communityUser',
        'product',
        'largeProduct',
        'deal',
        'article',
        'exhibitor',
        'session',
        'speaker'].includes(this.currentPage.entityType.code);
      return isSupportedEntityType ? this.currentPage.entityType.code : null;
    }
    return null;
  }

  created(): void {
    this.notifyEvents = [NotificationEventType.MEETING_PARTICIPANT, NotificationEventType.MEETING];
    window.addEventListener('scroll', () => {
      this.isScrolled = window.scrollY > 100;
    });
    this.$root.$on('on-toolbox-count-load', this.loadToolboxItemsCount);
    this.displayToolboxMessage();
  }

  mounted(): void {
    this.$eventsBus.on('ontoolbox', (event, payload): void => {
      this.toolboxPayload = payload as unknown as ToolboxQueryParams;
      this.doAction(this.toolboxPayload.view || ToolbarMenuActions.TOOLBAR_AGENDA);
    });

    this.$eventsBus.on('close-toolbox', (): void => {
      this.$bvModal.hide('toolbox-modal');
      this.openToolboxModal = false;
      this.currentToolboxModal = '';
    });

    this.$root.$on('clear-toolbox-payload-data', () => {
      this.toolboxPayload.data = undefined;
    });
  }

  protected notificationCallback(event: SubscriptionEvent): void {
    if (event.type === NotificationEventType.MEETING_PARTICIPANT
      || event.type === NotificationEventType.MEETING) {
      this.loadAgendaCounts(
        getUnixTime(
          DateTimeHelper.zonedToUTCTimeDate(
            DateTimeHelper.getCurrentDateTime(),
            this.selectedTzName,
          ),
        ),
      );
    }
  }

  private changeStatus(status: MessageBoxActions): void {
    return this.commit('changeStatus', status);
  }

  private setSelectedGroup(group: string | null): void {
    return this.commit('setSelectedGroup', group);
  }

  private toggleChat(close?: boolean): void {
    return this.commit('toggleChat', close);
  }

  @Watch('windowWidth', { deep: true })
  private displayToolboxMessage(): void {
    if (this.currentToolboxModal === ToolbarMenuActions.TOOLBAR_CHAT) {
      if (parseInt(this.windowWidth.value, 10) < 1200) {
        if (this.openToolboxModal) {
          this.openToolboxModal = false;
          this.toggleChat(false);
        }
      } else {
        this.openToolboxModal = true;
        this.toggleChat(true);
      }
    }
  }

  private doAction(action: string | null): void {
    if (action === ToolbarMenuActions.TOOLBAR_CHAT) {
      if (!this.selectedGroup
        && this.conversationsList.length > 0
        && this.status !== MessageBoxActions.NEW_CONVERSATION) {
        this.setSelectedGroup(this.conversationsList[0].uid);
        this.changeStatus(MessageBoxActions.MESSAGES);
      }

      if (!this.isDesktop) {
        this.toggleChat(false);
        return;
      }
    }
    if (action) {
      if (this.openToolboxModal && this.currentToolboxModal === action) {
        this.$bvModal.hide('toolbox-modal');
      } else {
        this.openToolboxModal = true;
        this.$bvModal.show('toolbox-modal');
      }
      this.currentToolboxModal = action;
    }
  }

  @Watch('openToolboxModal')
  private toggleScrollbar(): void {
    if (this.openToolboxModal) {
      this.toggleChat(true);
    }
  }

  private toggleOpenToolbox(): void {
    this.toolboxOpen = !this.toolboxOpen;
  }

  @Watch('entityPageUid')
  private loadToolboxItemsCount(): void {
    this.resetNoteStates();
    if (this.currentPage && !this.currentPage.parentPage && this.entityPageUid) {
      if (this.isTagsFeatureEnabled) {
        this.loadTagLinkedEntityCount(this.entityPageUid);
      }
      if (this.isNotesFeatureEnabled) {
        this.loadNoteLinkedEntityCount(this.entityPageUid);
      }
    }
  }
}
