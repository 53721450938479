import Vue from 'vue';
import Component from 'vue-class-component';
import CommunityUserUrl from '@/models/graphql/CommunityUserUrl';
import UrlType from '@/utils/enums/UrlType';
import ToastActionType from '@/utils/enums/ToastActionType';
import SocialNetworkDetector from 'social-network-detector';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import { twitterUrlType } from '@/utils/helpers/RegexHelper';

export type Url = CommunityUserUrl | ExhibitorUrl;

@Component
export default class SocialUrlWrapper extends Vue {
  protected dataNotSaved = false;

  protected inputChanged = false;

  protected currentSelfContext = '';

  protected userContacts: {
    uid: string;
    email: string;
    phones: Url[];
    mobilePhones: Url[];
    websiteUrls: Url[];
    secondaryWebsiteUrls: Url[];
    socialsUrls: Url[];
  } = {
    uid: '',
    email: '',
    phones: [],
    mobilePhones: [],
    websiteUrls: [],
    secondaryWebsiteUrls: [],
    socialsUrls: [],
  };

  protected onUrlInputChange(
    url: Url,
    urlLocalIndex: number,
    userUrlDAO: {
      userUrls: Url[] | undefined;
      createUserUrl: (url: Url, index: number) => void;
      updateUserUrl: (userUrl: Url) => void;
    },
    hooks: {
      onRemoveUrlClick: (uid: string, urlType: UrlType, urlIndexLocal: number) => void;
      showToast: (type: ToastActionType) => void;
    },
  ): void {
    const { userUrls } = userUrlDAO;
    const { onRemoveUrlClick } = hooks;
    this.dataNotSaved = false;
    if (url.url !== '' && this.inputChanged) {
      const urlType = url.name ? url.name : url.type;
      switch (urlType) {
        case UrlType.MOBILE_PHONE:
          this.currentSelfContext = `mobile-phone-${urlLocalIndex}`;
          url.type = 'mobile';
          break;
        case UrlType.PHONE:
          this.currentSelfContext = `phone-${urlLocalIndex}`;
          url.type = 'phone';
          break;
        case UrlType.WEBSITE:
          this.currentSelfContext = `website-${urlLocalIndex}`;
          url.type = 'website';
          break;
        case UrlType.SECONDARY_WEBSITE:
          this.currentSelfContext = `secondary-website-${urlLocalIndex}`;
          url.type = 'secondary-website';
          break;
        case UrlType.SOCIAL:
          url.type = SocialUrlWrapper.detectUrlType(url);
          this.currentSelfContext = `social-${urlLocalIndex}`;
          break;
        default:
          this.currentSelfContext = '';
          break;
      }
      this.createOrUpdateUserUrl(url, urlLocalIndex, userUrlDAO, hooks);
      this.inputChanged = false;
    } else if (this.inputChanged) {
      const index = userUrls?.findIndex((el: Url) => el.uid === url.uid);
      if (index !== -1) {
        onRemoveUrlClick(url.uid, url.name as UrlType, urlLocalIndex);
      }
    }
  }

  protected createOrUpdateUserUrl(
    url: Url,
    urlLocalIndex: number,
    userUrlDAO: {
      userUrls: Url[] | undefined;
      createUserUrl: (url: Url, index: number) => void;
      updateUserUrl: (userUrl: Url) => void;
    },
    hooks: {
      onRemoveUrlClick: (uid: string, urlType: UrlType, urlIndexLocal: number) => void;
      showToast: (type: ToastActionType) => void;
    },
  ): void {
    const { userUrls, createUserUrl, updateUserUrl } = userUrlDAO;
    const { showToast } = hooks;

    if (!userUrls) {
      createUserUrl(SocialUrlWrapper.updateUrlTypeAndName(url), urlLocalIndex);
      return;
    }

    const index = userUrls
      .findIndex((el: Url): boolean => el.uid === url.uid);
    if (index === -1) {
      createUserUrl(SocialUrlWrapper.updateUrlTypeAndName(url), urlLocalIndex);
      return;
    }

    this.inputChanged = false;
    userUrls[index] = url;
    const userUrl: CommunityUserUrl = {
      uid: url.uid,
      name: url.name,
      type: url.type,
      url: url.url,
    };
    updateUserUrl(userUrl);
    showToast(ToastActionType.UPDATE_PERSONAL_INFORMATION);
  }

  protected static updateUrlTypeAndName(url: Url): Url {
    const newUrl: Url = { ...url };

    switch (newUrl.name) {
      case UrlType.MOBILE_PHONE:
        newUrl.type = 'mobile';
        break;
      case UrlType.PHONE:
        newUrl.type = 'phone';
        break;
      case UrlType.WEBSITE:
        newUrl.type = 'website';
        break;
      case UrlType.SECONDARY_WEBSITE:
        newUrl.type = 'secondary-website';
        break;
      case UrlType.SOCIAL:
        if (newUrl.url && (!/^http?:\/\//i.test(newUrl.url) && !/^https?:\/\//i.test(newUrl.url))) {
          newUrl.url = `https://${newUrl.url}`;
        }
        newUrl.type = SocialUrlWrapper.detectUrlType(url);
        break;
      default:
        break;
    }

    newUrl.name = newUrl.type ? newUrl.name : 'website';

    return newUrl;
  }

  protected static detectUrlType(url: CommunityUserUrl): string {
    switch (url.name) {
      case UrlType.MOBILE_PHONE:
      case UrlType.PHONE:
        return UrlType.PHONE;
      default:
        return twitterUrlType(url.url, SocialNetworkDetector.detect(url.url) ?? UrlType.WEBSITE);
    }
  }
}
