import { RawLocation, Route } from 'vue-router';
import { Vue } from 'vue-property-decorator';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';

export default class AuthPageGuard {
  static beforeEnter(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
  ): void {
    const isLoginPathHasRedirection = from.name !== null
      && from.name !== 'enter-email'
      && from.name !== 'sign-up'
      && from.name !== 'no-sign-up'
      && from.name !== 'email-sent'
      && from.name !== 'enter-information'
      && from.name !== 'email-disambiguated'
      && from.name !== 'change-email'
      && from.name !== 'enter-password'
      && from.name !== 'password-reset'
      && from.name !== 'create-password';
    if (isLoginPathHasRedirection) {
      next((vm) => {
        if (vm.$store.state.sessionSelectedTab) {
          Object.assign(from.query, { sessionSelectedTab: vm.$store.state.sessionSelectedTab });
          vm.$store.commit('setSessionSelectedTab', null);
        }
        let redirect = encodeURIComponent(vm.$router.resolve({
          params: from.params,
          path: from.path,
          query: from.query,
        }).href);
        if (from.query && from.query.redirect) {
          redirect = encodeURIComponent(decodeURIComponent(from.query.redirect as string));
        } else if (from.name === 'sign-in') {
          redirect = encodeURIComponent('/');
        }
        if (redirect) {
          vm.$router.replace({
            name: to.name as string,
            params: to.params,
            query: { redirect },
          });
        }
      });
    }
    next((vm) => {
      const signupPages = ['sign-up', 'enter-information'].includes(to.name || '');
      const { isUnifyPortal, featureByKey } = vm.$store.getters;
      const isInvalidSignIn = !signupPages && !featureByKey(FeatureKeys.UNIFY_LOGIN)?.enabled;
      const isInvalidSignup = signupPages && !featureByKey(FeatureKeys.USER_SIGNUP)?.enabled;

      if ((isUnifyPortal && (isInvalidSignIn || isInvalidSignup))
        || vm.$store.getters['AuthenticationStore/isAuthenticated']) {
        vm.$router.replace('/');
      }
    });
    next();
  }
}
