export enum FeatureKeys {
  AGENDA = 'community_agenda_feature',
  COMMUNITY_ACTIVITY_FEED_REACTIONS = 'community_activity_feed_reactions',
  COMMUNITY_ACTIVITY_FEED_REPOSTS = 'community_activity_feed_reposts',
  COMMUNITY_AGENDA_FEATURE = 'community_agenda_feature',
  COMMUNITY_AUTO_OPEN_SESSION_CHAT = 'community_session_chat_auto_open',
  COMMUNITY_AUTO_OPEN_SESSION_QNA = 'community_session_qna_auto_open',
  COMMUNITY_CHAT_FEATURE = 'community_chat_feature',
  COMMUNITY_CHAT_PANEL = 'community_chat_panel',
  COMMUNITY_COMPANY_PROFILE_COUNTRY_FEATURE = 'community_company_profile_country_feature',
  COMMUNITY_CONNECTIONS_FEATURE = 'community_connections_feature',
  COMMUNITY_ENABLE_ACTIVITY_FEED = 'community_enable_activity_feed',
  COMMUNITY_ENABLE_BLOCKED_USERS = 'community_enable_blocked_users',
  COMMUNITY_ENABLE_COMPANY_INVITATION = 'community_enable_company_invitation',
  COMMUNITY_ENABLE_COMPANY_MEETING = 'community_enable_company_meeting_management',
  COMMUNITY_ENABLE_ENHANCED_LOGIN = 'community_enable_enhanced_login',
  COMMUNITY_ENABLE_PROFILE_VISIBILITY = 'community_enable_profile_visibility',
  COMMUNITY_ENABLE_SMART_APP_BANNER = 'community_enable_smart_app_banners',
  COMMUNITY_EXTERNAL_REGISTRATION_FEATURE = 'community_external_registration_feature',
  COMMUNITY_FORCE_UPDATE_USER_FEATURE = 'community_force_update_user_feature',
  COMMUNITY_CREDIT_CARD_FEATURE = 'community_credit_card_feature',
  COMMUNITY_BILL_ME_LATER_FEATURE = 'community_bill_me_later_feature',
  COMMUNITY_MEETING_CHAT = 'community_meeting_chat',
  COMMUNITY_POST_ENABLE_COMMENT = 'community_post_enable_comment',
  COMMUNITY_SESSION_RATING = 'community_session_rating',
  COMMUNITY_TIMEZONE_FEATURE = 'community_timezone_feature',
  COMMUNITY_USER_PROFILE_SETTINGS = 'community_user_profile_settings',
  COMMUNITY_LIVE_POLLS_FEATURE = 'community_live_polls_feature',
  COMMUNITY_LIVE_QNA_FEATURE = 'community_live_qna_feature',
  COMMUNITY_PRODUCTS_FEATURED_FEATURE = 'community_products_featured_feature',
  COMMUNITY_SALES_SERVICE_FEATURE = 'community_sales_service',
  COMMUNITY_SPEAKER_HUB_FEATURE = 'community_speaker_hub_feature',
  CONNECTIONS = 'community_connections_feature',
  COUNTRY_MY_PROFILE = 'community_user_country_my_profile',
  DASHBOARD = 'community_dashboard_feature',
  GLOBAL_SEARCH = 'community_global_search',
  NOTES = 'community_notepad_feature',
  TAGS = 'community_tags_feature',
  NOTIFICATIONS = 'community_notifications',
  USER_POST_CREATION = 'community_feed_disable_auth_user_posting',
  USER_SIGNUP = 'community_user_signup',
  UNIFY_LOGIN = 'enable_unify_login',
  VIDEO_USER_PROFILE_FEATURE = 'video_user_profile_feature',
  VIDEO_COMPANY_PROFILE_FEATURE = 'video_company_profile_feature',
  COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE = 'community_new_limits_calculation_feature',
  COMMUNITY_LATEX_FEATURE = 'community_latex_feature',
  COMMUNITY_SESSION_SETTINGS_FEATURE = 'community_session_settings_feature',
  COMMUNITY_ENABLE_AUTO_HIDE_CLOSED_POLLS = 'community_enable_auto_hide_closed_polls',
  COMMUNITY_AUTOPLAY_LIVE_STREAM = 'community_autoplay_live_stream',
  COMMUNITY_AUTOPLAY_RECORDED_STREAM = 'community_autoplay_recorded_stream',
}
