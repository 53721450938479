




































































































































































































import { Component, Prop } from 'vue-property-decorator';
import StandardModal from '@/components/modals/StandardModal.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import Session from '@/models/graphql/Session';
import Event from '@/utils/types/Event';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { namespace, State } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import { format } from 'date-fns';
import EntityType from '@/utils/enums/EntityType';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpeakerRole from '@/models/graphql/SpeakerRole';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class ManageConflictModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ default: '' })
  entityId!: string;

  @Prop({ default: '' })
  entityType!: string;

  @Prop({ default: '' })
  time!: string;

  @Prop({ default: '' })
  location!: string;

  @Prop({ default: '' })
  selectedDay!: string;

  @Prop({ default: 7 })
  selectedHour!: number;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  session!: Session;

  @Prop({ default: '' })
  events!: Event[];

  @Prop({ default: '' })
  isInAgenda!: string;

  @State
  private selectedTzAbbreviation!: string;

  @State
  private selectedTzName!: string;

  @State
  private dateLocale!: Locale;

  @State
  private authUser!: CommunityUser;

  @State
  private community!: Community;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @agendaStore.Mutation
  private setHourBeingViewed!: (hour: number) => void;

  onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private timeInterval(start: Date, end: Date): string {
    const parts: string[] = [];
    if (start) {
      parts.push(format(start, 'h:mm a', { locale: this.dateLocale })
        .replace(' ', ''));
    }
    if (end && end > start) {
      parts.push(format(end, 'h:mm a', { locale: this.dateLocale })
        .replace(' ', ''));
    }
    return parts.join(' – ');
  }

  // eslint-disable-next-line class-methods-use-this
  private images(event: Event): Array<{ uid: string; src: string; tooltip: string; text: string }> {
    if (event.entityType === EntityType.MEETING) {
      return (event.participants as MeetingParticipant[])
        .map((mp) => ({
          uid: mp.user?.uid as string,
          src: FileResourceHelper.getFullPath(mp.user?.pictureFileResource, 'w256'),
          tooltip: `${mp.user?.firstName} ${mp.user?.lastName}`.trim(),
          text: `${mp.user?.firstName?.charAt(0)} ${mp.user?.lastName?.charAt(0)}`.trim(),
        }));
    }
    if (event.entityType === EntityType.SESSION) {
      return (event.participants as SpeakerRole[])
        .map((s) => ({
          uid: s.speaker?.uid as string,
          src: FileResourceHelper.getFullPath(s.speaker?.photoFileResource, 'w256'),
          tooltip: `${s.speaker?.firstName} ${s.speaker?.lastName}`.trim(),
          text: `${s.speaker?.firstName?.charAt(0)} ${s.speaker?.lastName?.charAt(0)}`.trim(),
        }));
    }
    return [];
  }

  private openEventDetail(event: Event): void {
    this.setEventViewed(event);
    this.setViewMode(ViewMode.DETAIL);
    this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
  }

  private manageConflict(): void {
    this.setDayBeingViewed(this.selectedDay);
    this.setHourBeingViewed(this.selectedHour);
    this.setViewMode(ViewMode.DAILY_VIEW);
    this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
  }

  private toggleAgenda(): void {
    if (this.isInAgenda) {
      this.$emit('remove-from-agenda');
    } else {
      this.$emit('add-to-agenda');
    }
    this.onCancel();
  }
}
