enum EntityType {
  USER = 'CommunityUser',
  COMMUNITY_USER_TAG= 'CommunityUserTag',
  COMMUNITY_USER_CONNECTION= 'CommunityUserConnection',
  EVENT = 'event',
  COMPANY = 'Company',
  EXHIBITOR = 'Exhibitor',
  SESSION = 'Session',
  PRODUCT = 'Product',
  LARGE_PRODUCT = 'LargeProduct',
  BOAT_PRODUCT = 'BoatProduct',
  CATEGORY = 'Category',
  PROJECT = 'project',
  ARTICLE = 'Article',
  SPEAKER = 'Speaker',
  RESOURCE = 'Handout',
  CHANNEL = 'Channel',
  FILE_RESOURCE = 'FileResource',
  DEAL = 'Deal',
  NEWS = 'news',
  APPOINTMENT = 'appointment',
  MEETING = 'Meeting',
  MEETING_PARTICIPANT = 'MeetingParticipant',
  MEETING_REQUEST = 'MeetingRequest',
  EDITION = 'Edition',
  SUB_EDITION = 'SubEdition',
  LINK = 'link',
  TASK_STATE = 'task_state',
  CONNECTION_REQUEST = 'connection-request',
  APPOINTMENT_REQUEST = 'appointment-request',
  VENDOR_REQUEST = 'vendor-request',
  ADDRESS = 'address',
  MIXED = 'Mixed',
  BOOKMARK = 'CommunityUserBookmark',
  CONNECTION = 'CommunityUserConnection',
  MESSAGE_GROUP = 'MessageGroup',
  MESSAGE = 'Message',
  COMPANY_USER_ROLE = 'CompanyUserRole',
  HANDOUT = 'Handout',
  SPONSOR = 'Sponsor',
  SPONSOR_ROLE = 'SponsorRole',
  COMPANY_CMS = 'companyCms',
  SPEAKER_CMS = 'speakerCms',
  SESSION_CMS = 'sessionCms',
  TOPIC = 'Topic',
  SURVEY = 'Survey',
  SURVEY_QUESTION_CHOICE = 'SurveyQuestionChoice',
  FEED_ITEM_WRAPPER = 'FeedItemWrapper',
  EXHIBITOR_VIDEO = 'ExhibitorVideo',
  ORDER = 'Order',
  SALES_PACKAGE = 'SalesPackage',
  SCHEDULE_EVENT = 'ScheduleEvent',
  FEED_POST = 'FeedPost',
  POST_COMMENT = 'Comment',
  GEOZONE = 'Geozone',
}

export default EntityType;
