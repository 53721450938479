import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import CommunityUserAgendaEntryRepository from '@/repositories/CommunityUserAgendaEntryRepository';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import Agenda from '@/models/graphql/Agenda';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import AgendaRepository from '@/repositories/AgendaRepository';
import Event from '@/utils/types/Event';
import ToastActionType from '@/utils/enums/ToastActionType';

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class CommunityUserAgendaEntryStore extends LoadableStore<LoadableState> {
  private readonly communityUserAgendaEntryRepository = new CommunityUserAgendaEntryRepository();

  private readonly agendaRepository = new AgendaRepository();

  protected get repository(): CommunityUserAgendaEntryRepository {
    return this.communityUserAgendaEntryRepository;
  }

  /* eslint-disable @typescript-eslint/camelcase */
  @Action
  checkForConflicts({
    currentEntity,
    start,
    end,
  }: { currentEntity: string; start: number; end: number }): Promise<Event[]> {
    return this.agendaRepository.loadComposedQueries<Agenda>({
      operationName: 'CheckForAgendaConflicts',
      definitions: [{
        operation: 'meeting',
        alias: 'meetings',
        fragmentName: 'meetingForAgendaFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.MEETING_FILTER,
            value: {
              uid_not: currentEntity,
              isCancelled: false,
              _inMeeting: this.context.rootGetters.authUser.uid,
              startTimestamp_lte: end,
              endTimestamp_gte: start,
            },
          },
        }),
      }, {
        operation: 'session',
        alias: 'sessions',
        fragmentName: 'sessionForAgendaFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: {
              uid_not: currentEntity,
              _inAgenda: this.context.rootGetters.authUser.uid,
              startTimestamp_lte: end,
              endTimestamp_gte: start,
            },
          },
        }),
      }, {
        operation: 'scheduleEvent',
        alias: 'scheduleEvents',
        fragmentName: 'scheduleEventFullFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SCHEDULE_EVENT_FILTER,
            value: {
              uid_not: currentEntity,
              _inAgenda: this.context.rootGetters.authUser.uid,
              startTimestamp_lte: end,
              endTimestamp_gte: start,
            },
          },
        }),
      }],
      authUser: this.context.rootGetters.authUser.uid,
    })
      .then((response) => {
        const events: Event[] = [];
        events.push(...(response.meetings || []).map((m) => AgendaStoreHelper.convertMeetingToEvent(m, this.context.rootState.selectedTzName)));
        events.push(...(response.scheduleEvents || []).map((m) => AgendaStoreHelper.convertScheduleOfEventsToEvent(m, this.context.rootState.selectedTzName)));
        events.push(...(response.sessions || []).map((m) => AgendaStoreHelper.convertSessionToEvent(m, this.context.rootState.selectedTzName)));
        return events;
      });
  }

  @Action
  addToAgenda(payload: CommunityUserAgendaEntryFilter): Promise<CommunityUserAgendaEntry | undefined> {
    this.context.commit('load', true);
    return this.repository.create({
      authUser: this.context.rootState.authUser?.uid,
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: payload.userId,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: GqlEntityInputType.ID,
          value: payload.linkedUserId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_AGENDA_ENTRY_INPUT,
          value: { targetType: payload.entityType },
        },
      ]),
    })
      .then((response) => {
        if (response && payload.event) {
          this.context.dispatch(
            'AgendaStore/addToAgenda',
            {
              ...payload.event,
              isInAgenda: response.uid,
            },
            { root: true },
          );
          if (payload.showToast) {
            this.context.dispatch('ToastStore/addNewAction', { type: ToastActionType.ADD_TO_AGENDA }, { root: true });
          }
        }
        return response;
      })
      .finally(() => this.context.commit('load', false));
  }

  @Action
  removeFromAgenda(payload: CommunityUserAgendaEntryFilter): Promise<CommunityUserAgendaEntry | undefined> {
    this.context.commit('load', true);
    return this.repository.delete({
      authUser: this.context.rootState.authUser?.uid,
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.uid,
        },
      ]),
    })
      .then((response) => {
        if (payload.event) {
          this.context.dispatch(
            'AgendaStore/removeFromAgenda',
            payload.event,
            { root: true },
          );
        }
        if (payload.showToast) {
          this.context.dispatch('ToastStore/addNewAction', { type: ToastActionType.REMOVE_FROM_AGENDA }, { root: true });
        }
        return response;
      })
      .finally(() => this.context.commit('load', false));
  }
}
