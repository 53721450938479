var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['generic-page', _vm.page.cssClasses, {'overflow-hidden': !_vm.isOverflowVisible},
           {
             'pr-xl-6': _vm.authUserHasCompany
               && !_vm.page.parentPage
               && !!_vm.$route.meta.toolbar
               && !!_vm.authUser
               && _vm.isUnifyExhibitorPortal
           }],attrs:{"id":("page" + (_vm.page.id))}},[(_vm.showLiveSessionAlert)?_c('live-session-alert-component',{attrs:{"live-session":_vm.session,"widget":_vm.liveSessionWidget}}):_vm._e(),(_vm.notFoundPage && !_vm.isCompanyInviteLoading)?_c('not-found'):(!_vm.isCompanyInviteLoading
      && !_vm.authUserHasCompany
      && !_vm.notificationHasBeenInteracted
      && _vm.userHasCompanyRoleInvite)?_c('company-invitation-page',{attrs:{"user-company-invitation":_vm.userCompanyInvitation || undefined},on:{"on-decline":_vm.onCompanyInvitationDecline}}):((_vm.notAccessible || (!_vm.hasSalesPackageComponent && _vm.salesComponentId && _vm.availableSalesPackages.length <= 0))
      && !_vm.isCompanyInviteLoading && !_vm.packageLoading)?_c('forbidden'):(!_vm.hasSalesPackageComponent && _vm.availableSalesPackages.length > 0 && !_vm.isCompanyInviteLoading)?_c('package-upgrade',{attrs:{"available-sales-packages":_vm.availableSalesPackages,"current-active-menu":_vm.currentActiveMenu,"is-loading":_vm.packageLoading}}):_vm._l((_vm.widgets),function(w){return _c('load-widget-component',{key:w.uid,attrs:{"widget":w}})}),_c('portal-target',{attrs:{"name":("ep-user-detail-about-tab-" + (_vm.page.id))}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }