














































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Getter, namespace, State } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import Session from '@/models/graphql/Session';
import SessionRating from '@/models/graphql/SessionRating';
import CommunityUser from '@/models/graphql/CommunityUser';
import SurveyUserAnswer from '@/models/graphql/SurveyUserAnswer';
import { SurveyUserAnswerFilter } from '@/graphql/_Filters/SurveyUserAnswerFilter';
import ActionType from '@/utils/enums/ActionType';
import Survey from '@/models/graphql/Survey';
import { SurveyFilter } from '@/graphql/_Filters/SurveyFilter';
import { SessionRatingFilter } from '@/graphql/_Filters/SessionRatingFilter';
import SurveyStrategy from '@/utils/enums/SurveyStrategy';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import SessionRatingEnum from '@/utils/enums/SessionRatingEnum';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { isAfter, isBefore, parse } from 'date-fns';
import ActionRule from '@/utils/enums/ActionRule';
import ActionRuleService from '@/services/ActionRuleService';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import EntityType from '@/utils/enums/EntityType';

const surveyUserAnswerStore = namespace('SurveyUserAnswerStore');
const surveyStore = namespace('SurveyStore');
const sessionStore = namespace('SessionStore');
const sessionRatingStore = namespace('SessionRatingStore');

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
    ButtonComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class ActionButtonFeedback extends VueBaseActionButtonWidget {
  @sessionRatingStore.Action
  createReaction!: (payload: {
    rating: number;
    sessionId: string;
    userCommunityUid: string;
  }) => void;

  @sessionRatingStore.Action
  updateReaction!: (payload: {
    rating: number;
    uid?: string;
    id?: number;
    schemaCode?: string;
  }) => void;

  @sessionRatingStore.Mutation
  updateReactionState!: (rating: SessionRatingEnum) => void;

  @sessionRatingStore.Action
  getReaction!: (payload: { filter: SessionRatingFilter }) => Promise<SessionRating[]>;

  @sessionStore.Action
  loadLiveSession!: (payload: { uid: string }) => Promise<Session | undefined>;

  @Prop({ required: true })
  protected readonly actionResult!: Session;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected FeatureKeys = FeatureKeys;

  @sessionRatingStore.Getter
  private reaction!: SessionRatingEnum;

  @surveyUserAnswerStore.Action(ActionType.GET_ONE)
  private getAnswer!: (payload: { filter: SurveyUserAnswerFilter }) => Promise<SurveyUserAnswer>;

  @surveyStore.Action(ActionType.GET_ONE)
  private getSurvey!: (payload: { filter: SurveyFilter; fragmentName?: string }) => Promise<Survey>;

  @surveyStore.Getter
  private survey!: Survey;

  @State
  private selectedTzName!: string;

  private surveyAnswered = false;

  private showOverlay = false;

  private retrievedRating: SessionRating[] = [];

  private now = DateTimeHelper.getCurrentDateTime();

  private sessionEndTime: Date = DateTimeHelper.getCurrentDateTime();

  private displaySurveyNotification = false;

  private session: Session | null = null;

  private ActionRuleService = ActionRuleService;

  private listIcons = [
    {
      key: 'love',
      icon: 'heart',
      ratingValue: SessionRatingEnum.LOVED,
    },
    {
      key: 'like',
      icon: 'thumbs-up',
      ratingValue: SessionRatingEnum.LIKE,
    },
    {
      key: 'dislike',
      icon: 'thumbs-down',
      ratingValue: SessionRatingEnum.DISLIKE,
    },
  ];

  get getPopoverPlacement(): string {
    if (this.isMobile || this.isTablet) {
      return 'top';
    }
    return 'bottom';
  }

  private get showLiveSession(): boolean {
    if (this.session) {
      return (this.session.videoEnabled
        && ((this.session.videoLiveEnabled && this.session.videoType === 'live') || (this.session.videoArchiveEnabled
            && this.session.videoType === 'recorded'
            && !this.session.videoEnabledStartTime
            && !this.session.videoEnabledEndTime)
          || !!(this.session.videoArchiveEnabled
            && this.session.videoType === 'recorded'
            && this.session.videoEnabledStartTime
            && isBefore(
              DateTimeHelper.toUTC(parse(this.session.videoEnabledStartTime.toString(),
                DateTimeHelper.TIME_FORMAT_ISO_8601,
                new Date())),
              DateTimeHelper.toUTC(new Date()),
            ))
          || !!(this.session.videoArchiveEnabled
            && this.session.videoType === 'recorded'
            && this.session.videoEnabledEndTime
            && isAfter(
              DateTimeHelper.toUTC(parse(this.session.videoEnabledEndTime.toString(),
                DateTimeHelper.TIME_FORMAT_ISO_8601,
                new Date())),
              DateTimeHelper.toUTC(new Date()),
            )
          ))) || false;
    }
    return false;
  }

  private get canViewVideo(): boolean {
    if (this.session && this.session._actions) {
      // eslint-disable-next-line no-underscore-dangle
      return this.ActionRuleService.isRuleValid(this.session._actions, ActionRule.CAN_VIEW_LIVE_VIDEO);
    }
    return false;
  }

  private get isDisplayed(): boolean {
    return (this.survey
        || (this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING)
          && this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING).enabled))
      && (this.actionType === this.ActionButtonType.FEEDBACK);
  }

  private get actionButtonText(): string {
    if (((this.survey && !this.surveyAnswered) || (this.session && this.session.surveyUrl))
      && (!this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING)
        || !this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING).enabled)) {
      return `${this.$t('actions.take-survey')}`;
    }
    if (this.survey && this.surveyAnswered
      && (!this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING)
        || !this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING).enabled)
      && (this.session && !this.session.surveyUrl)) {
      return `${this.$t('actions.survey-completed')}`;
    }
    return `${this.$t('actions.feedback')}`;
  }

  created(): void {
    this.buttonsDisplay();
  }

  mounted(): void {
    if (this.entityUid) {
      if (this.entityType === this.EntityTypeEnum.SESSION) {
        this.getReaction({
          filter: {
            user: { uid: this.authUser?.uid },
            session: { uid: this.entityUid },
          },
        })
          .then((response) => {
            this.retrievedRating = response;
            if (response[0]?.rating !== undefined) {
              this.chooseAction(response[0]?.rating, false);
            }
          });
      }
      this.loadLiveSession({ uid: this.actionResult.uid })
        .then((result) => {
          if (result) {
            this.session = result;
            if (!this.session.surveyUrl) {
              this.loadSurveyAndAnswers();
            }
            if (this.session.endTime) {
              this.sessionEndTime = DateTimeHelper.utcToZonedTimeDate(
                `${this.session.endTime}Z`,
                this.selectedTzName,
              );
              this.now = DateTimeHelper.getCurrentDateTime();
            }
          }
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addHttps(url: string): string {
    if (url && (!/^http?:\/\//i.test(url) && !/^https?:\/\//i.test(url))) {
      return `https://${url}`;
    }
    return url;
  }

  private updateDisplaySurveyNotification(): void {
    this.now = DateTimeHelper.getCurrentDateTime();
    this.displaySurveyNotification = !!(((this.survey && !this.surveyAnswered)
        || (this.session && this.session.surveyUrl))
      && this.sessionEndTime
      && isAfter(this.now, this.sessionEndTime)
      && ((this.actionResult && this.actionResult.uid && this.actionResult._isInAgenda)
        || (this.showLiveSession
          && this.canViewVideo)));
  }

  @Watch('session.surveyUrl')
  private updateSession(): void {
    if ((this.survey && this.survey.uid && !this.surveyAnswered)
      || (this.session && this.session.surveyUrl)) {
      this.updateDisplaySurveyNotification();
    }
    setInterval(() => {
      this.updateDisplaySurveyNotification();
    }, 5000);
  }

  private loadSurveyAndAnswers(): void {
    let extraParam = {};
    let extraNullParam = {};
    let strategy = SurveyStrategy.SESSION_SURVEY;
    switch (this.entityType) {
      case this.EntityTypeEnum.EXHIBITOR:
        extraParam = { exhibitor: { uid: this.entityUid } };
        extraNullParam = { exhibitor: null };
        strategy = SurveyStrategy.EXHIBITOR_SURVEY;
        break;
      case this.EntityTypeEnum.SESSION:
        extraParam = { session: { uid: this.entityUid } };
        extraNullParam = { session: null };
        strategy = SurveyStrategy.SESSION_SURVEY;
        break;
      case this.EntityTypeEnum.SPEAKER:
        extraParam = { speaker: { uid: this.entityUid } };
        extraNullParam = { speaker: null };
        strategy = SurveyStrategy.SPEAKER_SURVEY;
        break;
      case this.EntityTypeEnum.PRODUCT:
        extraParam = { product: { uid: this.entityUid } };
        extraNullParam = { product: null };
        strategy = SurveyStrategy.PRODUCT_SURVEY;
        break;
      default:
        break;
    }
    const now = DateTimeHelper.currentTimestamp;
    /* eslint-disable @typescript-eslint/camelcase */
    this.getSurvey({
      filter: {
        AND: [
          { OR: [{ startTimestamp_lte: now }, { startTimestamp: null }] },
          { OR: [{ endTimestamp_gte: now }, { endTimestamp: null }] },
        ],
        ...extraParam,
      },
    })
      .then((survey) => {
        if (survey) {
          this.getAnswer({
            filter: {
              user: { uid: this.authUser ? this.authUser.uid : '' },
              ...extraParam,
            },
          })
            .then((response) => {
              if (response) {
                this.surveyAnswered = response && survey.uid === response.survey?.uid;
              } else {
                this.surveyAnswered = false;
              }
              this.updateDisplaySurveyNotification();
            });
        } else {
          this.getSurvey({
            filter: {
              AND: [
                { OR: [{ startTimestamp_lte: now }, { startTimestamp: null }] },
                { OR: [{ endTimestamp_gte: now }, { endTimestamp: null }] },
              ],
              ...extraNullParam,
              strategy,
            },
          })
            .then((survey2) => {
              if (survey2) {
                this.getAnswer({
                  filter: {
                    user: { uid: this.authUser ? this.authUser.uid : '' },
                    ...extraParam,
                  },
                })
                  .then((response) => {
                    if (response) {
                      this.surveyAnswered = response && survey2.uid === response.survey?.uid;
                    } else {
                      this.surveyAnswered = false;
                    }
                    this.updateDisplaySurveyNotification();
                  });
              }
            });
        }
      });
  }

  private dispatchAction(newRating: number, userCommunity: CommunityUser,
    sessionRatingData: SessionRating): void {
    if (this.reaction !== undefined && sessionRatingData) {
      this.updateReaction({
        rating: newRating,
        uid: sessionRatingData.uid,
        id: sessionRatingData.id,
        schemaCode: sessionRatingData.schemaCode,
      });
    } else {
      this.createReaction({
        rating: newRating,
        sessionId: this.entityUid,
        userCommunityUid: userCommunity?.uid,
      });
    }
  }

  private chooseAction(option: SessionRatingEnum, dispatch: boolean): void {
    const oldReaction = this.reaction;
    if (dispatch) {
      this.dispatchAction(option, this.authUser, this.retrievedRating?.[0]);
    } else {
      this.updateReactionState(option);
    }
    let statName = '';
    switch (option) {
      case SessionRatingEnum.LOVED:
        statName = 'loveSessionFeedback';
        break;
      case SessionRatingEnum.LIKE:
        statName = 'likeSessionFeedback';
        break;
      case SessionRatingEnum.DISLIKE:
        statName = 'dislikeSessionFeedback';
        break;
      default:
        break;
    }
    if (statName && dispatch && oldReaction !== option) {
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        EntityType.SESSION,
        StatLoggerActions.CLICK,
        statName,
        -1,
        this.entityUid,
        StatLoggerCategories.FEEDBACK,
        this.$i18n.locale,
      );
    }
  }

  private onMainButtonClick(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
    } else if (((this.survey && !this.surveyAnswered) || (this.session && this.session.surveyUrl))
      && (!this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING)
        || !this.featureByKey(FeatureKeys.COMMUNITY_SESSION_RATING).enabled)) {
      this.onTakeSurveyClick();
    } else {
      if (this.isMobile || this.isTablet) {
        this.showOverlay = !this.showOverlay;
        return;
      }
      this.showOverlay = false;
    }
  }

  private onTakeSurveyClick(): void {
    if (this.session?.surveyUrl) {
      const url = this.addHttps(this.session.surveyUrl);
      window.open(url, '_blank');
      return;
    }
    if (this.survey && this.survey.uid) {
      this.$router.push(`/survey/${this.entityUid}`);
    }
  }

  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }
}
