export const COMMUNITY_USER_LINK_BASE_FRAGMENT = `
  fragment communityUserLinkBaseFragment on CommunityUserLink {
    uid
    schemaCode
    targetType
    target {
      __typename
      uid
      ... on Session {
        _haveConflicts(myUid: "%authUser%")
      }
    }
  }
`;
