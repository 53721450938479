














































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import HeaderPanel from '@/views/adminPanel/company/tabs/HeaderPanel.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { Getter, namespace } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Speaker from '@/models/graphql/Speaker';

const localStorageCartStore = namespace('LocalStorageCartStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
    ButtonIconComponent,
    ButtonComponent,
    HeaderPanel,
  },
})
export default class CmsPageHeaderWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget) {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @permissionManagerStore.Getter
  protected canManageSales!: (companyUid: string) => boolean;

  @Prop({ required: false })
  private route!: string;

  @Prop({ required: false })
  private pageType!: string;

  @localStorageCartStore.Getter
  private numberOfItemInCart!: number;

  @localStorageCartStore.Action
  private setCart!: (cartUid: string) => void;

  private FileResourceHelper = FileResourceHelper;

  private isHamburgerMenuOpen = false;

  private FeatureKeys = FeatureKeys;

  private pageTypes = {
    company: 'company',
    session: 'session',
    speaker: 'speaker',
  };

  private get speaker(): Speaker | null {
    if (this.authUser && this.authUser.speakers?.length) {
      // eslint-disable-next-line prefer-destructuring
      Object.assign(this.$route.params, { speakerId: this.authUser.speakers[0].uid });
      return Speaker.hydrate(this.authUser.speakers[0]);
    }
    return null;
  }

  private get speakerImgSrc(): string | null {
    return this.speaker
    && this.speaker.photoFileResource
    && this.speaker.photoFileResource.path
      ? FileResourceHelper.getFullPath(this.speaker.photoFileResource, 'w56')
      : null;
  }

  private get avatarImg(): string {
    if (this.pageType === this.pageTypes.company) {
      return FileResourceHelper.getFullPath(this.company.logoFileResource, 'w56');
    }
    if (this.pageType === this.pageTypes.speaker) {
      return this.speakerImgSrc || '';
    }
    if (this.pageType === this.pageTypes.session) {
      return FileResourceHelper.getFullPath(this.authUser.pictureFileResource, 'w56');
    }
    return '';
  }

  private get customizedUrl(): string | null {
    if (this.route) {
      let r = this.route;
      if (this.pageType === this.pageTypes.speaker) {
        const matches = this.route.match(/%speakerId%/gs);
        if (matches) {
          matches.forEach((m) => {
            m.replaceAll('%', '')
              .trim();
            if (this.speaker) {
              r = r.replaceAll(m, this.speaker.uid);
            }
          });
          return r;
        }
        r = this.speaker && this.speaker.uid ? `${r}/${this.speaker.uid}` : r;
      } else {
        const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
        if (matches) {
          matches.forEach((m) => {
            const prop = m.replaceAll('%', '')
              .trim();
            if (this.$route.params[prop]) {
              r = r.replaceAll(m, this.$route.params[prop]);
            }
          });
          return r;
        }
      }

      return r;
    }
    return null;
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('company')
  private setCartCount(): void {
    this.setCart(this.company.uid);
  }

  private onPreviewClick(): void {
    this.isHamburgerMenuOpen = false;
    if (this.route && this.customizedUrl) {
      window.open(this.customizedUrl);
    } else {
      const route = this.$router.resolve({
        name: 'company-detail',
        params: { companyId: this.company.uid },
      });
      window.open(route.href);
    }
  }

  private onCartButtonClick(): void {
    this.isHamburgerMenuOpen = false;
    this.$router.push({
      name: 'company-cms-cart',
      params: { companyId: this.company.uid },
    });
  }

  private onToggleHambugerMenu(): void {
    this.isHamburgerMenuOpen = !this.isHamburgerMenuOpen;
  }
}
