import ActionButtonGroupWidgetStore from '@/store/widget/base/ActionButtonGroupWidgetStore';
import ActionListWidgetStore from '@/store/widget/base/ActionListWidgetStore';
import BigNumberWidgetStore from '@/store/widget/base/BigNumberWidgetStore';
import BreadcrumbWidgetStore from '@/store/widget/base/BreadcrumbWidgetStore';
import CarouselWidgetStore from '@/store/widget/base/CarouselWidgetStore';
import ChatStore from '@/store/chat/ChatStore';
import CompanyEventsWidgetStore from '@/store/widget/base/CompanyEventsWidgetStore';
import CountdownWidgetStore from '@/store/widget/base/CountdownWidgetStore';
import DescriptionFormWidgetStore from '@/store/widget/base/DescriptionFormWidgetStore';
import DetailPageHeaderWidgetStore from '@/store/widget/base/DetailPageHeaderWidgetStore';
import DynamicTextWidgetStore from '@/store/widget/base/DynamicTextWidgetStore';
import DynamicTitleWidgetStore from '@/store/widget/base/DynamicTitleWidgetStore';
import EntityInfoWidgetStore from '@/store/widget/base/EntityInfoWidgetStore';
import EntityListWidgetStore from '@/store/widget/base/EntityListWidgetStore';
import EntitySearchWidgetStore from '@/store/widget/base/EntitySearchWidgetStore';
import EntityTagsWidgetStore from '@/store/widget/base/EntityTagsWidgetStore';
import GenericPageHeaderWidgetStore from '@/store/widget/base/GenericPageHeaderWidgetStore';
import GenericWidgetStore from '@/store/widget/GenericWidgetStore';
import ImageTileListWidgetStore from '@/store/widget/base/ImageTileListWidgetStore';
import InformationCardListWidgetStore from '@/store/widget/base/InformationCardListWidgetStore';
import LogoListWidgetStore from '@/store/widget/base/LogoListWidgetStore';
import MapWidgetStore from '@/store/widget/base/MapWidgetStore';
import MemberAboutWidgetStore from '@/store/widget/base/MemberAboutWidgetStore';
import MembersWidgetStore from '@/store/widget/base/MembersWidgetStore';
import MyProfileAboutFormStore from '@/store/widget/base/MyProfileAboutFormStore';
import MyProfileContactsInfoWidgetStore from '@/store/widget/base/MyProfileContactsInfoWidgetStore';
import MyProfileInterestsWidgetStore from '@/store/widget/base/MyProfileInterestsWidgetStore';
import MyProfileRolesWidgetStore from '@/store/widget/base/MyProfileRolesWidgetStore';
import PillListWidgetStore from '@/store/widget/base/PillListWidgetStore';
import ResourcesFormWidgetStore from '@/store/widget/base/ResourcesFormWidgetStore';
import ScheduleOfEventWidgetStore from '@/store/widget/base/ScheduleOfEventWidgetStore';
import SearchResultWidgetStore from '@/store/widget/base/SearchResultWidgetStore';
import SessionCmsCoverWidgetStore from '@/store/widget/base/SessionCmsCoverWidgetStore';
import SessionCmsPageHeaderWidgetStore from '@/store/widget/base/SessionCmsPageHeaderWidgetStore';
import SpeakerSocialLinksWidgetStore from '@/store/widget/base/SpeakerSocialLinksWidgetStore';
import SponsorListWidgetStore from '@/store/widget/base/SponsorListWidgetStore';
import TabItemWidgetStore from '@/store/widget/base/TabItemWidgetStore';
import TabMenuWidgetStore from '@/store/widget/base/TabMenuWidgetStore';
import UiOnBoardingWidgetStore from '@/store/widget/base/UiOnBoardingWidgetStore';
import UserPrivacyGuardWidgetStore from '@/store/widget/base/UserPrivacyGuardWidgetStore';
import VideoEmbedWidgetStore from '@/store/widget/base/VideoEmbedWidgetStore';
import WideCarouselWidgetStore from '@/store/widget/base/WideCarouselWidgetStore';
import WizardWidgetStore from '@/store/widget/WizardWidgetStore';
import ExhibitorPortalHeaderWidgetStore from '@/store/widget/base/ExhibitorPortalHeaderWidgetStore';
import PollBaseStore from '@/store/widget/base/PollBaseStore';
import ExhibitorPortalUserDetailWidgetStore
  from '@/store/widget/base/ExhibitorPortalUserDetailWidgetStore';
import ProgramEntitySearchWidgetStore from '@/store/widget/base/ProgramEntitySearchWidgetStore';
import ExhibitorPortalUserDetailChatWidgetStore
  from '@/store/widget/base/ExhibitorPortalUserDetailChatWidgetStore';
import ExhibitorPortalUserDetailMeetingWidgetStore
  from '@/store/widget/base/ExhibitorPortalUserDetailMeetingWidgetStore';

export default {
  ActionButtonGroupWidgetStore,
  ActionListWidgetStore,
  BigNumberWidgetStore,
  BreadcrumbWidgetStore,
  CarouselWidgetStore,
  ChatStore,
  CompanyEventsWidgetStore,
  CountdownWidgetStore,
  DescriptionFormWidgetStore,
  DetailPageHeaderWidgetStore,
  DynamicTextWidgetStore,
  DynamicTitleWidgetStore,
  EntityInfoWidgetStore,
  EntityListWidgetStore,
  ProgramEntitySearchWidgetStore,
  EntitySearchWidgetStore,
  EntityTagsWidgetStore,
  GenericPageHeaderWidgetStore,
  GenericWidgetStore,
  ImageTileListWidgetStore,
  InformationCardListWidgetStore,
  LogoListWidgetStore,
  MapWidgetStore,
  MemberAboutWidgetStore,
  MembersWidgetStore,
  MyProfileAboutFormStore,
  MyProfileContactsInfoWidgetStore,
  MyProfileInterestsWidgetStore,
  MyProfileRolesWidgetStore,
  PillListWidgetStore,
  PollBaseStore,
  ResourcesFormWidgetStore,
  ScheduleOfEventWidgetStore,
  SearchResultWidgetStore,
  SessionCmsCoverWidgetStore,
  SessionCmsPageHeaderWidgetStore,
  SpeakerSocialLinksWidgetStore,
  SponsorListWidgetStore,
  TabItemWidgetStore,
  TabMenuWidgetStore,
  UiOnBoardingWidgetStore,
  UserPrivacyGuardWidgetStore,
  VideoEmbedWidgetStore,
  WideCarouselWidgetStore,
  WizardWidgetStore,
  ExhibitorPortalHeaderWidgetStore,
  ExhibitorPortalUserDetailWidgetStore,
  ExhibitorPortalUserDetailChatWidgetStore,
  ExhibitorPortalUserDetailMeetingWidgetStore,
};
