var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['program-event-item d-flex text-left column-gap-8 flex-column flex-xl-row', {'desktop': _vm.isDesktop},
           'px-3 py-4 px-md-4 bg-neutral-n-2-background', _vm.isMobile ? 'shadows-line-down' : 'rounded']},[_c('div',{staticClass:"left-col d-flex"},[(_vm.timeInterval)?_c('p',{class:['m-0 text-neutral-n-7-light-ink font-weight-bold text-nowrap',
               _vm.isMobile ? 'paragraph-3' : _vm.isTablet ? 'paragraph-1' : 'large']},[_vm._v(" "+_vm._s(_vm.timeInterval)+" ")]):_vm._e(),(!_vm.isDesktop
        && _vm.featureByKey(_vm.FeatureKeys.AGENDA)
        && _vm.featureByKey(_vm.FeatureKeys.AGENDA).enabled)?_c('div',{staticClass:"d-flex column-gap-1 ml-auto"},[(!_vm.isMobile)?_c('button-component',{class:['h-fit', {'invisible': !(_vm.haveConflict && _vm.inAgenda)}],attrs:{"rounded":true,"text":_vm.$t('program.conflict'),"size":"md","variant":"yellow"},on:{"on-click":_vm.scheduleConflict},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-clock","icon-classes":"icon-16"}})]},proxy:true}],null,false,1936101878)}):_c('button-icon-component',{class:['h-fit', {'invisible': !(_vm.haveConflict && _vm.inAgenda)}],attrs:{"shape":"round","size":"sm","variant":"yellow"},on:{"on-click":_vm.scheduleConflict},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"fa-regular fa-clock","icon-classes":"icon-8"}})]},proxy:true}],null,false,2620055486)}),_c('button-icon-component',{staticClass:"h-fit",attrs:{"disabled":_vm.isAgendaLoading,"size":_vm.isMobile ? 'sm' : 'md',"variant":_vm.inAgenda ? 'alt-2' : 'tertiary',"shape":"round"},on:{"on-click":_vm.toggleAgenda},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.isAgendaLoading)?_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon-classes":_vm.isMobile ? 'icon-8' : 'icon-16',"spin":true,"icon":"fa-solid fa-spinner-third"}}):_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon":_vm.inAgenda
              ? 'fa-regular fa-calendar-check'
              : 'fa-regular fa-calendar-plus',"icon-classes":_vm.isMobile ? 'icon-8' : 'icon-16'}})]},proxy:true}],null,false,2792818642)})],1):_vm._e()]),_c('div',{staticClass:"right-col d-flex column-gap-4"},[_c('div',{staticClass:"information d-flex flex-column row-gap-3 row-gap-md-4"},[(_vm.name)?[(_vm.isMobile)?_c('h6',{staticClass:"m-0 text-neutral-n-8-dark-ink font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_c('p',{staticClass:"large m-0 text-neutral-n-8-dark-ink font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]:_vm._e(),(_vm.description)?_c('div',[_c('p',{ref:"content",class:['desc label m-0 text-neutral-n-6-label',
                   _vm.isMobile ? 'label-3' : 'label-2',
                   {'ellipsis-3': !_vm.showAllDesc}]},[_vm._v(" "+_vm._s(_vm.description)+" ")]),(_vm.showMore)?_c('span',{class:['label medium m-0 text-blue-b-3-primary-blue cursor-pointer',
                   _vm.isMobile ? 'label-3' : 'label-2' ],on:{"click":function($event){_vm.showAllDesc = !_vm.showAllDesc}}},[_vm._v(_vm._s(_vm.showAllDesc ? _vm.$t('actions.less') : _vm.$t('actions.more')))]):_vm._e()]):_vm._e(),(_vm.url && _vm.urlLabel)?_c('button-component',{staticClass:"mt-3 w-fit",attrs:{"text":_vm.urlLabel,"size":"md","variant":"primary"},on:{"on-click":_vm.handleBaseUrl},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-arrow-up-right","icon-classes":"icon-16"}})]},proxy:true}],null,false,353113564)}):_vm._e()],2),(_vm.isDesktop
        && _vm.featureByKey(_vm.FeatureKeys.AGENDA)
        && _vm.featureByKey(_vm.FeatureKeys.AGENDA).enabled)?_c('div',{staticClass:"d-flex column-gap-1 ml-auto"},[_c('button-component',{class:['h-fit', {'invisible': !(_vm.haveConflict && _vm.inAgenda)}],attrs:{"rounded":true,"text":_vm.$t('program.conflict'),"size":"md","variant":"yellow"},on:{"on-click":_vm.scheduleConflict},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-clock","icon-classes":"icon-16"}})]},proxy:true}],null,false,1936101878)}),_c('button-icon-component',{staticClass:"h-fit",attrs:{"disabled":_vm.isAgendaLoading,"variant":_vm.inAgenda ? 'alt-2' : 'tertiary',"shape":"round","size":"md"},on:{"on-click":_vm.toggleAgenda},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.isAgendaLoading)?_c('font-awesome-component',{attrs:{"spin":true,"container-classes":"icon-24","icon":"fa-solid fa-spinner-third"}}):_c('font-awesome-component',{attrs:{"icon":_vm.inAgenda
              ? 'fa-regular fa-calendar-check'
              : 'fa-regular fa-calendar-plus',"container-classes":"icon-24"}})]},proxy:true}],null,false,2568429426)})],1):_vm._e()]),_c('manage-conflict-modal',{attrs:{"entity-id":_vm.uid,"events":_vm.events,"is-in-agenda":_vm.inAgenda,"modal-id":_vm.modalId,"selected-day":_vm.selectedDay,"selected-hour":_vm.selectedHour,"time":_vm.timeInterval,"title":_vm.name,"entity-type":"ScheduleEvent"},on:{"add-to-agenda":_vm.addEventToAgenda,"remove-from-agenda":_vm.removeSessionFromAgenda}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }