export const SESSION_FOR_GEOZONE_FRAGMENT = `
  fragment sessionForGeozoneFragment on Session {
    uid
    schemaCode
    name
    shortName
    description
    startTime
    startTimestamp
    endTime
    endTimestamp
  }
`;
