import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';
import { LARGE_PRODUCT_BASE_FRAGMENT } from '@/graphql/_Fragments/LargeProduct/Base';

export const BOOTH_BASE_FOR_ENTITY_LIST_FRAGMENT = `
  fragment boothBaseForEntityListFragment on Booth {
    id
    uid
    __typename
    schemaCode
    number
    exhibitors {
      ...exhibitorBaseFragment
    }
    largeProducts {
      ...largeProductBaseFragment
    }
  }
  ${EXHIBITOR_BASE_FRAGMENT}
  ${LARGE_PRODUCT_BASE_FRAGMENT}
`;
