


































































import { Component, Prop, Watch } from 'vue-property-decorator';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';

type PermissionKey =
  | 'manageTeamMembers'
  | 'manageContent'
  | 'manageMeetings'
  | 'setPosting'
  | 'manageSales'
  | 'manageInbox'
  ;

type PermissionOptions = {
  key: PermissionKey;
  title: string;
  subtitle?: string;
  isEnabled: boolean;
  companyRepPermission: boolean;
}

@Component({
  components: {
    AvatarSoloWidget,
    CheckboxSwitchComponent,
  },
})
export default class PermissionsManagerForm extends BreakpointWrapper {
  @Prop({ required: true })
  private communityUser!: CommunityUser;

  @Prop({ default: true })
  private canEditPermissions!: boolean;

  @Prop({ default: null })
  private authUserPermissions!: CompanyUserRole;

  @Prop({ default: false })
  private isAnInvite!: boolean;

  private FileResourceHelper = FileResourceHelper;

  private permissionOptions: PermissionOptions[] = [];

  private get permissionsPayload(): {
    companyUserRoleId: string;
    moderator: boolean;
    canPost: boolean;
    handleMeetings: boolean;
    manageSales: boolean;
    manageContent: boolean;
    manageCompanyUserRoles: boolean;
    } {
    return {
      companyUserRoleId: this.communityUser?.roleInCompany?.uid,
      moderator: !!this.permissionOptions.find((permission) => permission.key === 'manageInbox')?.isEnabled,
      canPost: !!this.permissionOptions.find((permission) => permission.key === 'setPosting')?.isEnabled,
      handleMeetings: !!this.permissionOptions.find((permission) => permission.key === 'manageMeetings')?.isEnabled,
      manageSales: !!this.permissionOptions.find((permission) => permission.key === 'manageSales')?.isEnabled,
      manageContent: !!this.permissionOptions.find((permission) => permission.key === 'manageContent')?.isEnabled,
      manageCompanyUserRoles: !!this.permissionOptions.find((permission) => permission.key === 'manageTeamMembers')?.isEnabled,
    };
  }

  private get isACompanyOwner(): boolean {
    return this.authUserPermissions.role.code === 'owner';
  }

  private created(): void {
    this.initPermissionOptions();
  }

  @Watch('communityUser')
  private initPermissionOptions(): void {
    this.permissionOptions = [];
    this.permissionOptions.push(...[{
      key: 'manageTeamMembers',
      title: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-team-members.title')}`,
      subtitle: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-team-members.subtitle')}`,
      isEnabled: !!this.communityUser?.roleInCompany?.manageCompanyUserRoles,
      companyRepPermission: this.authUserPermissions && !this.isACompanyOwner
        ? this.authUserPermissions.manageCompanyUserRoles : true,
    }, {
      key: 'manageContent',
      title: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-content.title')}`,
      subtitle: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-content.subtitle')}`,
      isEnabled: !!this.communityUser?.roleInCompany?.manageContent,
      companyRepPermission: this.authUserPermissions && !this.isACompanyOwner ? this.authUserPermissions.manageContent : true,
    }, {
      key: 'manageInbox',
      title: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-inbox.title')}`,
      subtitle: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-inbox.subtitle')}`,
      isEnabled: !!this.communityUser?.roleInCompany?.moderator,
      companyRepPermission: this.authUserPermissions && !this.isACompanyOwner ? this.authUserPermissions.moderator : true,
    }, {
      key: 'manageSales',
      title: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-sales.title')}`,
      subtitle: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-sales.subtitle')}`,
      isEnabled: !!this.communityUser?.roleInCompany?.manageSales,
      companyRepPermission: this.authUserPermissions && !this.isACompanyOwner ? this.authUserPermissions.manageSales : true,
    }, {
      key: 'setPosting',
      title: `${this.$t('admin-panel.modal.communityuser.account-permissions.set-posting.title')}`,
      subtitle: `${this.$t('admin-panel.modal.communityuser.account-permissions.set-posting.subtitle')}`,
      isEnabled: !!this.communityUser?.roleInCompany?.canPost,
      companyRepPermission: this.authUserPermissions && !this.isACompanyOwner ? this.authUserPermissions.canPost : true,
    }, {
      key: 'manageMeetings',
      title: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-meetings.title')}`,
      subtitle: `${this.$t('admin-panel.modal.communityuser.account-permissions.manage-meetings.subtitle')}`,
      isEnabled: !!this.communityUser?.roleInCompany?.handleMeetings,
      companyRepPermission: this.authUserPermissions && !this.isACompanyOwner ? this.authUserPermissions.handleMeetings : true,
    }] as PermissionOptions[]);
    if (this.canEditPermissions) {
      this.$emit('on-change', this.permissionsPayload);
    }
  }

  private onToggle(optionKey: string, isChecked: boolean): void {
    if (this.canEditPermissions) {
      this.permissionOptions = this.permissionOptions.map((permission) => {
        if (permission.key === optionKey) {
          permission.isEnabled = isChecked;
        }
        return permission;
      });
      this.$emit('on-change', this.permissionsPayload);
    }
  }
}
