























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import ValidationType from '@/utils/enums/ValidationType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import SocialNetworkDetector from 'social-network-detector';
import UrlType from '@/utils/enums/UrlType';

@Component({
  components: {
    FontAwesomeComponent,
  },
})
export default class MyProfileInputText extends VueContextLoading {
  @Prop({ default: '' })
  readonly label!: string;

  @Prop({ default: '' })
  readonly defaultValue!: string;

  @Prop({ default: 0 })
  readonly maxLength!: number;

  @Prop({ default: '' })
  readonly hintText!: string;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: false })
  readonly isActive!: boolean;

  @Prop({ default: false })
  readonly hasErrors!: boolean;

  @Prop({ default: false })
  readonly required!: boolean;

  @Prop({ default: false })
  readonly showOption!: boolean;

  @Prop({ default: false })
  readonly password!: boolean;

  @Prop({ default: '' })
  readonly error!: string;

  @Prop({ default: '' })
  readonly validation!: ValidationType;

  private errorParam = this.error;

  private hasErrorParam = this.hasErrors;

  private displayError = '';

  private passwordParam = false;

  private isActiveParam = this.isActive;

  private validationRegex = new RegExp('');

  private inputText = '';

  private isConfirmed = false;

  @Watch('password')
  initPassword(): void {
    this.passwordParam = this.password;
  }

  mounted(): void {
    this.inputText = this.defaultValue;
    this.initPassword();
    this.initDisplayError();
    this.initValidation();
  }

  @Watch('defaultValue')
  updateInput(): void {
    this.inputText = this.defaultValue;
  }

  @Watch('errorParam')
  initDisplayError(): void {
    switch (this.errorParam) {
      case 'email':
        this.displayError = this.$t('field-validation.email-invalid').toString();
        break;
      case 'required':
        this.displayError = this.$t('field-validation.required').toString();
        break;
      case 'emailExists':
        this.displayError = this.$t('field-validation.email-used').toString();
        break;
      case 'phone':
        this.displayError = this.$t('field-validation.phone').toString();
        break;
      case 'website':
      case 'secondary-website':
      case ValidationType.FACEBOOK:
      case ValidationType.INSTAGRAM:
      case ValidationType.TWITTER:
      case ValidationType.PINTEREST:
        this.displayError = this.$t('field-validation.website').toString();
        break;
      default:
        this.displayError = '';
    }
  }

  focusIn(): void {
    this.$emit('on-focus-in');
    this.isActiveParam = true;
  }

  focusOut(): void {
    if (!this.hasErrorParam) {
      this.$emit('on-focus-out', this.inputText);
    }
    this.isActiveParam = false;
  }

  @Watch('isLoading')
  watchLoading(): void {
    this.setIsConfirmed();
  }

  @Watch('selfContext')
  watchSelfContext(): void {
    this.setIsConfirmed();
  }

  setIsConfirmed(): void {
    if (this.selfContext !== '' && !this.isLoading) {
      this.isConfirmed = true;
    }
  }

  onBlur(): void {
    if (this.inputText !== '' || this.required) {
      this.testValidation();
      if (!this.hasErrorParam) {
        this.setIsConfirmed();
        this.$emit('blur');
      }
    } else {
      this.setIsConfirmed();
      this.$emit('blur');
      this.hasErrorParam = false;
      this.errorParam = '';
    }
  }

  @Watch('isConfirmed')
  watchIsConfirmed(): void {
    if (this.isConfirmed) {
      setTimeout(() => {
        this.isConfirmed = false;
      }, 4000);
    }
  }

  testValidation(): void {
    if (this.validation && this.validationRegex) {
      switch (this.validation) {
        case ValidationType.EMAIL:
          this.errorParam = 'email';
          break;
        case ValidationType.PHONE:
          this.errorParam = 'phone';
          break;
        case ValidationType.WEBSITE:
          this.errorParam = 'website';
          break;
        case ValidationType.REQUIRED:
          this.errorParam = 'required';
          break;
        case ValidationType.FACEBOOK:
          this.errorParam = ValidationType.FACEBOOK;
          break;
        case ValidationType.TWITTER:
          this.errorParam = ValidationType.TWITTER;
          break;
        case ValidationType.INSTAGRAM:
          this.errorParam = ValidationType.INSTAGRAM;
          break;
        case ValidationType.PINTEREST:
          this.errorParam = ValidationType.PINTEREST;
          break;
        default:
          this.errorParam = '';
          break;
      }

      if (this.required && this.inputText.trim() === '') {
        this.hasErrorParam = true;
      } else {
        switch (this.validation) {
          case ValidationType.FACEBOOK:
            this.hasErrorParam = this.getUrlType(this.addHttps(this.inputText)) !== UrlType.FACEBOOK;
            break;
          case ValidationType.TWITTER:
            this.hasErrorParam = this.getUrlType(this.addHttps(this.inputText)) !== UrlType.TWITTER;
            break;
          case ValidationType.INSTAGRAM:
            this.hasErrorParam = this.getUrlType(this.addHttps(this.inputText)) !== UrlType.INSTAGRAM;
            break;
          case ValidationType.PINTEREST:
            this.hasErrorParam = this.getUrlType(this.addHttps(this.inputText)) !== UrlType.PINTEREST;
            break;
          default:
            this.hasErrorParam = !this.validationRegex.test(this.inputText);
            break;
        }
      }
    }
  }

  initValidation(): void {
    switch (this.validation) {
      case ValidationType.EMAIL:
        // eslint-disable-next-line max-len
        this.validationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        break;
      case ValidationType.PHONE:
        // eslint-disable-next-line no-useless-escape
        this.validationRegex = /^[0-9-\+\(\)]+$/;
        break;
      case ValidationType.WEBSITE:
        // eslint-disable-next-line max-len
        this.validationRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
        break;
      default:
        this.validationRegex = new RegExp('');
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getUrlType(url: string): string {
    const detectedType = SocialNetworkDetector.detect(url);
    if (detectedType) {
      return detectedType;
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  addHttps(url: string): string {
    if (url && (!/^http?:\/\//i.test(url) && !/^https?:\/\//i.test(url))) {
      return `https://${url}`;
    }
    return url;
  }

  private onChange(): void {
    this.$emit('update:defaultValue', this.inputText);
  }
}
