import LoadableStore from '@/store/LoadableStore';
import LoadableState from '@/store/states/LoadableState';
import { Module, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class PageStateManagementStore extends LoadableStore<LoadableState> {
  private pageDataLoading: Record<number, string[]> = {};

  private dependenciesCreated: Record<number, string[]> = {};

  private dependenciesError: Record<number, string[]> = {};

  private pageReadyToLoadData: number[] = [];

  private pageReadyToDisplay: number[] = [];

  private loadDependencies: Record<string, Promise<object>> = {};

  get getLoadDependencies(): Record<string, Promise<object>> {
    return this.loadDependencies;
  }

  @Mutation
  initLoadingStates(page: number): void {
    if (Object.keys(this.pageDataLoading)
      .includes(String(page))) {
      this.pageDataLoading[page] = [];
      this.pageReadyToDisplay.push(page);
      this.pageReadyToDisplay = Array.from(new Set(this.pageReadyToDisplay));
    }
  }

  @Mutation
  removeReadyToLoadData(page: number): void {
    this.pageReadyToLoadData = this.pageReadyToLoadData.filter((p) => p !== page);
  }

  @Mutation
  addLoadingState(payload: { page: number; widget: string }): void {
    if (!Object.keys(this.pageDataLoading)
      .includes(String(payload.page))) {
      Object.assign(this.pageDataLoading, { [payload.page]: [] });
    }
    this.pageDataLoading[payload.page].push(payload.widget);
  }

  @Mutation
  addDependency(payload: { page: number; widget: string }): void {
    if (!Object.keys(this.dependenciesCreated)
      .includes(String(payload.page))) {
      Object.assign(this.dependenciesCreated, { [payload.page]: [] });
    }
    this.dependenciesCreated[payload.page].push(payload.widget);
  }

  @Mutation
  addDependencyError(payload: { page: number; widget: string }): void {
    if (!Object.keys(this.dependenciesError)
      .includes(String(payload.page))) {
      Object.assign(this.dependenciesError, { [payload.page]: [] });
    }
    this.dependenciesError[payload.page].push(payload.widget);
  }

  @Mutation
  initDependency(page: number): void {
    if (Object.keys(this.dependenciesCreated)
      .includes(String(page))) {
      this.dependenciesCreated[page] = [];
    }
  }

  @Mutation
  removeDependency(payload: { page: number; widget: string }): void {
    if (Object.keys(this.dependenciesCreated)
      .includes(String(payload.page))) {
      this.dependenciesCreated[payload.page] = this.dependenciesCreated[payload.page]
        .filter((dep) => dep !== payload.widget);
      if (this.dependenciesCreated[payload.page].length === 0) {
        this.pageReadyToLoadData.push(payload.page);
      }
    }
  }

  @Mutation
  toggleReadyToDisplay(page: number): void {
    if (Object.keys(this.pageReadyToDisplay)
      .includes(String(page))) {
      this.pageReadyToDisplay = this.pageReadyToDisplay.filter((p) => p !== page);
    } else {
      this.pageReadyToDisplay.push(page);
    }
  }

  @Mutation
  addLoadDependencies(payload: { key: string; promise: Promise<object> }): void {
    this.loadDependencies[payload.key] = payload.promise;
  }

  @Mutation
  removeLoadDependencies(key: string): void {
    delete this.loadDependencies[key];
  }

  @Mutation
  initPageStates(): void {
    delete this.pageDataLoading;
    this.pageDataLoading = {};
    delete this.dependenciesCreated;
    this.dependenciesCreated = {};
    delete this.dependenciesError;
    this.dependenciesError = {};
    this.pageReadyToLoadData = [];
    this.pageReadyToDisplay = [];
  }

  @Mutation
  initPageStatesForPage(page: number): void {
    delete this.pageDataLoading[page];
    delete this.dependenciesCreated[page];
    this.pageReadyToLoadData = this.pageReadyToDisplay.filter((p) => p !== page);
    this.pageReadyToDisplay = this.pageReadyToDisplay.filter((p) => p !== page);
  }
}
