var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100",class:[
    'order-card-component w-100',
    {'card-border': !_vm.isMobile},
    {'shadows-line-down': _vm.isMobile}
  ]},[_c('div',{class:[
      'd-flex align-items-center justify-content-between p-4',
      {'bg-red-r-1-light-red': _vm.order.status === _vm.orderStatus.REFUNDED},
      {'bg-yellow-y-1-light-yellow': _vm.order.status === _vm.orderStatus.WAITING_FOR_PAYMENT
        || _vm.order.status === _vm.orderStatus.PENDING
        || _vm.order.status === _vm.orderStatus.WAITING_FOR_APPROVAL},
      {'bg-green-g-1-light-green': _vm.order.status === _vm.orderStatus.PAID},
      {'flex-column': _vm.isMobile},
      {'order-card-header-border': !_vm.isMobile} ]},[_c('div',{class:['d-flex order-md-1 order-2 flex-column flex-md-row',
               'align-items-center justify-content-start column-gap-24 text-left',
               (_vm.isMobile) ? 'w-100 justify-content-between' : '']},[_c('div',{class:['d-flex flex-row flex-md-column', (_vm.isMobile) ? 'w-100 justify-content-between' : '']},[_c('p',{staticClass:"m-0 order-2"},[_vm._v(" "+_vm._s(_vm.getOrderStatus)+" ")]),_c('p',{staticClass:"m-0 order-1 caps caps-2 bold"},[_vm._v(" "+_vm._s(("" + (_vm.$t('sales.order.title.status'))))+" ")])]),_c('div',{class:['d-flex flex-row flex-md-column', (_vm.isMobile) ? 'w-100 justify-content-between' : '']},[_c('p',{staticClass:"m-0 order-2"},[_vm._v(" "+_vm._s(_vm.orderCreationTime)+" ")]),_c('p',{staticClass:"m-0 order-1 caps caps-2 bold"},[_vm._v(" "+_vm._s(("" + (_vm.$t('sales.order.title.order-placed'))))+" ")])]),_c('div',{class:['d-flex flex-row flex-md-column', (_vm.isMobile) ? 'w-100 justify-content-between' : '']},[_c('p',{staticClass:"m-0 order-2"},[_vm._v(" "+_vm._s(_vm.totalPrice)+" ")]),_c('p',{staticClass:"m-0 order-1 caps caps-2 bold"},[_vm._v(" "+_vm._s(("" + (_vm.$t('sales.order.title.total'))))+" ")])])]),_c('div',{class:['d-flex flex-row flex-md-column order-md-2 order-1', (_vm.isMobile) ? 'w-100 justify-content-between': '']},[_c('p',{staticClass:"m-0 order-2 text-right"},[_vm._v(" "+_vm._s(_vm.orderNumber)+" ")]),_c('p',{staticClass:"m-0 order-1 caps caps-2 bold text-left"},[_vm._v(" "+_vm._s(("" + (_vm.$t('sales.order.title.order-number'))))+" ")])])]),_c('div',{staticClass:"d-flex align-items-start p-4 order-details column-gap-6",class:[
      {'justify-content-between': _vm.isDesktop},
      {'flex-column row-gap-6': !_vm.isDesktop}
    ]},[_c('div',[_c('ul',{staticClass:"mb-0"},_vm._l((_vm.order.orderPackages),function(orderPackage){return _c('li',{key:orderPackage.uid,staticClass:"package-component text-neutral-n-8-dark-ink text-left"},[_vm._v(" "+_vm._s(((orderPackage.salesPackage.title || '') + " (" + (orderPackage.quantity) + "x)"))+" ")])}),0)]),_c('div',{class:[
        'd-flex row-gap-2',
        {'w-100': !_vm.isDesktop},
        {'order-buttons-lg': _vm.isDesktop},
        {'flex-column': !_vm.isTablet},
        {'justify-content-between column-gap-2': _vm.isTablet} ]},[(_vm.order.status === _vm.orderStatus.PENDING)?_c('div',{staticClass:"w-100"},[_c('button-icon-component',{staticClass:"w-100",attrs:{"size":"lg","variant":"primary","text":("" + (_vm.$t('sales.order.card.buttons.checkout')))},on:{"on-click":function($event){return _vm.mainButtonClick(_vm.order)}}})],1):_vm._e(),(_vm.order.status === _vm.orderStatus.PAID || _vm.order.status === _vm.orderStatus.REFUNDED)?_c('div',{staticClass:"w-100"},[_c('button-icon-component',{staticClass:"w-100",attrs:{"size":"lg","variant":"tertiary","text":("" + (_vm.$t('sales.order.card.buttons.invoice')))},on:{"on-click":function($event){return _vm.invoiceClick(_vm.order)}}})],1):_vm._e(),_c('div',{staticClass:"w-100"},[_c('button-icon-component',{staticClass:"w-100",attrs:{"size":"lg","variant":"tertiary","text":("" + (_vm.$t('sales.order.card.buttons.order-detail')))},on:{"on-click":function($event){return _vm.orderDetailClick(_vm.order)}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }