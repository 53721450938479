export const SCHEDULE_EVENT_PROGRAM_FRAGMENT = `
  fragment scheduleEventProgramFragment on ScheduleEvent {
    uid
    schemaCode
    type
    name
    description
    url
    urlLabel
    startTime
    startTimestamp
    endTime
    endTimestamp
    _isInAgenda(myUid: "%authUser%")
    _haveConflicts(myUid: "%authUser%")
  }
`;
