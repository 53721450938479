









































































































import {
  Component, Inject, Mixins, Prop, Watch,
} from 'vue-property-decorator';
import Variant from '@/utils/enums/Variant';
import SelectedListParams from '@/utils/types/SelectedListParams';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import VueContextLoading from '@/components/VueContextLoading';
import PillWidget from '@/components/pill/PillWidget.vue';
import EntitySearchFilterModal from '@/components/modals/EntitySearchFilterModal.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: {
    FontAwesomeComponent,
    LoadingSpinnerComponent,
    EntitySearchFilterModal,
    PillWidget,
    InputSearchComponent,
    FilterItemComponent,
  },
})
export default class FilterComponent extends Mixins(BreakpointWrapper, VueContextLoading) {
  @Inject({ from: 'windowWidth' })
  private readonly windowWidth!: { value: number };

  @Inject({ from: 'windowHeight' })
  private readonly windowHeight!: { value: number };

  @Prop({
    required: false,
    default: null,
  })
  private storeName!: string;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ required: true })
  private readonly list!: SelectedListParams[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly selectedList!: string[];

  @Prop({
    required: false,
    default: false,
  })
  private readonly isOpen!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  private readonly code!: string;

  @Prop({
    required: false,
    default: false,
  })
  private isDateFilter!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private logStats!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private entityType!: string;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  private filterList = this.list;

  private showedFilterList = this.list;

  private showList = this.isOpen;

  private selectedIds: string[] = this.selectedList;

  private loading = false;

  private variantEnum = Variant;

  private styles: { top: string; left: string; width: string } = {
    top: '0px',
    left: this.isUnifyExhibitorPortal && this.breakpoint.value === 'xl' ? 'var(--side-menu-width)' : '0px',
    width: '100%',
  };

  mounted(): void {
    this.$eventsBus.on('clear-all-pills-entity-search', (event, payload): void => {
      const data = payload as { code: string };
      if (data.code === this.code) {
        this.toggleFilterItem('all');
      }
    });
    this.updateStyles();
    this.updateList();
    this.updateSelectedList();
    document.addEventListener('scroll', this.closeList);
    this.$watch('showList', () => (this.showList ? this.updateStyles() : ''));
  }

  @Watch('selectedList')
  private updateSelectedList(): void {
    const selectedIds = [...this.selectedList];
    if (selectedIds.length === 0) {
      selectedIds.push('all');
    }
    this.selectedIds = selectedIds;
  }

  @Watch('list')
  private updateList(): void {
    const filterList = this.list ? [...this.list] : [];
    filterList.unshift({
      id: 'all',
      selected: false,
      title: this.$t('filter-component.all'),
    } as SelectedListParams);
    this.filterList = filterList;
    this.updateStyles();
    this.updateSelectedList();
    this.loading = false;
  }

  @Watch('showList')
  private close(): void {
    this.$emit('toggle');
  }

  private loadFilterData(): Promise<void> {
    return this.$store.dispatch(`${this.storeName}/loadFilterData`, this.code);
  }

  @Watch('isOpen')
  private stateChange(): void {
    this.showList = this.isOpen;
  }

  private updateStyles(): void {
    this.$nextTick(() => {
      if (this.$el) {
        const element = this.$el.querySelector('div.filter-pill');
        const filter = this.$el.querySelector('div.filter-list');
        const list = this.$el.querySelector('.list');

        if (element && filter) {
          const top = element.getBoundingClientRect().top + element.getBoundingClientRect().height + 8;
          this.styles = {
            top: `${top}px`,
            left: this.isUnifyExhibitorPortal && this.breakpoint.value === 'xl' ? 'var(--side-menu-width)' : '0px',
            width: '100%',
          };
        }

        this.showedFilterList = this.filterList ? [...this.filterList] : [];
        if (list && filter) {
          this.$nextTick(() => {
            list.childNodes.forEach((child) => {
              const childHtmlElement = child as HTMLElement;
              if (typeof childHtmlElement.getBoundingClientRect === 'function') {
                const childElementBottom = (child as HTMLElement).getBoundingClientRect().bottom;
                if (childElementBottom > filter.getBoundingClientRect().bottom) {
                  this.showedFilterList.pop();
                }
              }
            });
          });
          setTimeout(() => {
            const moreButton = this.$el.querySelector('.more-button');
            if (moreButton) {
              const moreButtonBottom = moreButton.getBoundingClientRect().bottom;
              if (moreButtonBottom > filter.getBoundingClientRect().bottom) {
                this.showedFilterList.pop();
              }
            }
          }, 50);
        }
      }
    });
  }

  private toggleFilterItem(id: string): void {
    let removeAll = false;
    const idIndex = this.selectedIds.findIndex((i) => i === id);
    if (id === 'all') {
      this.selectedIds = ['all'];
    } else if (idIndex < 0) {
      if (this.isDateFilter) {
        this.selectedIds = [id];
      } else {
        removeAll = this.selectedIds.includes('all');
        this.selectedIds.push(id);
      }
      if (this.logStats) {
        const selectedFilter = this.list.find((item) => item.id === id);
        if (selectedFilter) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code || '',
            '',
            StatLoggerActions.FILTER,
            selectedFilter.title,
            -1,
            '',
            this.entityType,
            this.$i18n.locale,
          );
        }
      }
    } else {
      removeAll = this.selectedIds.includes('all');
      this.selectedIds.splice(idIndex, 1);
    }

    const allIdIndex = this.selectedIds.findIndex((i) => i === 'all');
    if (removeAll) {
      this.selectedIds.splice(allIdIndex, 1);
    }

    const selectedIdsFilter = [...this.selectedIds];
    if (selectedIdsFilter.includes('all')) {
      selectedIdsFilter.splice(allIdIndex, 1);
    }
    this.updateFilterList();
    this.$emit('selected-filters', selectedIdsFilter);
  }

  private updateFilterList(): void {
    this.selectedList.forEach((uid) => {
      this.filterList.forEach((f) => {
        f.selected = f.id === uid;
      });
    });
  }

  private closeList(): void {
    this.showList = false;
  }

  private toggleModal(): void {
    this.$bvModal.show(this.code);
  }

  private onClickOutside(): void {
    this.showList = false;
    this.$root.$emit(`toggle-filter-${this.code}`, this.showList);
  }

  private toggleListClick(): void {
    this.showList = !this.showList;
    this.loading = true;
    this.$root.$emit(`toggle-filter-${this.code}`, this.showList);
    if (this.storeName && this.list.length === 0) {
      this.loadFilterData()
        .catch(() => {
          this.loading = false;
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    } else {
      this.loading = false;
    }
  }

  private filterModalConfirm(selectedIds: string[]): void {
    this.selectedIds = selectedIds;
    if (selectedIds.length === 1 && selectedIds.includes('all')) {
      this.updateFilterList();
      this.$emit('selected-filters', []);
    } else {
      this.updateFilterList();
      this.$emit('selected-filters', selectedIds);
    }
    this.$bvModal.hide(this.code);
  }

  private filterModalCancel(): void {
    this.$bvModal.hide(this.code);
  }
}
