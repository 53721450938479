






































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import EntitySearchFilterWidget from '@/components/entity-search/EntitySearchFilterWidget.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import SessionsCalendarComponent
  from '@/components/sessions-calendar/SessionsCalendarComponent.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import AlertComponent from '@/components/AlertComponent.vue';
import { mixins } from 'vue-class-component';
import AlertType from '@/utils/enums/AlertType';
import EntityType from '@/utils/enums/EntityType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import IllustrationType from '@/utils/enums/IllustrationType';
import SegmentedElementParams from '@/utils/types/SegmentedElementParams';
import SizeDigit from '@/utils/enums/SizeDigit';
import EntitySearchDisplayMode from '@/utils/enums/EntitySearchDisplayMode';
import SegmentedControlsComponent from '@/components/SegmentedControlsComponent.vue';
import AdvertiseWidget from '@/components/AdvertiseWidget.vue';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import WidgetCardMapping from '@/utils/widgets/WidgetCardMapping';
import ClientStorage from '@/utils/ClientStore';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import EntityFilterRange from '@/utils/types/entity-search/EntityFilterRange';
import SelectedListParams from '@/utils/types/SelectedListParams';
import GUUID from '@/utils/GUUID';

/* eslint-disable @typescript-eslint/camelcase,max-len,no-restricted-globals,no-underscore-dangle */

@Component({
  components: {
    AdvertiseWidget,
    SegmentedControlsComponent,
    IllustrationComponent,
    LoadingSpinnerComponent,
    SessionsCalendarComponent,
    FontAwesomeComponent,
    PaginationComponent,
    EntitySearchFilterWidget,
    AlertComponent,
  },
})
export default class EntitySearchWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'EntitySearchWidgetStore';

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayRecommendedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayFeaturedBorder!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayLiveBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayExpirationBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayInterestedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displaySpeakers!: boolean;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly displayExtraProperties!: { id: number; key: string }[];

  @Prop({
    required: false,
    default: 0,
  })
  private readonly id!: number;

  @Prop({
    required: false,
    default: 20,
  })
  private readonly amount!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly cardType!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly urlOpenNewWindow!: boolean;

  @Prop({
    required: false,
    default: '{}',
  })
  private readonly filter!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly orderBy!: string;

  @Prop({
    required: false,
    default: true,
  })
  private readonly paginate!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  private readonly mapUrl!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly schemaCode!: string;

  @Prop({
    required: false,
    default: 'Search',
  })
  private readonly placeholder!: string;

  @Prop({
    required: false,
    default: true,
  })
  private readonly search!: boolean;

  @Prop({
    required: false,
    default: 'grid',
  })
  private readonly display!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly cardRoute!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly children!: Data[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly listConfigs!: EntityFilterList[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly toggleConfigs!: EntityFilterToggle[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly rangeConfigs!: EntityFilterRange[];

  @Prop({
    required: false,
    default: false,
  })
  private readonly displayHall!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly hideFilters!: boolean;

  private guuid = GUUID;

  private readonly FeatureKeys = FeatureKeys;

  private readonly IllustrationType = IllustrationType;

  private readonly AlertType = AlertType;

  private readonly EntityType = EntityType;

  private readonly WidgetCardMapping = WidgetCardMapping;

  private configLoading = true;

  private localOrderBy = this.orderBy;

  private calendarViewRenderKey = 0;

  private displaySelectorOptions: SegmentedElementParams[] = [];

  private selectedTab = EntitySearchDisplayMode.GRID;

  private get paginatedItems(): Data[] {
    return this.$store.getters[`${this.widgetStorePath}/paginatedItems`];
  }

  private get itemsPerPage(): number {
    return this.$store.getters[`${this.widgetStorePath}/first`];
  }

  private get page(): number {
    return this.$store.getters[`${this.widgetStorePath}/pageValue`];
  }

  private get localCount(): number {
    return this.$store.getters[`${this.widgetStorePath}/nbItems`];
  }

  private get isLoading(): boolean {
    return this.$store.getters[`${this.widgetStorePath}/isLoading`];
  }

  private get appliedFilter(): object {
    return this.$store.getters[`${this.widgetStorePath}/appliedFilter`];
  }

  private get isCalendarView(): boolean {
    return (this.display === EntitySearchDisplayMode.CALENDAR.toLowerCase()
        || this.selectedTab === EntitySearchDisplayMode.CALENDAR)
      && this.cardType === 'session';
  }

  private get isListView(): boolean {
    return ((this.display === EntitySearchDisplayMode.LIST.toLowerCase()
        || this.selectedTab === EntitySearchDisplayMode.LIST)
      && this.cardType === 'session') || this.cardType === 'crmUser';
  }

  private get usePagination(): boolean {
    return this.paginate && !this.isCalendarView;
  }

  private get isAll(): boolean {
    return this.amount === 0;
  }

  private get entityType(): string {
    switch (this.cardType) {
      case 'member':
        return EntityType.USER;
      case 'exhibitor':
      case 'company':
        return EntityType.EXHIBITOR;
      case 'session':
        return EntityType.SESSION;
      case 'product':
        return EntityType.PRODUCT;
      case 'largeProduct':
      case 'boatProduct':
        return EntityType.LARGE_PRODUCT;
      case 'deal':
        return EntityType.DEAL;
      case 'speaker':
        return EntityType.SPEAKER;
      case 'events':
        return EntityType.SUB_EDITION;
      case 'article':
        return EntityType.ARTICLE;
      case 'topic':
        return EntityType.TOPIC;
      case 'exhibitorVideo':
        return EntityType.EXHIBITOR_VIDEO;
      case 'package':
        return EntityType.SALES_PACKAGE;
      default:
        return this.cardType;
    }
  }

  created(): void {
    this.setDataConfig();
  }

  getClass(index: number, tabItemsLength: number): string {
    if (this.cardType === 'article') {
      if (this.isMobile) return 'card-container col-12';
      switch (tabItemsLength) {
        case 1:
          return 'card-container col-12';
        case 2:
          return 'card-container col-6';
        default:
          break;
      }
      if ((tabItemsLength - 1) === index && index % 3 === 1) {
        return 'card-container col-12';
      }
      if (index % 3 === 0) {
        return 'card-container col-12';
      }
      return 'card-container col-6';
    }
    if (this.isListView) {
      return this.cardType === 'crmUser'
        ? 'col-12 d-flex'
        : 'col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 d-flex';
    }
    return 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3';
  }

  private onCalendarViewSelectedDate(): void {
    this.calendarViewRenderKey += 1;
    this.$eventsBus.emit('advertise-refresh', {
      uid: this.widget.uid,
      pageNum: this.calendarViewRenderKey,
    });
  }

  private loadPaginatedData(page: number): Promise<void> {
    if (this.isReadyToDisplay) {
      return this.$store.dispatch(`${this.widgetStorePath}/loadPaginatedData`, {
        page,
        queries: this.$route.query,
      });
    }
    return Promise.resolve();
  }

  @Watch('isReadyToDisplay', { immediate: true })
  private initSelectorOptions(): void {
    if (this.isReadyToDisplay) {
      if (this.$route.query
        && 'sortBy' in this.$route.query
        && this.$route.query.sortBy) {
        this.localOrderBy = this.$route.query.sortBy as string;
      } else {
        this.localOrderBy = this.orderBy;
      }
      const result: SegmentedElementParams[] = [];
      if (this.display) {
        const displayModes = this.display.split(',');
        if (displayModes[0] === 'default') {
          this.selectedTab = EntitySearchDisplayMode.GRID;
        } else {
          this.selectedTab = displayModes[0].toUpperCase() as EntitySearchDisplayMode;
        }

        displayModes.forEach((displayMode, index) => {
          switch (displayMode) {
            case EntitySearchDisplayMode.GRID.toLowerCase():
              result.push({
                key: EntitySearchDisplayMode.GRID,
                text: `${this.$t('entity-search.buttons.grid')}`,
                size: SizeDigit.S_40,
                activeState: index === 0,
              });
              break;
            case EntitySearchDisplayMode.LIST.toLowerCase():
              result.push({
                key: EntitySearchDisplayMode.LIST,
                text: `${this.$t('entity-search.buttons.list')}`,
                size: SizeDigit.S_40,
                activeState: index === 0,
              });
              break;
            case EntitySearchDisplayMode.CALENDAR.toLowerCase():
              result.push({
                key: EntitySearchDisplayMode.CALENDAR,
                text: `${this.$t('entity-search.buttons.calendar')}`,
                size: SizeDigit.S_40,
                activeState: index === 0,
              });
              break;
            default:
              break;
          }
        });
        this.setStoredTab(result);
        const tabFound = result.find((item) => item.activeState);
        if (tabFound) {
          this.displaySelectorOptions = result;
        } else if (result.length > 0) {
          result[0].activeState = true;
          this.displaySelectorOptions = result;
          this.switchTab(result[0]);
        }
      }
      this.$nextTick(() => {
        this.configLoading = false;
        this.$eventsBus.emit('advertise-refresh', {
          uid: this.widget.uid,
          pageNum: 1,
        });
      });
      this.logStatsOnPageLoad();
      this.logSearchStats();
    }
  }

  private logSearchStats(): void {
    if (this.$route.query.search && this.$route.query.search.length > 0) {
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        '',
        StatLoggerActions.SEARCH,
        this.$route.query.search as string,
        -1,
        '',
        this.entityType,
        this.$i18n.locale,
      );
    }
  }

  private logStatsOnPageLoad(): void {
    const toggles = this.$route.query.toggles as unknown as Record<string, string> | undefined;
    const filters = this.$route.query.filters as unknown as Record<string, string[]> | undefined;
    if (filters && Object.keys(filters).length > 0) {
      Object.keys(filters)
        .forEach((key) => {
          const filter = this.listConfigs.find((cfg) => cfg.code === key);
          if (filter && filter.dataQuery && filter.dataQuery.length > 0) {
            filters[key].forEach((uid) => {
              const filterValue = (filter.dataQuery as SelectedListParams[]).find((data) => data.id === uid);
              if (filterValue) {
                this.$logger.logMatomoStats(
                  this.authUser,
                  this.community.code || '',
                  '',
                  StatLoggerActions.FILTER,
                  filterValue.title,
                  -1,
                  '',
                  this.entityType,
                  this.$i18n.locale,
                );
              }
            });
          }
        });
    }
    if (toggles && Object.keys(toggles).length > 0) {
      Object.keys(toggles)
        .forEach((key) => {
          if (toggles[key] && toggles[key] === 'true') {
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code || '',
              '',
              StatLoggerActions.FILTER,
              key,
              -1,
              '',
              this.entityType,
              this.$i18n.locale,
            );
          }
        });
    }
  }

  private setStoredTab(result: SegmentedElementParams[]): void {
    const storeView = ClientStorage.getItem('entity-search-view');
    if (storeView && result) {
      this.selectedTab = storeView as EntitySearchDisplayMode;
      result.some((item) => {
        const state = item.activeState;
        item.activeState = false;

        return state;
      });
      const tabFound = result.find((item) => item.key === storeView);
      if (tabFound) {
        tabFound.activeState = true;
      }
    }
  }

  private applyOrder(sortBy: string): void {
    this.localOrderBy = sortBy;
    this.loadPaginatedData(this.isAll ? 0 : 1);
  }

  private onPageChanged(page: number): void {
    window.scrollTo({ top: 0 });
    this.$eventsBus.emit('toggle-advertise-visibility', {
      uid: this.widget.uid,
      visibility: false,
    });
    this.loadPaginatedData(page)
      .then(() => {
        this.$eventsBus.emit('advertise-refresh', {
          uid: this.widget.uid,
          pageNum: page,
        });
      })
      .then(() => {
        this.$eventsBus.emit('toggle-advertise-visibility', {
          uid: this.widget.uid,
          visibility: true,
        });
      });
  }

  private switchTab(tab: SegmentedElementParams): void {
    if (ClientStorage.getItem('entity-search-view') === EntitySearchDisplayMode.CALENDAR
      && tab.key !== EntitySearchDisplayMode.CALENDAR) {
      this.loadPaginatedData(this.isAll ? 0 : this.page);
      const queries = this.$route.query;
      delete queries.calendar;
      this.$router
        .replace({ query: queries })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
    }
    this.displaySelectorOptions = this.displaySelectorOptions.map((v) => {
      if (tab.key === v.key) {
        this.selectedTab = tab.key as EntitySearchDisplayMode;
        ClientStorage.setItem('entity-search-view', tab.key);
        v.activeState = true;
      } else {
        v.activeState = false;
      }
      return v;
    });
  }
}
