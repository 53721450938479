import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Exhibitor from '@/models/graphql/Exhibitor';
import ExhibitorRepository from '@/repositories/ExhibitorRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { BasicTypes } from '@/utils/types/BasicTypes';

/* eslint-disable @typescript-eslint/camelcase */
interface ExhibitorState extends LoadableState {
  adminPanelExhibitor: Exhibitor;
}

@Module({ namespaced: true })
export default class ExhibitorStore extends LoadableStore<ExhibitorState> {
  adminPanelExhibitor: Exhibitor = {} as Exhibitor;

  private readonly exhibitorRepository = new ExhibitorRepository();

  get fetchAdminPanelExhibitor(): Exhibitor | null {
    if (Object.keys(this.adminPanelExhibitor).length === 0) {
      return null;
    }
    return this.adminPanelExhibitor;
  }

  protected get repository(): ExhibitorRepository {
    return this.exhibitorRepository;
  }

  @Action
  loadFeedExhibitor({
    uid,
    authUser,
  }: { uid: string; authUser: string }): Promise<void> {
    this.context.commit('load', true);
    return this.repository.get({
      operationName: 'GetFeedInitiator',
      definition: buildQueryDefinition({
        filter: {
          value: { uid },
          type: GqlEntityFilterType.EXHIBITOR_FILTER,
        },
      }),
      fragmentName: 'exhibitorFullFragment',
      authUser,
    })
      .then((response): void => {
        this.context.commit('FeedItemWrapperStore/setFeedInitiator', response, { root: true });
        this.context.commit('load', false);
      });
  }

  @Action
  fetchExhibitorWithPackagesStocks(payload: {
    exhibitorId: string;
    packageId: string;
  }): Promise<Exhibitor | undefined> {
    const params: Record<string, BasicTypes> = { packageId: payload.packageId };
    return this.repository.fetchWithPackageStock({
      operationName: 'GetExhibitorPackageStock',
      definition: buildQueryDefinition({
        filter: {
          value: { uid: payload.exhibitorId },
          type: GqlEntityFilterType.EXHIBITOR_FILTER,
        },
      }),
      fragmentName: 'exhibitorWithPackageStockFragment',
      params,
    });
  }

  @Action
  loadMapExhibitor(payload: {
    geozoneId: number; edition: string; authUser: string;
  }): Promise<Exhibitor[]> {
    return this.repository.filter({
      operationName: 'GetMapExhibitor',
      definition: buildQueryDefinition({
        filter: {
          value: {
            editionMappings_some: {
              editionExhibitor_some: {
                schemaCode: payload.edition,
                booths_some: {
                  geozone_some: {
                    id: payload.geozoneId,
                  },
                },
              },
            },
          },
          type: GqlEntityFilterType.EXHIBITOR_FILTER,
        },
      }),
      fragmentName: 'exhibitorMapFragment',
      authUser: payload.authUser,
    });
  }

  @Action
  loadAdminPanelExhibitor({
    uid,
    authUser,
  }: { uid: string; authUser: string }): void {
    this.context.commit('load', true);
    let promise: Promise<Exhibitor | undefined> = Promise.resolve(undefined);
    if (uid) {
      promise = this.repository.get({
        operationName: 'GetAdminPanelExhibitor',
        definition: buildQueryDefinition({
          filter: {
            value: { uid },
            type: GqlEntityFilterType.EXHIBITOR_FILTER,
          },
        }),
        fragmentName: 'exhibitorFullFragment',
        authUser,
      });
    }
    const key = 'ExhibitorStore/loadAdminPanelExhibitor';
    this.context.commit('PageStateManagementStore/addLoadDependencies',
      {
        key,
        promise: promise.then((response) => response),
      },
      { root: true });

    promise.then((response): void => {
      this.context.commit('setAdminPanelExhibitor', response);
      this.context.commit('load', false);
      this.context.commit('PageStateManagementStore/removeLoadDependencies', key, { root: true });
    });
  }

  @Action
  updateExhibitor({
    uid,
    attributes,
  }: { uid: string; attributes: Partial<Exhibitor> }): Promise<Exhibitor | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.EXHIBITOR_INPUT,
          value: { uid, ...attributes },
        },
      ]),
    });
  }

  @Action
  updateExhibitorByLocale({
    uid,
    attributes,
    locale,
  }: {
    uid: string;
    attributes: Partial<Exhibitor>;
    locale: string;
  }): Promise<Exhibitor | undefined> {
    return this.repository.updateByLocale({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.EXHIBITOR_INPUT,
          value: { uid, ...attributes },
        },
      ]),
    }, locale);
  }

  @Action
  removeCategoriesFromExhibitor(payload: {
    uid: string;
    categoryUid: string[];
  }): Promise<void> {
    const definitions = payload.categoryUid.map((cat, index) => ({
      operation: 'ExhibitorRemoveCategory',
      gqlDefinition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.uid,
        },
        {
          fieldName: 'category_CategoryUid',
          type: 'ID',
          value: cat,
        },
      ]),
      fragmentName: 'exhibitorBaseFragment',
      alias: `delete${index}`,
    }));
    return this.repository.removeCategoriesFromExhibitor({ definitions })
      .then(() => Promise.resolve());
  }

  @Action
  addCategoriesToExhibitor(payload: {
    uid: string;
    categoryUid: string[];
  }): Promise<void> {
    const definitions = payload.categoryUid.map((cat, index) => ({
      operation: 'ExhibitorAddCategory',
      gqlDefinition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.uid,
        },
        {
          fieldName: 'category_CategoryUid',
          type: 'ID',
          value: cat,
        },
      ]),
      fragmentName: 'exhibitorBaseFragment',
      alias: `add${index}`,
    }));
    return this.repository.addCategoriesToExhibitor({ definitions })
      .then(() => Promise.resolve());
  }

  @Action
  setExhibitorAddress(payload: { addressId: string; uid: string }): void {
    this.repository.setExhibitorAddress({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.uid,
        },
        {
          fieldName: 'addresse_AddressUid',
          type: GqlEntityInputType.ID,
          value: payload.addressId,
        },
      ]),
    });
  }

  @Action
  setExhibitorCountry(payload: {
    countryUid: string;
    exhibitorUid: string;
  }): Promise<Exhibitor | undefined> {
    return this.repository.setExhibitorCountry({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.exhibitorUid,
        },
        {
          fieldName: 'country_CountryUid',
          type: GqlEntityInputType.ID,
          value: payload.countryUid,
        },
      ]),
    });
  }

  @Mutation
  setAdminPanelExhibitorName(exhibitor: Exhibitor): void {
    if (this.adminPanelExhibitor) {
      this.adminPanelExhibitor.name = exhibitor.name;
    }
  }

  @Mutation
  setAdminPanelExhibitor(adminPanelExhibitor: Exhibitor): void {
    this.adminPanelExhibitor = Exhibitor.hydrate(adminPanelExhibitor);
  }

  @Mutation
  setAdminPanelExhibitorDescription(exhibitor: Exhibitor): void {
    if (this.adminPanelExhibitor) {
      this.adminPanelExhibitor.description = exhibitor.description;
    }
  }

  @Mutation
  setAdminPanelExhibitorVideoPresentationS3Url(exhibitor: Exhibitor): void {
    if (this.adminPanelExhibitor) {
      this.adminPanelExhibitor.videoPresentationS3Url = exhibitor.videoPresentationS3Url;
    }
  }
}
