import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SESSION_PROGRAM_FRAGMENT = `
  fragment sessionProgramFragment on Session {
    uid
    name
    startTime
    startTimestamp
    endTime
    endTimestamp
    description
    featured
    exhibitor {
      uid
      name
      logoFileResource {
        ...fileResourceBaseFragment
      }
    }
    categoriesInContext(context:"session_flag") {
      uid
      name
    }
    categories"%filter%" {
      uid
      schemaCode
      domain
      name
      description
    }
    speakerRoles(orderBy: displaySequence_asc) {
      uid
      role
      displaySequence
      speaker {
        uid
        firstName
        lastName
        name
        bio
        prefix
        suffix
        user {
          uid
        }
      }
    }
    sponsorRoles {
      uid
      role
      sponsor {
        uid
        name
        description
        url
        type
        subEdition
        logoFileResource {
          ...fileResourceBaseFragment
        }
        exhibitor {
          uid
          name
        }
      }
    }
    geozonesOfEdition: _geozonesOfEdition {
      name
      exhibitHall {
        name
      }
    }
    _isInAgenda(myUid: "%authUser%")
    _haveConflicts(myUid: "%authUser%")
    _isRecommendedForMe
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
  `;
