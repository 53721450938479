import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUser from '@/models/graphql/CommunityUser';
import CommunityUserRepository from '@/repositories/CommunityUserRepository';
import { CommunityUsersFilter } from '@/graphql/_Filters/CommunityUsersFilter';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import { BasicTypes } from '@/utils/types/BasicTypes';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import RootState from '@/store/states/RootState';
import ToastActionType from '@/utils/enums/ToastActionType';
import FeedState from '@/models/graphql/FeedState';

interface CommunityUserState extends LoadableState {
  communityUsers: CommunityUser[];
  communityUserState: CommunityUser;
}

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class CommunityUserStore extends LoadableStore<CommunityUserState> {
  communityUsers: CommunityUser[] = [];

  communityUserState: CommunityUser = {} as CommunityUser;

  private page = 0;

  private itemsPerPage = 10;

  private loadingState = false;

  private readonly communityUserRepository = new CommunityUserRepository();

  get getLoadingState(): boolean {
    return this.loadingState;
  }

  get communityUser(): CommunityUser {
    return this.communityUserState;
  }

  get fetchBlockedCommunityUsers(): CommunityUser[] {
    if (this.context.rootState.authUser) {
      return this.context.rootState.authUser.blockedUsers;
    }
    return [];
  }

  get fetchBlockedCommunityUserUids(): string[] {
    if (this.context.rootState.authUser) {
      return this.context.rootState.authUser.blockedUsers.map((cu) => cu.uid);
    }
    return [];
  }

  get fetchCommunityUsers(): CommunityUser[] {
    return this.communityUsers;
  }

  private get repository(): CommunityUserRepository {
    return this.communityUserRepository;
  }

  @Action
  get(payload: {
    filter: CommunityUsersFilter;
    fragmentName?: string;
  }): Promise<void | CommunityUser | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
      fragmentName: payload.fragmentName || 'communityUserFullFragment',
      authUser: this.context.rootGetters.authUser.uid,
    })
      .then((response) => {
        this.context.commit('setElement', response);
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  getForCommunityUserAuth(payload: {
    filter: CommunityUsersFilter;
  }): Promise<void | CommunityUser | undefined> {
    this.context.commit('load', true);
    return this.repository.authUser({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    })
      .then((response) => {
        this.context.commit('setElement', response);
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  count(payload: { filter: CommunityUsersFilter }): Promise<void | number | undefined> {
    this.context.commit('load', true);
    return this.repository.count({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    })
      .then((response) => {
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  loadEmailForAuthUser(): Promise<void> {
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: { uid: this.context.rootState.authUser?.uid },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
      fragmentName: 'communityUserBaseFragment',
      operationName: 'LoadEmailForAuthUser',
    })
      .then((response) => {
        if (response && response.email && this.context.rootState.authUser) {
          this.context.rootState.authUser.email = response.email;
        }
        return Promise.resolve();
      });
  }

  @Action
  getWithAllCompanyRole(uid: string): Promise<void | CommunityUser | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: { uid },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
      fragmentName: 'communityUserCompanyUserRoleFragment',
    })
      .then((response) => {
        this.context.commit('setElement', response);
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  filter(payload: { filter: CommunityUsersFilter }): void {
    this.context.commit('load', true);
    if (this.context.rootState.authUser && this.context.rootState.authUser.uid) {
      // eslint-disable-next-line no-underscore-dangle
      payload.filter._isNotBlocked = this.context.rootState.authUser.uid;
    }
    this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    })
      .then((response) => {
        this.context.commit('setElements', response);
        this.context.commit('load', false);
      });
  }

  @Action
  loadBlockedCommunityUsers(): void {
    if (this.context.rootState.authUser && this.context.rootState.authUser.uid) {
      this.repository.filter({
        operationName: 'LoadBlockedCommunityUsers',
        definition: buildQueryDefinition({
          filter: {
            value: {
              blockedByUsers: {
                uid: this.context.rootState.authUser.uid,
              },
            },
            type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
          },
        }),
      })
        .then((response) => {
          this.context.commit('setBlockedUsers', {
            users: response,
            rootState: this.context.rootState,
          });
        });
    }
  }

  @Action({ rawError: true })
  updateUserProfile(payload: Partial<CommunityUser>): Promise<CommunityUser | undefined> {
    if (this.context.rootState.authUser) {
      this.context.commit('load', true);
      const newUser: CommunityUser = {} as CommunityUser;
      newUser.uid = this.context.rootState.authUser.uid;
      if (payload.firstName != null) {
        newUser.firstName = payload.firstName;
      }
      if (payload.lastName != null) {
        newUser.lastName = payload.lastName;
      }
      if (payload.jobTitle) {
        newUser.jobTitle = payload.jobTitle;
      }
      if (payload.employerName) {
        newUser.employerName = payload.employerName;
      }
      if (payload.bio) {
        newUser.bio = payload.bio;
      }
      if (payload.goals) {
        newUser.goals = payload.goals;
      }
      if (payload.profilePrivacyMode) {
        newUser.profilePrivacyMode = payload.profilePrivacyMode;
      }
      if (payload.email) {
        newUser.email = payload.email;
      }
      if (payload.prefix !== undefined) {
        newUser.prefix = payload.prefix;
      }
      if (payload.suffix !== undefined) {
        newUser.suffix = payload.suffix;
      }
      newUser.videoPresentationS3Url = payload.videoPresentationS3Url;
      return this.repository.update({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.COMMUNITY_USER_INPUT,
            value: newUser,
          },
        ]),
      })
        .then((response) => {
          this.context.commit('load', false);
          return response;
        });
    }
    return Promise.resolve(undefined);
  }

  @Action
  updatePassword(payload: { password: string; uid?: string }): Promise<CommunityUser | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_INPUT,
        value: {
          uid: payload.uid || this.context.rootState.authUser?.uid as string,
          passwordHash: payload.password,
        },
      },
      ]),
    })
      .then((response) => response);
  }

  @Action
  updatePasswordWithTempToken(payload: { newPassword: string; tempToken: string; userUid: string }): Promise<CommunityUser | undefined> {
    return this.repository.updatePasswordWithTempToken({
      definition: buildMutationDefinition([
        {
          fieldName: 'tempToken',
          type: GqlEntityInputType.STRING,
          value: payload.tempToken,
        },
        {
          fieldName: 'userUid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: payload.userUid,
        },
        {
          fieldName: 'newPassword',
          type: GqlEntityInputType.STRING,
          value: payload.newPassword,
        },
      ]),
    })
      .then((response) => response);
  }

  @Action
  getFeedStateForAuthUser(): Promise<void> {
    if (this.context.rootState.authUser && this.context.rootState.authUser.uid) {
      return this.repository.get({
        operationName: 'GetAuthUserFeedStates',
        definition: buildQueryDefinition({
          filter: {
            value: { uid: this.context.rootState.authUser.uid },
            type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
          },
        }),
        fragmentName: 'communityUserFeedStateFragment',
      })
        .then((response) => {
          this.context.commit('setFeedState', {
            authUser: this.context.rootState.authUser,
            response,
          });
          return Promise.resolve();
        });
    }
    return Promise.resolve();
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  setFeedState({
    authUser,
    response,
  }: { authUser: CommunityUser; response: CommunityUser }): void {
    Object.assign(authUser, response);
  }

  @Action
  updateLastViewedFeedsGlobal(lastViewed: number): void {
    this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_INPUT,
        value: {
          uid: this.context.rootState.authUser?.uid as string,
          lastViewedFeeds: Math.round(lastViewed),
        },
      },
      ]),
    })
      .then(() => this.context.commit(
        'setLastViewedFeeds',
        {
          authUser: this.context.rootState.authUser,
          lastViewed,
        },
      ));
  }

  @Action
  loadPaginatedUsers(payload: Record<string, BasicTypes>): Promise<number | null> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter as object | null | undefined,
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
        first: this.itemsPerPage,
        offset: this.itemsPerPage * this.page,
      }),
      operationName: 'PaginateCommunityUsers',
      fragmentName: 'communityUserPaginateFragment',
      authUser: payload.authUser as string,
    })
      .then((response) => {
        if (this.page === 0) {
          if (response.length !== 0) {
            this.context.commit('setPage', 1);
          }
        } else {
          this.context.commit('setPage', this.page + 1);
        }
        this.context.commit('addElements', response);
        this.context.commit('load', false);
        return response.length;
      })
      .catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  resetPage(): void {
    this.context.commit('setPage', 0);
    this.context.commit('setElements', []);
  }

  @Action
  createAccount(model: CommunityUser): Promise<CommunityUser | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_INPUT,
        value: model,
      }]),
    });
  }

  @Action
  getBanStatus(payload: { user: CommunityUser }): Promise<CommunityUser[] | undefined> {
    this.context.commit('setLoadingState', true);
    return this.repository.filter({
      fragmentName: 'communityUserForBanChatStatusFragment',
      operationName: 'GetCommunityUserBanStatus',
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
          value: { uid: payload.user.uid },
        },
      }),
    })
      .then((result) => {
        this.context.commit('setLoadingState', false);
        return result;
      });
  }

  @Mutation
  setPage(page: number): void {
    this.page = page;
  }

  @Mutation
  addElements(communityUsers: CommunityUser[]): void {
    this.communityUsers = [...this.communityUsers, ...communityUsers];
  }

  @Mutation
  setElements(users: CommunityUser[]): void {
    this.communityUsers = users;
  }

  @Mutation
  setElement(user: CommunityUser): void {
    this.communityUserState = user;
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  setBlockedUsers({
    users,
    rootState,
  }: { users: CommunityUser[]; rootState: RootState }): void {
    if (rootState.authUser) {
      if (rootState.authUser.blockedUsers) {
        users.forEach((u) => {
          if (rootState.authUser
            && rootState.authUser.blockedUsers.findIndex((ub) => ub.uid === u.uid) === -1) {
            rootState.authUser.blockedUsers.push(u);
          }
        });
      } else {
        rootState.authUser.blockedUsers = users;
      }
    }
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  setUnBlockedUsers({
    users,
    rootState,
  }: { users: CommunityUser[]; rootState: RootState }): void {
    if (rootState.authUser && rootState.authUser.blockedUsers) {
      rootState.authUser.blockedUsers = rootState.authUser.blockedUsers
        .filter((item: CommunityUser) => users.filter((user: CommunityUser) => user.uid !== item.uid).length > 0);
    }
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  setLastViewedFeeds(payload: { authUser: CommunityUser; lastViewed: number }): void {
    if (payload.authUser) {
      payload.authUser.lastViewedFeeds = payload.lastViewed;
    }
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  setLastViewFeedsEntity(payload: { authUser: CommunityUser; feedState: FeedState }): void {
    if (payload.authUser) {
      const currentFeedState = payload.authUser.feedStates?.find((e) => e.uid === payload.feedState.uid);
      if (!payload.authUser.feedStates) return;
      if (currentFeedState) {
        currentFeedState.lastViewedFeed = payload.feedState.lastViewedFeed;
      } else {
        // eslint-disable-next-line no-unused-expressions
        payload.authUser.feedStates?.push(payload.feedState);
      }
    }
  }

  @Action
  setFeedStates(payload: { authUser: CommunityUser; feedState: FeedState }): void {
    this.context.commit('setLastViewFeedsEntity', payload);
  }

  @Mutation
  setLoadingState(state: boolean): void {
    this.loadingState = state;
  }

  @Action
  changePassword(payload: {
    uid: string;
    password: string;
    newPassword: string;
  }): Promise<boolean> {
    this.context.commit('load', true);
    return this.repository.updatePassword({
      definition: buildMutationDefinition([
        {
          fieldName: 'userUid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: payload.uid,
        },
        {
          fieldName: 'password',
          type: GqlEntityInputType.STRING,
          value: payload.password,
        },
        {
          fieldName: 'newPassword',
          type: GqlEntityInputType.STRING,
          value: payload.newPassword,
        },
      ]),
    })
      .then((response: boolean) => {
        this.context.commit('load', false);
        if (response) {
          this.context.dispatch('ToastStore/addNewAction', {
            type: ToastActionType.SETTINGS_CHANGE_PASSWORD,
            delay: 3500,
          }, { root: true });
        }
        return response;
      });
  }

  @Action
  changeEmail(payload: { newEmail: string; password: string }): Promise<boolean> {
    this.context.commit('load', true);
    return this.repository.updateEmail({
      definition: buildMutationDefinition([
        {
          fieldName: 'newEmail',
          type: GqlEntityInputType.STRING,
          value: payload.newEmail,
        },
        {
          fieldName: 'password',
          type: GqlEntityInputType.STRING,
          value: payload.password,
        },
      ]),
    })
      .then((response: boolean) => {
        if (response) {
          this.context.dispatch('ToastStore/addNewAction', {
            type: ToastActionType.SETTINGS_CHANGE_EMAIL,
            delay: 3500,
          }, { root: true });
        }
        return response;
      }).catch(() => false);
  }

  @Action
  changeUserEmail(payload: { uploadToken: string }): Promise<CommunityUser | undefined> {
    this.context.commit('load', true);
    return this.repository.confirmUserEmail({
      definition: buildMutationDefinition([
        {
          fieldName: 'token',
          value: payload.uploadToken,
          type: GqlEntityInputType.STRING,
        },
        {
          fieldName: 'generateAuthToken',
          value: true,
          type: GqlEntityInputType.BOOLEAN,
        },
      ]),
    })
      .then((response: CommunityUser | undefined) => response);
  }
}
