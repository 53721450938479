import { Action, Module } from 'vuex-module-decorators';
import FileResource from '@/models/graphql/FileResource';
import FileResourceRepository from '@/repositories/FileResourceRepository';
import LoadableStore from '@/store/LoadableStore';
import FileResourceParams from '@/utils/types/FileResourceParams';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class FileResourceStore extends LoadableStore<FileResource> {
  private readonly fileResourceRepository = new FileResourceRepository();

  protected get repository(): FileResourceRepository {
    return this.fileResourceRepository;
  }

  @Action
  deleteFileResource(uid: string): Promise<FileResource | undefined> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    });
  }

  @Action
  async createFileResourceForHandout(
    payload: {
      entityId: string;
      fileResourceData: FileResourceParams;
    },
  ): Promise<FileResource | undefined> {
    this.context.commit('load', { namespace: 'FileResource', loading: true });
    try {
      const response = await this.repository.createFileResourceForHandout({
        definition: buildMutationDefinition([{
          fieldName: 'handoutHandout_HandoutUid',
          type: 'ID',
          value: payload.entityId,
        }, {
          fieldName: 'entity',
          type: GqlEntityInputType.FILE_RESOURCE_INPUT,
          value: payload.fileResourceData,
        }]),
      });
      this.context.commit('load', { namespace: 'FileResource', loading: false });
      return Promise.resolve(response);
    } catch (e) {
      return undefined;
    }
  }

  @Action
  createProfileFileResourceForCommunityUser(uploadToken: string): Promise<FileResource | undefined> {
    if (this.context.rootState.authUser) {
      this.context.commit('load', { namespace: 'FileResource', loading: true });
      return this.repository.createProfileFileResourceForCommunityUser({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.FILE_RESOURCE_INPUT,
            value: {
              uploadToken,
              resourceType: 'PROFILE_IMAGE',
              fileType: 'PROFILE_IMAGE',
            },
          },
          {
            fieldName: 'userPicture_CommunityUserUid',
            type: 'ID',
            value: this.context.rootState.authUser.uid,
          }]),
      }).then((response): Promise<FileResource | undefined> => {
        this.context.commit('load', { namespace: 'FileResource', loading: false });
        return Promise.resolve(response);
      }).catch(() => undefined);
    }
    return Promise.resolve(undefined);
  }

  @Action
  createBannerFileResourceForCommunityUser(uploadToken: string): Promise<FileResource | undefined> {
    if (this.context.rootState.authUser) {
      this.context.commit('load', { namespace: 'FileResource', loading: true });
      return this.repository.createBannerFileResourceForCommunityUser({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.FILE_RESOURCE_INPUT,
            value: {
              uploadToken,
              resourceType: 'BANNER_IMAGE',
              fileType: 'BANNER_IMAGE',
            },
          },
          {
            fieldName: 'userBanner_CommunityUserUid',
            type: 'ID',
            value: this.context.rootState.authUser.uid,
          }]),
      }).then((response): Promise<FileResource | undefined> => {
        this.context.commit('load', { namespace: 'FileResource', loading: false });
        return Promise.resolve(response);
      });
    }
    return Promise.resolve(undefined);
  }

  @Action
  createLogoFileResourceForExhibitor(data: {
    uploadToken: string;
    companyUid: string;
  }): Promise<FileResource | undefined> {
    this.context.commit('load', { namespace: 'FileResource', loading: true });
    return this.repository.createLogoFileResourceForExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FILE_RESOURCE_INPUT,
          value: {
            uploadToken: data.uploadToken,
            resourceType: 'LOGO_IMAGE',
            fileType: 'LOGO_IMAGE',
          },
        },
        {
          fieldName: 'exhibitorLogo_ExhibitorUid',
          type: 'ID',
          value: data.companyUid,
        }]),
    }).then((response) => {
      this.context.commit('load', { namespace: 'FileResource', loading: false });
      return Promise.resolve(response);
    });
  }

  @Action
  createBannerFileResourceForExhibitor(data: {
    uploadToken: string;
    companyUid: string;
  }): Promise<FileResource | undefined> {
    this.context.commit('load', { namespace: 'FileResource', loading: true });
    return this.repository.createBannerFileResourceForExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FILE_RESOURCE_INPUT,
          value: {
            uploadToken: data.uploadToken,
            resourceType: 'BANNER_IMAGE',
            fileType: 'BANNER_IMAGE',
          },
        },
        {
          fieldName: 'exhibitorBanner_ExhibitorUid',
          type: 'ID',
          value: data.companyUid,
        }]),
    }).then((response) => {
      this.context.commit('load', { namespace: 'FileResource', loading: false });
      return Promise.resolve(response);
    });
  }

  @Action
  createPhotoFileResourceForSpeaker(data: {
    uploadToken: string;
    speakerUid: string;
  }): Promise<FileResource | undefined> {
    this.context.commit('load', { namespace: 'FileResource', loading: true });
    return this.repository.createPhotoFileResourceForSpeaker({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FILE_RESOURCE_INPUT,
          value: {
            uploadToken: data.uploadToken,
            resourceType: 'SPEAKER_IMAGE',
            fileType: 'SPEAKER_IMAGE',
          },
        },
        {
          fieldName: 'speakerPhoto_SpeakerUid',
          type: 'ID',
          value: data.speakerUid,
        }]),
    }).then((response) => {
      this.context.commit('load', { namespace: 'FileResource', loading: false });
      return Promise.resolve(response);
    });
  }

  @Action
  createBannerFileResourceForSession(data: {
    uid: string;
    uploadToken: string;
    fileName: string;
    fileType: string;
  }): Promise<FileResource | undefined> {
    this.context.commit('load', { namespace: 'FileResource', loading: true });
    return this.repository.createBannerFileResourceForSession({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FILE_RESOURCE_INPUT,
          value: {
            uploadToken: data.uploadToken,
            fileName: data.fileName,
            fileType: data.fileType,
            resourceType: 'SESSION_BANNER_IMAGE',
          },
        },
        {
          fieldName: 'sessionBanner_SessionUid',
          type: 'ID',
          value: data.uid,
        }]),
    }).then((response) => {
      this.context.commit('load', { namespace: 'FileResource', loading: false });
      return Promise.resolve(response);
    });
  }

  @Action
  createDisplayLogoFileResourceForDeal(data: {
    uid: string;
    uploadToken: string;
    fileName: string;
    fileType: string;
  }): Promise<FileResource | undefined> {
    this.context.commit('load', { namespace: 'FileResource', loading: true });
    return this.repository.createDisplayLogoFileResourceForDeal({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FILE_RESOURCE_INPUT,
          value: {
            uploadToken: data.uploadToken,
            fileName: data.fileName,
            fileType: data.fileType,
            resourceType: 'DEAL_LOGO',
          },
        },
        {
          fieldName: 'productDisplay_DealUid',
          type: 'ID',
          value: data.uid,
        }]),
    }).then((response) => {
      this.context.commit('load', { namespace: 'FileResource', loading: false });
      return Promise.resolve(response);
    });
  }

  @Action
  createThumbnailFileResourceForExhibitorVideo(data: {
    uid: string;
    uploadToken: string;
    fileName: string;
    fileType: string;
  }): Promise<FileResource | undefined> {
    this.context.commit('load', { namespace: 'FileResource', loading: true });
    return this.repository.createThumbnailFileResourceForExhibitorVideo({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FILE_RESOURCE_INPUT,
          value: {
            uploadToken: data.uploadToken,
            fileName: data.fileName,
            fileType: data.fileType,
            resourceType: 'THUMBNAIL',
          },
        },
        {
          fieldName: 'exhibitorVideoThumbnailFileResource_ExhibitorVideoUid',
          type: 'ID',
          value: data.uid,
        }]),
    }).then((response) => {
      this.context.commit('load', { namespace: 'FileResource', loading: false });
      return Promise.resolve(response);
    });
  }
}
