var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay && _vm.user)?_c('div',{class:['exhibitor-portal-user-header container p-0', _vm.classList]},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"content-wrapper d-flex flex-column align-items-center justify-content-center"},[_c('div',{class:['banner rounded-lg position-relative', {
            'desktop': _vm.isDesktop,
            'tablet': _vm.isTablet,
            'mobile': _vm.isMobile
          }],style:({ backgroundImage: _vm.bannerSrc })},[_c('recommendation-badge-component',{staticClass:"nb-followers position-absolute",attrs:{"icon":_vm.isMobile ? {
              'iconDef': 'fa-regular fa-user-group',
              'iconClass': 'icon-12 text-neutral-n-8-dark-ink'
            } : undefined,"label":_vm.followers,"size":_vm.isMobile ? 'lg' : 'xlg',"variant":"light-white"}}),(_vm.videoPresentationUrl)?_c('div',{class:[
              'bg-transparent d-flex align-items-center justify-content-center play-button position-absolute z-index-1' ]},[(!_vm.isMobile)?_c('button-component',{staticStyle:{"opacity":"0.8"},attrs:{"no-border":true,"rounded":true,"text":("" + (_vm.$t('generic-page-header.button.play'))),"size":"md","variant":"dark-gray"},on:{"on-click":function($event){_vm.showVideoPlayer = true}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-play","icon-classes":"icon-16"}})]},proxy:true}],null,false,2007394395)}):_c('button-icon-component',{staticStyle:{"opacity":"0.8"},attrs:{"rounded":true,"size":"sm","variant":"admin"},on:{"on-click":function($event){_vm.showVideoPlayer = true}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-play","icon-classes":"icon-16"}})]},proxy:true}],null,false,706225417)})],1):_vm._e(),(!_vm.isDesktop)?_c('div',{staticClass:"bg-transparent d-flex align-items-center justify-content-center logo position-absolute w-100"},[_c('avatar-solo-widget',{attrs:{"firstname":_vm.user.firstName || '',"lastname":_vm.user.lastName || '',"recommended-for-you":_vm.user._isRecommendedForMe,"size":_vm.isMobile ? '96' : '136',"src":_vm.logoSrc,"with-borders":true,"default-icon":"far fa-circle-user"}})],1):_vm._e()],1),(_vm.connectionsMessage && _vm.mutual)?_c('div',{class:['infos d-flex justify-content-center align-items-center', {
            'desktop': _vm.isDesktop,
            'tablet': _vm.isTablet,
            'mobile': _vm.isMobile
          }]},[_c('b-link',{staticClass:"caps caps-2 m-0 text-blue-b-3-primary-blue",attrs:{"href":_vm.connectionPath,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.connectionsMessage)+" ")]),(_vm.mutual)?_c('p',{staticClass:"caps caps-2 m-0 text-neutral-n-6-label"},[_vm._v("  ·  ")]):_vm._e(),_c('b-link',{staticClass:"caps caps-2 m-0 text-blue-b-3-primary-blue",attrs:{"href":_vm.mutualPath,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.mutual)+" ")])],1):_vm._e(),(_vm.showVideoPlayer && _vm.videoPresentationUrl)?[_c('video-component',{staticClass:"popup-video",attrs:{"url":_vm.videoPresentationUrl,"reference":"playerUser"}}),_c('div',{staticClass:"backdrop",on:{"click":function($event){_vm.showVideoPlayer = false}}})]:_vm._e()],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }