












































































import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import Decoration from '@/utils/enums/Decoration';
import Size from '@/utils/enums/Size';
import Variant from '@/utils/enums/Variant';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { runMathJax } from '@/utils/helpers/LatexHelper';

@Component({
  methods: { runMathJax },
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
  },
  inheritAttrs: false,
})
export default class PillWidget extends Vue {
  @Inject({
    from: 'context',
    default: '',
  })
  private context!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly label!: string;

  @Prop({
    required: false,
    default: 'white',
  })
  private readonly variant!: Variant | string;

  @Prop({
    required: false,
    default: 'md',
  })
  private readonly size!: Size;

  @Prop({
    required: false,
    default: '',
  })
  private readonly avatarSize!: Size;

  @Prop({
    required: false,
    default: 'none',
  })
  private readonly decoration!: Decoration;

  @Prop({
    required: false,
    default: 0,
  })
  private readonly number!: number;

  @Prop({
    required: false,
    default: '',
  })
  private readonly img!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly showTooltip!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly withBorders!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly centered!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly clickable!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly textWrap!: boolean;

  @Prop({
    required: false,
    default: null,
    validator: (obj) => 'iconDef' in obj && 'iconClass' in obj,
  })
  private readonly icon!: Record<string, string>;

  @Prop({
    required: false,
    default: false,
  })
  private readonly clearable!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly hover!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly stopPropagation!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly disabled!: boolean;

  private sizeEnum = Size;

  private DecorationEnum = Decoration;

  private variantEnum = Variant;

  private get cssClass(): string {
    if (Decoration.IMAGE === this.decoration || Decoration.ICON === this.decoration) {
      if (this.clearable) {
        return 'clearable-icon-image-padding';
      }
      if (this.label) {
        return 'icon-image-padding-label';
      }
      return 'icon-image-padding';
    }

    if (this.clearable) {
      return 'clearable-padding';
    }

    if (Decoration.NUMBER === this.decoration) {
      return 'number-padding';
    }
    return '';
  }

  private get isOnboarding(): boolean {
    return this.context === 'onboarding';
  }

  private get contextVariant(): string {
    if (this.isOnboarding) {
      if (this.variant === this.variantEnum.LIGHTBLUE) {
        return this.variantEnum.BLUE;
      }
      if (this.variant === this.variantEnum.WHITE) {
        return this.variantEnum.LIGHT_GRAY;
      }
    }
    return this.variant;
  }

  private onClick(e: Event): void {
    if (this.stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
      if (this.hover || this.clickable) {
        this.$emit('on-click');
      }
    } else if (this.hover || this.clickable) {
      this.$emit('on-click');
    }
  }
}
