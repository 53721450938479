

























































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import FileType from '@/utils/enums/FileType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import InputText from '@/components/InputText.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import VideoComponent from '@/components/VideoComponent.vue';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@/utils/helpers/FileResourceHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import EntityType from '@/utils/enums/EntityType';
import Exhibitor from '@/models/graphql/Exhibitor';
import { Getter, namespace } from 'vuex-class';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import UploadFileHandler from '@/utils/UploadFileHandler';
import CookieService from '@/services/CookieService';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import CommunityUser from '@/models/graphql/CommunityUser';
import ExhibitorVideo from '@/models/graphql/ExhibitorVideo';
import Community from '@/models/graphql/Community';

const exhibitorStore = namespace('ExhibitorStore');
const communityUserStore = namespace('CommunityUserStore');
const exhibitorVideoStore = namespace('ExhibitorVideoStore');
const fileResourceStore = namespace('FileResourceStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    VideoComponent,
    InputText,
    FontAwesomeComponent,
    StandardModal,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class VideoSelectorModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  protected readonly authUser!: CommunityUser;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  protected adminPanelExhibitor!: Exhibitor;

  @Getter
  private community!: Community;

  @exhibitorStore.Action
  private updateExhibitor!: (payload: {
    uid: string;
    attributes: Partial<Exhibitor>;
  }) => Promise<Exhibitor | undefined>;

  @exhibitorStore.Mutation
  private setAdminPanelExhibitorVideoPresentationS3Url!: (exhibitor: Exhibitor) => void;

  @communityUserStore.Action
  private updateUserProfile!: (payload: Partial<CommunityUser>) => void;

  @exhibitorVideoStore.Action
  private create!: (payload: {
    exhibitorUid: string;
    entity: Partial<ExhibitorVideo>;
  }) => Promise<ExhibitorVideo | undefined>;

  @exhibitorVideoStore.Action
  private update!: (exhibitorVideo: Partial<ExhibitorVideo>) => Promise<ExhibitorVideo | undefined>;

  @fileResourceStore.Action
  private createThumbnailFileResourceForExhibitorVideo!: (data: {
    uid: string;
    uploadToken: string;
    fileName: string;
    fileType: string;
  }) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @Prop({
    required: false,
    default: '',
  })
  private videoUid!: string;

  @Prop({
    required: false,
    default: '',
  })
  private exhibitorUid!: string;

  @Prop({
    required: false,
    default: '',
  })
  private defaultUrl!: string;

  @Prop({
    required: false,
    default: false,
  })
  private isExhibitorVideo!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  private defaultName!: string;

  @Prop({
    required: false,
    default: null,
  })
  private defaultThumbnail!: FileResource | null;

  @Prop({ default: EntityType.COMPANY })
  private entityType!: string;

  private selected = 'url';

  private url = this.defaultUrl;

  private name = this.defaultName;

  private fileType = `${FileType.MP4},${FileType.WEBM}`;

  private fileTypeThumbnail = `${FileType.PNG}, ${FileType.JPEG}, ${FileType.WEBP}`;

  private file: File | undefined | null = null;

  private thumbnailFile: File | undefined | null = null;

  private videoPreviewHeight = '0';

  private localThumbnailPath = '';

  private sherpaBaseVideoUrl: string = process.env.VUE_APP_S3_VIDEO_AMAZONAWS ?? '';

  private formatNotSupported = false;

  private isSaving = false;

  private thumbnailDeleted = false;

  private oldFile: File | undefined | null = null;

  private oldThumbnail: File | undefined | null = null;

  private canValidateName = false;

  private canValidateUrl = false;

  private get invalidUrl(): boolean {
    return !RouteHelper.isUrlValid(this.url);
  }

  private get thumbnailPath(): string {
    if (this.localThumbnailPath) {
      return this.localThumbnailPath;
    }

    if (this.defaultThumbnail?.path && !this.thumbnailDeleted) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.defaultThumbnail), 'w96');
    }
    return '';
  }

  private get isSaveButtonDisabled(): boolean {
    if (this.selected === 'url') {
      if ((this.invalidUrl || !this.url) || this.isSaving) {
        return true;
      }
      if (this.isExhibitorVideo) {
        return ((this.name === '' || this.url === '')
            || (this.name === this.defaultName && this.url === this.defaultUrl))
          && !this.thumbnailChanged;
      }
      return false;
    }
    if (this.isExhibitorVideo) {
      return (!this.file || this.name === '')
        || (this.file === this.oldFile && this.name === this.defaultName && !this.thumbnailChanged);
    }
    return !this.file;
  }

  private get thumbnailChanged(): boolean {
    return this.thumbnailDeleted || this.localThumbnailPath.length !== 0;
  }

  mounted(): void {
    this.updateName();
  }

  @Watch('defaultName')
  updateName(): void {
    this.name = this.defaultName;
    this.canValidateName = this.defaultName !== '';
  }

  @Watch('defaultUrl')
  public initFirstLoad(): void {
    this.thumbnailDeleted = false;
    this.thumbnailFile = null;
    this.file = null;
    this.localThumbnailPath = '';
    this.url = this.defaultUrl;
    this.canValidateUrl = this.defaultUrl !== '';
    if (this.defaultUrl && this.defaultUrl.startsWith(this.sherpaBaseVideoUrl)) {
      this.selected = 'upload';
      this.file = null;
      this.createFile();
    } else {
      this.file = null;
      this.selected = 'url';
    }
  }

  createFile(): void {
    const request = new XMLHttpRequest();
    request.open('GET', this.defaultUrl, true);
    request.responseType = 'blob';
    request.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = async (e) => {
        if (e && e.target) {
          const arr = ((e.target.result as string) || '').split(',');
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const mime = arr[0].match(/:(.*?);/)![1];

          const response = await fetch(e.target.result as string);
          const blob = await response.blob();
          const finalFile = new File([blob], 'video', { type: mime });
          this.file = finalFile;
          this.oldFile = finalFile;
        }
      };
    };
    request.send();
  }

  protected showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private onNameValueChange(): void {
    this.canValidateName = true;
  }

  private onUrlValueChange(): void {
    this.canValidateUrl = true;
  }

  @Watch('selected')
  private onSelectionChange(): void {
    if (this.selected === 'url') {
      this.formatNotSupported = false;
    }
  }

  @Watch('file')
  private onFileChange(): void {
    if (this.file) {
      this.formatNotSupported = false;
    }
  }

  private onDeleteVideoClick(): void {
    this.file = null;
    this.url = '';
  }

  private onUploadVideoClick(): void {
    // eslint-disable-next-line no-unused-expressions
    (this.$el.querySelector(`#video-uploader-${this.modalId}`) as HTMLElement)?.click();
  }

  private onDrop(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer !== null ? e.dataTransfer.files[0] : null;
    if (file && (file?.type === 'video/webm'
      || file?.type === 'video/mp4')) {
      this.file = file;
      this.formatNotSupported = false;
    } else {
      this.$data.src = '';
      // eslint-disable-next-line no-alert
      this.formatNotSupported = true;
    }
  }

  private allowDrop = (e: DragEvent): void => {
    e.stopPropagation();
    e.preventDefault();
  };

  private allowDropEnd = (e: DragEvent): void => {
    e.stopPropagation();
    e.preventDefault();
  };

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.isSaving = false;
    this.$emit('on-cancel');
  }

  private onConfirm(): void {
    this.isSaving = true;
    if (this.selected === 'upload' && this.file) {
      if (this.file !== this.oldFile) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (): void => {
          UploadFileHandler.getS3Info({
            file: this.file as File,
            token: CookieService.getCookie(AUTH_TOKEN) || '',
            base64Path: reader.result as string,
          })
            .then((resp) => {
              if (resp.src) {
                this.setVideo(resp.src);
              }
            });
        };
      } else {
        this.setVideo(this.url);
      }
    }
    if (this.selected === 'url' && (this.isUrlValid(this.url) || this.url === '')) {
      this.setVideo(this.url);
    }
  }

  private onShow(): void {
    this.$emit('on-show-modal');
    this.canValidateName = false;
    this.canValidateUrl = false;
  }

  private onFileUpload(): void {
    if (this.thumbnailFile) {
      const reader = new FileReader();
      reader.readAsDataURL(this.thumbnailFile);
      reader.onload = (): void => {
        this.localThumbnailPath = reader.result as string;
      };
    }
  }

  private onThumbnailUploadClick(): void {
    if (!this.isSaving) {
      // eslint-disable-next-line no-unused-expressions
      (this.$el.querySelector(`#thumbnail-uploader-${this.modalId}`) as HTMLElement)?.click();
    }
  }

  private onDeleteThumbnailClick(): void {
    if (!this.isSaving) {
      this.thumbnailDeleted = !!this.defaultThumbnail;
      this.thumbnailFile = null;
      this.localThumbnailPath = '';
    }
  }

  private setVideo(url: string): void {
    switch (this.entityType) {
      case EntityType.COMPANY:
        this.setVideoForExhibitor(url);
        break;
      case EntityType.USER:
        this.videoSetForUser(url);
        break;
      case EntityType.EXHIBITOR_VIDEO:
        this.videoSetForExhibitorVideo(url);
        break;
      default:
        break;
    }
  }

  private setVideoForExhibitor(url: string): void {
    const newUrl = url.trim();
    if (this.adminPanelExhibitor && this.adminPanelExhibitor.videoPresentationS3Url !== newUrl) {
      this.updateExhibitor({
        uid: this.adminPanelExhibitor.uid,
        attributes: {
          uid: this.adminPanelExhibitor.uid,
          videoPresentationS3Url: newUrl,
        },
      })
        .then((response) => {
          if (response) {
            this.setAdminPanelExhibitorVideoPresentationS3Url(response);
          }
          this.$emit('on-confirm', {
            selected: this.selected,
            url: newUrl,
            file: this.selected === 'upload' ? this.file : null,
          });
          this.onCancel();
          this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
        });
    }
  }

  private videoSetForUser(url: string): void {
    const newUrl = url.trim();
    this.authUser.videoPresentationS3Url = newUrl;
    this.updateUserProfile({ videoPresentationS3Url: newUrl });
    this.$emit('on-confirm', {
      selected: this.selected,
      url: newUrl,
      file: this.selected === 'upload' ? this.file : null,
    });
    this.onCancel();
    this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
  }

  private videoSetForExhibitorVideo(url: string): void {
    if (this.videoUid) {
      let exhibVideo: Partial<ExhibitorVideo> = { uid: this.videoUid };
      if (this.name && this.name.length > 0 && this.name.trim() !== this.defaultName) {
        exhibVideo = {
          ...exhibVideo,
          name: this.name,
        };
      }
      if (url && url.length > 0 && this.selected === 'url'
        && url.trim() !== this.defaultUrl) {
        exhibVideo = {
          ...exhibVideo,
          url,
        };
      }
      if (this.file && this.selected === 'upload') {
        this.setExhibitorVideo({
          ...exhibVideo,
          url,
        });
      } else {
        this.setExhibitorVideo(exhibVideo);
      }
    } else if (!this.videoUid) {
      if (this.file && this.selected === 'upload') {
        this.createExhibitorVideo({
          url,
          thumbnail: this.thumbnailFile as File,
          name: this.name,
        });
      } else {
        this.createExhibitorVideo({
          url: this.url,
          thumbnail: this.thumbnailFile as File,
          name: this.name,
        });
      }
    }
  }

  private createExhibitorVideo(payload: { url: string; name: string; thumbnail: File }): void {
    this.create({
      entity: {
        name: payload.name,
        url: payload.url,
      },
      exhibitorUid: this.exhibitorUid,
    })
      .then((video) => {
        if (video?.uid) {
          this.createThumbnail({
            thumbnail: payload.thumbnail,
            exhibitorVideoUid: video.uid,
            thumbnailDeleted: false,
            isNewVideo: true,
          });
        } else {
          this.$emit('on-confirm');
          this.onCancel();
        }
      });
  }

  private setExhibitorVideo(video: Partial<ExhibitorVideo>): void {
    this.update(video)
      .then(() => {
        this.createThumbnail({
          thumbnail: this.thumbnailFile,
          exhibitorVideoUid: this.videoUid,
          thumbnailDeleted: this.thumbnailDeleted,
          isNewVideo: false,
        });
      });
  }

  private createThumbnail(payload: {
    thumbnail: File | null | undefined;
    exhibitorVideoUid: string;
    thumbnailDeleted: boolean;
    isNewVideo: boolean;
  }): void {
    if (payload.thumbnail) {
      const readerThumbnail = new FileReader();
      readerThumbnail.readAsDataURL(payload.thumbnail);
      readerThumbnail.onload = (): void => {
        UploadFileHandler.uploadFileResourceHandler({
          schemaCode: this.community.code,
          fileName: payload.thumbnail?.name,
          base64Path: readerThumbnail.result as string,
        })
          .then((request) => {
            request.onload = (): void => {
              if (request?.status !== 413) {
                if (this.defaultThumbnail) {
                  this.deleteFileResource(this.defaultThumbnail.uid);
                }
                const uploadToken = JSON.parse(request.response)?.uploadToken;
                if (uploadToken) {
                  this.createThumbnailFileResourceForExhibitorVideo({
                    uid: payload.exhibitorVideoUid,
                    uploadToken,
                    fileName: payload.thumbnail?.name ?? '',
                    fileType: payload.thumbnail?.type ?? '',
                  })
                    .then(() => {
                      if (payload.isNewVideo) {
                        this.addNewAction({ type: ToastActionType.CREATE_EXHIBITOR_VIDEO });
                      } else {
                        this.addNewAction({ type: ToastActionType.UPDATE_EXHIBITOR_VIDEO });
                      }
                      this.$emit('on-confirm');
                    });
                } else {
                  if (payload.isNewVideo) {
                    this.addNewAction({ type: ToastActionType.CREATE_EXHIBITOR_VIDEO });
                  } else {
                    this.addNewAction({ type: ToastActionType.UPDATE_EXHIBITOR_VIDEO });
                  }
                  this.$emit('on-confirm');
                }
              }
            };
          });
      };
    } else if (this.defaultThumbnail && payload.thumbnailDeleted) {
      this.deleteFileResource(this.defaultThumbnail.uid)
        .then(() => {
          if (payload.isNewVideo) {
            this.addNewAction({ type: ToastActionType.CREATE_EXHIBITOR_VIDEO });
          } else {
            this.addNewAction({ type: ToastActionType.UPDATE_EXHIBITOR_VIDEO });
          }
          this.$emit('on-confirm');
        });
    } else {
      if (payload.isNewVideo) {
        this.addNewAction({ type: ToastActionType.CREATE_EXHIBITOR_VIDEO });
      } else {
        this.addNewAction({ type: ToastActionType.UPDATE_EXHIBITOR_VIDEO });
      }
      this.$emit('on-confirm');
    }
  }

  private onSelectionClick(selected: string): void {
    if (!this.isSaving) {
      this.url = '';
      this.file = null;
      this.selected = selected;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private isUrlValid(url: string): boolean {
    return RouteHelper.isUrlValid(url);
  }
}
