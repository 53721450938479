
























































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  format, fromUnixTime, isAfter, isBefore, isValid, isWithinInterval,
} from 'date-fns';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import CommunityUser from '@/models/graphql/CommunityUser';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import { deepGet } from '@/utils/ObjectHelpers';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import GroupType from '@/utils/enums/chat/GroupType';
import SendConnectionRequestModal from '@/components/modals/SendConnectionRequestModal.vue';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import EntityType from '@/utils/enums/EntityType';
import ActionRule from '@/utils/enums/ActionRule';
import ActionRuleService from '@/services/ActionRuleService';
import { CommunityUserFollowFilter } from '@/graphql/_Filters/CommunityUserFollowFilter';
import CommunityUserFollow from '@/models/graphql/CommunityUserFollow';
import Community from '@/models/graphql/Community';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import ExhibitHall from '@/models/graphql/ExhibitHall';
import Session from '@/models/graphql/Session';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');
const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');
const communityUserConnectionStore = namespace('CommunityUserConnectionStore');
const communityUserFollowStore = namespace('CommunityUserFollowStore');

@Component({
  components: {
    SendConnectionRequestModal,
    FontAwesomeComponent,
    ConfirmModal,
    AvatarSoloWidget,
  },
  inheritAttrs: false,
})
export default class EntityListItemComponent extends Vue {
  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserFollowStore.Action
  follow!: (payload: CommunityUserFollowFilter) => Promise<CommunityUserFollow | undefined>;

  @communityUserFollowStore.Action
  unFollow!: (payload: CommunityUserFollowFilter) => Promise<CommunityUserFollow | undefined>;

  @communityUserAgendaEntryStore.Action
  addToAgenda!: (
      payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  removeFromAgenda!: (
      payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserConnectionStore.Action
  fullyConnect!: (payload: {
    connectionArgs: object; newConversationArgs: object;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  fullyDisconnect!: (payload: {
    uid: string;
    messageLookupArgs: object;
    toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ default: 'DependencyWidgetStore' })
  private readonly context!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly actions!: { key: string; value: string }[];

  @Prop({
    required: false,
    default: false,
  })
  private readonly archived!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly alignLeft!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly hover!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  private readonly prefix!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly suffix!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly authUser!: CommunityUser;

  @Prop({
    required: false,
    default: null,
  })
  private readonly uid!: string;

  @Prop({
    required: false,
    default: 0,
  })
  private readonly id!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly title!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly type!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly logo!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly categories!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly code!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly firstName!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly lastName!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly isProfileVisible!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly jobTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly employerName!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly companyId!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly companyName!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly articleSubTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly sponsorSubTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly topicSubTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly genericSubTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly fileUrl!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly fileResourcePath!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly company!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly startTimestamp!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly endTimestamp!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly bookmarked!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly followed!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly visited!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly agenda!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly ourConnection!: CommunityUserConnection;

  @Prop({
    required: false,
    default: null,
  })
  private readonly actionButton!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly autoFollow!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly selected!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly selectable!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly relatedEntity!: { uid: string; entityType: string };

  @Prop({
    required: false,
    default: false,
  })
  private readonly noPadding!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly edition!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly exhibitHall!: ExhibitHall | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly geozoneSessions!: Session[] | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly objectType!: string | null;

  @Getter
  private community!: Community;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private connection: CommunityUserConnection | null = this.ourConnection;

  private isBookmarked = this.bookmarked;

  private isFollowed = this.followed;

  private isInAgenda = this.agenda;

  private FeatureKeys = FeatureKeys;

  private ActionRule = ActionRule;

  private ActionRuleService = ActionRuleService;

  private window = window;

  private actionButtonChoices = {
    bookmark: 'bookmark',
    follow: 'follow',
    addToAgenda: 'addToAgenda',
    connect: 'connect',
  };

  private get subtitle(): string {
    const today = new Date();
    const start = fromUnixTime(this.startTimestamp);
    const end = fromUnixTime(this.endTimestamp);

    if (this.type === 'CommunityUser' && this.isProfileVisible) {
      const temp = [];
      if (this.jobTitle) {
        temp.push(this.jobTitle);
      }
      if (this.companyName) {
        temp.push(this.companyName);
      } else if (this.employerName) {
        temp.push(this.employerName);
      }
      return temp.join(` ${this.$t('entity-list-item.at')} `)
        .trim();
    }
    if (this.type === EntityType.PRODUCT || this.type === EntityType.LARGE_PRODUCT) {
      return this.company;
    }
    if (this.type === EntityType.ARTICLE) {
      return this.articleSubTitle !== 'null' ? this.articleSubTitle : '';
    }
    if (this.type === EntityType.SPONSOR) {
      return this.sponsorSubTitle !== 'null' ? this.sponsorSubTitle : '';
    }
    if (this.type === EntityType.TOPIC) {
      return this.topicSubTitle !== 'null' ? this.topicSubTitle : '';
    }
    if (this.type === EntityType.CATEGORY || this.type === EntityType.GEOZONE) {
      if (this.type === EntityType.GEOZONE && this.objectType === 'Room' && this.geozoneSessions) {
        let currentSession = this.geozoneSessions.find((session: Session) => this.currentGeozoneSessionIsLive(session));
        if (currentSession) {
          return `${this.currentGeozoneSession(currentSession)}, ${currentSession.name}` || '';
        }
        currentSession = this.geozoneSessions.find((session: Session) => this.currentGeozoneSessionIsUpcoming(session));
        return currentSession ? `${this.currentGeozoneSession(currentSession)}, ${currentSession.name}` || '' : '';
      }
      return this.genericSubTitle !== 'null' ? this.genericSubTitle.split(/<[^>]*>/).join('') : '';
    }
    if (this.type === EntityType.DEAL) {
      if (isValid(start)
          && isValid(end)
          && isBefore(start, end)
          && isWithinInterval(today, {
            start,
            end,
          })) {
        return `${this.$tc('deal-card-component.valid-until', 0, {
          parsedEndDate: DateTimeHelper.formatFromUnixTime(
            this.endTimestamp,
            `${this.$t('app.date.defaultDateFormat')}`,
            this.dateLocale,
          ),
        })}`;
      }
      if (isAfter(today, end)) {
        return `${this.$t('deal-card-component.ended')}`;
      }
      if (isBefore(today, start)) {
        return this.todayIsBeforeStartSubtitleFormat();
      }
    }
    if (this.type === EntityType.EXHIBITOR) {
      return this.categories;
    }
    if (this.type === EntityType.SESSION || this.type === EntityType.SUB_EDITION) {
      if (this.archived) {
        return `${this.$t('actions.archived')}`;
      }
      if (isValid(start)
          && isValid(end)
          && isBefore(start, end)) {
        if (isWithinInterval(today, {
          start,
          end,
        })) {
          return `${this.$tc('actions.live')}`;
        }
        if (isValid(start)
            && isValid(end)
            && isAfter(today, end)) {
          return `${this.$t('actions.archived')}`;
        }
        if (isValid(start)
            && isValid(end)
            && isBefore(today, start)) {
          return this.todayIsBeforeStartSubtitleFormat();
        }
      }
    }
    return '';
  }

  private get isAccepted(): boolean {
    return this.connection?.linkState === ConnectionStatusType.ACCEPTED;
  }

  private get isInvited(): boolean {
    return this.connection?.linkState === ConnectionStatusType.INVITED;
  }

  private get avatarDefaultIcon(): string {
    if (this.type === EntityType.SESSION) {
      return 'far fa-calendar-star';
    }
    if (this.type === EntityType.ARTICLE) {
      return 'far fa-newspaper';
    }
    if (this.type === EntityType.GEOZONE) {
      return 'far fa-location-dot';
    }
    return 'far fa-buildings';
  }

  private currentGeozoneSessionIsLive(session: Session): boolean {
    const today = DateTimeHelper.utcToZonedTimeDate(
      DateTimeHelper.getCurrentDateTime(),
      this.selectedTzName,
    );
    const startTime = DateTimeHelper.utcToZonedTimeDate(
      `${session.startTime}Z`,
      this.selectedTzName,
    );
    const endTime = DateTimeHelper.utcToZonedTimeDate(
      `${session.endTime}Z`,
      this.selectedTzName,
    );

    if (session.startTime
      && session.endTime
      && isBefore(startTime, endTime)
      && isWithinInterval(today, {
        start: startTime,
        end: endTime,
      })) {
      return true;
    }
    return false;
  }

  private currentGeozoneSessionIsUpcoming(session: Session): boolean {
    const today = DateTimeHelper.utcToZonedTimeDate(
      DateTimeHelper.getCurrentDateTime(),
      this.selectedTzName,
    );
    const startTime = DateTimeHelper.utcToZonedTimeDate(
      `${session.startTime}Z`,
      this.selectedTzName,
    );

    return !!(session.startTime && isBefore(today, startTime));
  }

  private currentGeozoneSession(session: Session): string | null {
    const startTime = DateTimeHelper.utcToZonedTimeDate(
      `${session.startTime}Z`,
      this.selectedTzName,
    );
    if (this.currentGeozoneSessionIsLive(session)) {
      return `${this.$t('entity-list-item.geozone.current-session')} ${format(
        startTime,
        `${this.$t('app.date.dayOfWeek')}, ${this.$t('app.date.defaultDateFormat')}`,
        { locale: this.dateLocale },
      )}`;
    }
    if (this.currentGeozoneSessionIsUpcoming(session)) {
      return `${this.$t('entity-list-item.geozone.upcoming-session')} ${format(
        startTime,
        `${this.$t('app.date.dayOfWeek')}, ${this.$t('app.date.defaultDateFormat')}`,
        { locale: this.dateLocale },
      )}`;
    }
    return null;
  }

  private todayIsBeforeStartSubtitleFormat(): string {
    const startMonthlyDayMedium = DateTimeHelper.formatFromUnixTime(
      this.startTimestamp,
      `${this.$t('app.date.monthDayShort')}`,
      this.dateLocale,
    );
    const endMonthlyDayMedium = DateTimeHelper.formatFromUnixTime(
      this.endTimestamp,
      `${this.$t('app.date.monthDayShort')}`,
      this.dateLocale,
    );
    const startTimeZoned = DateTimeHelper.utcToZonedTimeDate(
      fromUnixTime(this.startTimestamp),
      this.selectedTzName,
    );
    const formatStartTimeZoned = `${format(startTimeZoned, `${this.$t('app.date.monthDayShort')}`)}`;
    const formatEndTimeZoned = `${format(
      DateTimeHelper.utcToZonedTimeDate(
        fromUnixTime(this.endTimestamp),
        this.selectedTzName,
      ),
      `${this.$t('app.date.defaultTimeFormat')}`, { locale: this.dateLocale },
    )}`;
    const formatYearZoned = format(startTimeZoned, `${this.$t('app.date.year')}`,
      { locale: this.dateLocale });
    if (startMonthlyDayMedium === endMonthlyDayMedium) {
      if (this.type === EntityType.SESSION) {
        return `${formatStartTimeZoned} —
       ${format(startTimeZoned, `${this.$t('app.date.defaultTimeFormat')}`,
    { locale: this.dateLocale })} — ${formatEndTimeZoned}`;
      }
      const startTime = DateTimeHelper.formatFromUnixTime(
        this.startTimestamp,
        `${this.$t('app.date.defaultTimeFormat')}`,
        this.dateLocale,
      );
      const endTime = DateTimeHelper.formatFromUnixTime(
        this.endTimestamp,
        `${this.$t('app.date.defaultTimeFormat')}`,
        this.dateLocale,
      );
      return `${startMonthlyDayMedium} — ${startTime} — ${endTime}`;
    }

    const endYear = DateTimeHelper.formatFromUnixTime(
      this.endTimestamp,
      `${this.$t('app.date.year')}`,
      this.dateLocale,
    );
    if (this.type === EntityType.SESSION) {
      return `${formatStartTimeZoned} — ${formatEndTimeZoned} ${formatYearZoned}
       `;
    }
    return `${startMonthlyDayMedium} — ${endMonthlyDayMedium} ${endYear}`;
  }

  private toggleBookmark(): void {
    if (this.isBookmarked) {
      const temp = this.isBookmarked;
      this.isBookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.isBookmarked = temp;
        });
    } else {
      this.isBookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: this.type,
      })
        .then((response) => {
          this.isBookmarked = response?.uid || '';
          this.$logger.logMatomoStats(
            this.authUser,
                this.community.code as string,
                this.type,
                StatLoggerActions.ADD,
                '',
                -1,
                this.uid,
                StatLoggerCategories.BOOKMARK,
                this.$i18n.locale,
          );
        })
        .catch(() => {
          this.isBookmarked = null;
        });
    }
  }

  private toggleFollow(): void {
    if (this.isFollowed) {
      const temp = this.isFollowed;
      this.isFollowed = null;
      this.unFollow({ uid: temp })
        .catch(() => {
          this.isFollowed = temp;
        });
    } else {
      this.isFollowed = 'followed';
      this.follow({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: this.type,
      })
        .then((response) => {
          this.isFollowed = response?.uid || '';
          this.$logger.logMatomoStats(
            this.authUser,
                this.community.code as string,
                this.type,
                StatLoggerActions.ADD,
                '',
                -1,
                this.uid,
                StatLoggerCategories.FOLLOW,
                this.$i18n.locale,
          );
        })
        .catch(() => {
          this.isFollowed = null;
        });
    }
  }

  private toggleAgenda(): void {
    if (this.isInAgenda) {
      this.removeFromAgenda({ uid: this.isInAgenda });
      this.isInAgenda = null;
      this.$logger.logMatomoStats(
        this.authUser,
          this.community.code as string,
          EntityType.SESSION,
          StatLoggerActions.REMOVE_FROM_AGENDA,
          'removeAppointment',
          -1,
          this.uid,
          StatLoggerCategories.REMOVE,
          this.$i18n.locale,
      );
    } else {
      this.addToAgenda({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.SESSION,
      })
        .then((response) => {
          this.isInAgenda = response?.uid || '';
          this.$logger.logMatomoStats(
            this.authUser,
                this.community.code as string,
                EntityType.SESSION,
                StatLoggerActions.ADD_TO_AGENDA,
                'addAppointment',
                -1,
                this.uid,
                StatLoggerCategories.ADD,
                this.$i18n.locale,
          );
        });
    }
  }

  private toggleModal(): void {
    if (this.isInvited) return;
    if (this.isAccepted) {
      this.$bvModal.show(`entity-list-remove-contact-${this.uid}`);
    } else {
      this.$bvModal.show(`entity-list-issue-connection-request-${this.uid}`);
    }
  }

  private toggleContact(message: string | null = null): void {
    const auth = (({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }) => ({
      uid,
      firstName,
      lastName,
      pictureFileResource,
    }))(this.authUser) as CommunityUser;
    if (this.isAccepted) {
      const temp = this.ourConnection;
      this.connection = null;
      this.fullyDisconnect({
        uid: temp.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: this.uid,
            firstName: this.firstName,
            lastName: this.lastName,
            pictureFileResource: undefined,
          } as CommunityUser],
          groupType: [GroupType.DIRECT],
        },
        toastUserFullName: `${this.firstName} ${this.lastName}`,
      })
        .catch(() => {
          this.connection = temp;
        });
    } else {
      const temp = { ...this.connection } as CommunityUserConnection;
      temp.linkState = ConnectionStatusType.INVITED;
      this.connection = temp;
      this.fullyConnect({
        connectionArgs: {
          userId: this.authUser.uid,
          linkedUserId: this.uid,
          message,
        },
        newConversationArgs: {
          message,
          users: [CommunityUser.hydrate({ uid: this.uid }),
            CommunityUser.hydrate({ uid: this.authUser.uid })],
          tempId: '',
          messageTempId: '',
        },
      })
        .then((response) => {
          if (response) {
            this.connection = response;
            this.$logger.logMatomoStats(
              this.authUser,
                  this.community.code as string,
                  this.type,
                  StatLoggerActions.ADD,
                  '',
                  -1,
                  this.uid,
                  StatLoggerCategories.CONNECT,
                  this.$i18n.locale,
            );
          }
        })
        .catch(() => {
          this.connection = null;
        });
    }
  }

  private handleBaseUrl(): void {
    if (this.selectable) {
      this.$emit('on-click', this.uid);
    }
    if (this.type === 'Sponsor' && !this.companyId) {
      return;
    }
    switch (this.type) {
      case EntityType.HANDOUT:
        if (this.route) {
          let r = this.route;
          const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '')
                .trim();
              const newValue = deepGet(this.$props, prop);
              if (newValue) {
                r = r.replaceAll(m, newValue);
              }
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          window.open(r, '_blank');
        } else if (this.fileUrl !== 'null') {
          window.open(this.fileUrl, '_blank');
        } else {
          window.open(this.fileResourcePath, '_blank');
        }
        this.$logger.logMatomoStats(
          this.authUser,
            this.community.code as string,
            this.relatedEntity.entityType,
            StatLoggerActions.VIEW,
            'viewHandout',
            -1,
            this.relatedEntity.uid,
            StatLoggerCategories.HANDOUT,
            this.$i18n.locale,
        );
        break;
      case EntityType.GEOZONE:
        if (this.route && this.exhibitHall && this.edition) {
          const r = `${this.route.endsWith('/')
            ? this.route.trim()
              .slice(0, this.route.length - 1)
            // eslint-disable-next-line max-len
            : this.route.trim()}?edition=${this.edition}&hall=${this.exhibitHall.id}&filters[geozones][0]=${this.id}`;
          this.$router.push(r);
        }
        break;
      default:
        if (this.route) {
          let r = this.route;
          const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '')
                .trim();
              const newValue = deepGet(this.$props, prop);
              if (newValue) {
                r = r.replaceAll(m, newValue);
              }
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          } else if (r[r.length - 1] === '/') {
            r = `${r}${this.type === EntityType.SUB_EDITION ? this.code : this.uid}`;
          } else {
            r = `${r}/${this.type === EntityType.SUB_EDITION ? this.code : this.uid}`;
          }
          if (this.isMyProfile(r)) {
            this.$router.push({ name: 'my-profile' });
          } else {
            this.$router.push(r);
          }
        }
        break;
    }
  }

  private isMyProfile(route: string): boolean {
    return route === '/network/members'
        && this.$store.state.authUser
        && this.uid === this.authUser.uid;
  }
}
