var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'pill-widget',
    'pill-container d-flex',
    {'bg-neutral-n-2-background disabled': _vm.disabled},
    {'centered': _vm.centered},
    (_vm.contextVariant + " " + _vm.size),
    {'clickable cursor-pointer': _vm.hover},
    {'white-border': _vm.withBorders},
    ("" + _vm.cssClass)
  ],on:{"click":_vm.onClick}},[(_vm.DecorationEnum.IMAGE === _vm.decoration && _vm.img)?_c('avatar-solo-widget',{attrs:{"size":_vm.avatarSize,"src":_vm.img,"with-borders":"false"}}):_vm._e(),(_vm.DecorationEnum.ICON === _vm.decoration && _vm.icon)?_c('font-awesome-component',{attrs:{"container-classes":[{ 'icon-24': _vm.sizeEnum.MEDIUM === _vm.size }],"icon":_vm.icon.iconDef,"icon-classes":_vm.icon.iconClass}}):_vm._e(),_vm._t("left-icon"),(_vm.DecorationEnum.NUMBER === _vm.decoration)?_c('span',{staticClass:"number label label-5"},[_vm._v(" "+_vm._s(_vm.number)+" ")]):_vm._e(),(_vm.label)?_c('p',{ref:"pillText",class:[
      'p-0 m-0 text-left text pillText',
      {'text-neutral-n-5-placeholder': _vm.disabled},
      {'text-nowrap': !_vm.textWrap},
      _vm.size === _vm.sizeEnum.SMALL
        ? 'label label-3'
        : _vm.size === _vm.sizeEnum.MICRO
          ? 'label label-4'
          : _vm.size === _vm.sizeEnum.NANO
            ? 'label label-5'
            : ''
    ]},[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]):_vm._e(),(_vm.showTooltip)?_c('b-tooltip',{attrs:{"target":function () { return _vm.$refs['pillText']; },"title":_vm.$t(_vm.label),"custom-class":"tooltip-style","placement":"top","triggers":"hover"},on:{"shown":_vm.runMathJax}}):_vm._e(),(_vm.clearable)?_c('div',[_c('font-awesome-component',{attrs:{"container-classes":[_vm.sizeEnum.SMALL === _vm.size ? 'icon-16' : 'icon-24'],"icon-classes":{ 'icon-12': _vm.sizeEnum.SMALL === _vm.size },"stop-propagation":true,"icon":"fa-regular fa-xmark"},on:{"on-click":function($event){return _vm.$emit('on-clear')}}})],1):_vm._e(),_vm._t("right-icon")],2)}
var staticRenderFns = []

export { render, staticRenderFns }