var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay
    && (_vm.title || _vm.subtitle || _vm.search))?_c('div',{class:['page-static-title-widget d-flex flex-column row-gap-2 container',
           _vm.classList,
           { 'mx-0': _vm.isUnifyExhibitorPortal },
           { 'text-left justify-content-start': ['left', 'default', 'custom'].includes(_vm.alignment)},
           { 'text-center justify-content-center': _vm.alignment === 'center' },
           { 'text-right justify-content-end': _vm.alignment === 'right' }]},[(_vm.title)?_c('title-component',{staticClass:"m-0 w-100",attrs:{"title":_vm.title,"title-size":_vm.titleSize || 'title1Bold'}}):_vm._e(),(_vm.subtitle)?_c('p',{staticClass:"text-neutral-n-6-label m-0"},[_vm._v(" "+_vm._s(_vm.$t(_vm.subtitle))+" ")]):_vm._e(),(_vm.search)?_c('input-search-component',{staticClass:"rounded-pill mt-3 col-12 col-md-8 col-xl-6",attrs:{"placeholder":("" + (_vm.placeholder
      ? _vm.$t(_vm.placeholder)
      : _vm.$t('app.home-page.search-placeholder'))),"size":_vm.isMobile ? 'md' : 'lg',"color":"gray","default-value":""},on:{"search-query-enter-key":_vm.onSearch}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }