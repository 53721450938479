import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import CommunityUser from '@/models/graphql/CommunityUser';
import { BasicTypes } from '@/utils/types/BasicTypes';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import Meeting from '@/models/graphql/Meeting';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import MeetingRepository from '@/repositories/MeetingRepository';
/* eslint-disable @typescript-eslint/camelcase */
@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ExhibitorPortalUserDetailChatWidgetStore extends WidgetBaseStore {
  private readonly meetingRepository = new MeetingRepository();

  private companyReps: CommunityUser[] = [];

  private selectedUsers: string[] = [];

  private search = '';

  private meetings: Meeting[] = [];

  private filter: object | null = null;

  private memberUid: string | null = null;

  private companyUid: string | null = null;

  @Action
  setDataConfigs(): void {
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    if (this.payload && this.payload.memberUid && this.payload.companyUid) {
      this.context.commit('setMemberId', WidgetHelper.entityCode(
        this.payload.memberUid as string,
        args.route as unknown as Location,
        null,
      ));
      this.context.commit('setCompanyId', WidgetHelper.entityCode(
        this.payload.companyUid as string,
        args.route as unknown as Location,
        null,
      ));
      this.context.commit('constructFilter');
      configs.push(...[{
        alias: `${this.widgetStoreName}_companyReps`,
        operation: 'communityUser',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
            value: {
              companyRoles: {
                company: {
                  uid: this.companyUid,
                },
                OR: [
                  { role: { code: 'owner' } },
                  {
                    state: 'ACCEPTED',
                    role: { code: 'administrator' },
                    handleMeetings: true,
                  },
                ],
              },
            },
          },
        }),
        fragmentName: 'communityUserBaseFragment',
      },
      {
        alias: `${this.widgetStoreName}_meetings`,
        operation: 'meeting',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.MEETING_FILTER,
            value: this.filter,
          },
          orderBy: {
            type: GqlEntityOrderingType.MEETING_ORDERING,
            value: ['startTimestamp_asc'],
          },
        }),
        fragmentName: 'meetingFullFragment',
      }]);
    }
    this.context.commit('setGqlQueryConfig', configs);
    this.context.commit(
      'WidgetDispatcherStore/setGqlQueryConfigs',
      {
        key: this.widgetStoreName,
        values: configs,
      },
      { root: true },
    );
  }

  @Action
  loadMeetings(): Promise<void> {
    this.context.commit('constructFilter');
    return this.meetingRepository.filter({
      operationName: 'LoadEpUserDetailMeetings',
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.MEETING_FILTER,
          value: this.filter,
        },
        orderBy: {
          type: GqlEntityOrderingType.MEETING_ORDERING,
          value: ['startTimestamp_asc'],
        },
      }),
      authUser: this.context.rootState.authUser?.uid as string,
    })
      .then((meetings) => {
        this.context.commit('setMeetings', meetings);
      })
      .catch(() => {
        this.context.commit('setMeetings', []);
      });
  }

  @Mutation
  constructFilter(): void {
    this.filter = {
      meetingRequest: { exhibitor: { uid: this.companyUid } },
      OR: [
        { creatorUser: { uid: this.memberUid } },
        { participants: { user: { uid: this.memberUid } } },
      ],
    };

    const AND: object[] = [];

    if (this.selectedUsers.length > 0) {
      AND.push({
        OR: [
          { creatorUser: { uid_in: this.selectedUsers } },
          { participants: { user: { uid_in: this.selectedUsers } } },
        ],
      });
    }

    if (this.search.length > 0) {
      AND.push({
        OR: [
          {
            creatorUser: {
              name_contains: this.search,
            },
          },
          {
            participants: {
              user: {
                name_contains: this.search,
              },
            },
          },
          {
            subject_contains: this.search,
          },
          {
            description_contains: this.search,
          },
          {
            location_contains: this.search,
          },
        ],
      });
    }

    if (AND.length > 0) {
      Object.assign(this.filter, { AND });
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      companyReps: this.companyReps,
      meetings: this.meetings,
      companyUid: this.companyUid,
      memberUid: this.memberUid,
    };
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.key.endsWith('_companyReps')) {
      this.companyReps = (data.values as unknown as Array<CommunityUser>).map((u) => CommunityUser.hydrate(u));
    }
    if (data.key.endsWith('_meetings') && data.values.length > 0) {
      this.meetings = data.values as unknown as Meeting[];
    }
  }

  @Mutation
  setCompanyId(val: string): void {
    this.companyUid = val;
  }

  @Mutation
  setMemberId(val: string): void {
    this.memberUid = val;
  }

  @Mutation
  private setSelectedUsers(users: string[]): void {
    this.selectedUsers = Array.from(new Set(users));
  }

  @Mutation
  private setSearchableString(str: string): void {
    this.search = str;
  }

  @Mutation
  private setMeetings(meetings: Meeting[]): void {
    this.meetings = meetings;
  }
}
