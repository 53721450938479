














import { Component } from 'vue-property-decorator';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import AddressFormComponent from '@/components/admin-panel/AddressFormComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { namespace } from 'vuex-class';

const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    AddressFormComponent,
  },
})
export default class CompanyCmsProfileAddressWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget) {
  @permissionManagerStore.Getter
  private canManageCompanyContent!: (companyUid: string) => boolean;

  private get canManageCompanyInformation(): boolean {
    if (this.adminPanelExhibitor) {
      return this.canManageCompanyContent(this.adminPanelExhibitor.uid);
    }
    return false;
  }

  created(): void {
    this.setDataConfig();
  }
}
