
















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { Getter } from 'vuex-class';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import SliderArrowComponent from '@/components/SliderArrowComponent.vue';
import TitleComponent from '@/components/TitleComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import WidgetCardMapping from '@/utils/widgets/WidgetCardMapping';
import RouteHelper from '@/utils/helpers/RouteHelper';
import components from '@/utils/widgets/WidgetComponents';

@Component({
  components: {
    ButtonComponent,
    SliderArrowComponent,
    Splide,
    SplideSlide,
    TitleComponent,
  },
})
export default class SectionComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  readonly title!: string;

  @Prop({ required: false, default: '' })
  readonly subtitle!: string;

  @Prop({ required: false, default: '' })
  readonly anchor!: string;

  @Prop({ required: false, default: 'Stack' })
  readonly display!: string;

  @Prop({ required: false, default: 255 })
  readonly slideSize!: number;

  @Prop({ required: false, default: 'title2' })
  readonly titleSize!: string;

  @Prop({ required: false, default: null })
  readonly items!: UiPageWidget[];

  @Prop({ required: false, default: true })
  readonly defaultGaps!: boolean;

  @Prop({ required: false, default: '' })
  readonly uid!: string;

  @Getter
  protected readonly isLoading!: boolean;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  @Prop({ required: false, default: false })
  private isUserPrivate!: boolean;

  @Prop({ required: false, default: false })
  private isPrivate!: boolean;

  private showArrows = true;

  private showSection = true;

  private WidgetCardMapping = WidgetCardMapping;

  private RouteHelper = RouteHelper;

  private component: object | string = 'div';

  private get slideOptions(): object {
    return {
      fixedWidth: parseInt(`${this.slideSize}`, 10),
      gap: 30,
      start: 0,
      perMove: 1,
      trimSpace: 'move',
      focus: 0,
      loop: true,
      rewind: true,
      pagination: false,
      cover: true,
    };
  }

  private get insideTabItem(): boolean {
    return !!(this.widget
      && this.widget.parentWidget
      && this.widget.parentWidget.type === 'destination_tab_item_list_panel');
  }

  mounted(): void {
    this.updateStyles();

    this.$eventsBus.on('on-hide-section-widget', (event, childUid) => {
      if (this.widget.subWidgets?.find((w) => w.uid === childUid)) {
        this.showSection = false;
      }
    });
    if (!this.isLoading
      && this.anchor
      && this.$route.hash
      && this.$route.hash.includes(this.anchor)) {
      this.$nextTick(() => {
        const el = document.getElementById(this.anchor);
        if (el) {
          el.scrollIntoView({ block: 'start', inline: 'start', behavior: 'auto' });
        }
      });
    }
  }

  private get childWidgets(): UiPageWidget[] {
    return this.items || this.widget.subWidgets;
  }

  @Watch('display')
  private loadComponents(): void {
    if (!(this.items && this.items.length) && this.display === 'Carousel') {
      this.widget.subWidgets.forEach((widget: UiPageWidget) => {
        this.itemComponent(widget);
      });
    }
  }

  private itemComponent(item: UiPageWidget): void {
    const { code } = JSON.parse(item.payload || '{}');
    if (code
      && Object.keys(components)
        .includes(code as string)) {
      const path = components[code as string];
      import(`@/components/${path}`)
        .then((c: { default: object }) => {
          this.component = c.default;
        });
    }
  }

  created(): void {
    this.setDataConfig();
  }

  private nextClick(): void {
    const arrowNextEl = document.querySelector(`.slide-${this.uid} .splide__arrow--next`) as HTMLElement;
    if (arrowNextEl) {
      arrowNextEl.click();
    }
  }

  private prevClick(): void {
    const arrowPrevEl = document.querySelector(`.slide-${this.uid} .splide__arrow--prev`) as HTMLElement;
    if (arrowPrevEl) {
      arrowPrevEl.click();
    }
  }

  private updateStyles(): void {
    const container = this.$el.querySelector('.splide__track') as HTMLElement;
    const list = this.$el.querySelector('.splide__list') as HTMLElement;

    if (container && list) {
      const containerWidth = container.offsetWidth;
      let totalFlexItemsWidth = 0;
      const flexItems = list.children;
      const { gap } = this.slideOptions as { gap: number };
      for (let i = 0; i < flexItems.length; i++) {
        totalFlexItemsWidth += (flexItems[i] as HTMLElement).offsetWidth + gap;
      }
      this.showArrows = totalFlexItemsWidth - gap > containerWidth;
    }
  }
}
