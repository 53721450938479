import { FetchResult } from '@apollo/client';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import createInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';

/* eslint-disable max-len */
export default class CommunityUserAgendaEntryRepository extends GraphQlRepository<CommunityUserAgendaEntry>
  implements createInterface<CommunityUserAgendaEntry>, DeleteInterface<CommunityUserAgendaEntry> {
  create(params: GqlPayloadParams): Promise<CommunityUserAgendaEntry | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUserAgendaEntry',
      operation: 'CommunityUserAgendaEntryCreateForUserAndLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserAgendaEntryFullFragment',
      alias: 'CommunityUserAgendaEntryCreate',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        CommunityUserAgendaEntryCreate: CommunityUserAgendaEntry;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          CommunityUserAgendaEntryCreate: CommunityUserAgendaEntry;
        }>) => response.data?.CommunityUserAgendaEntryCreate));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserAgendaEntry | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteCommunityUserAgendaEntry',
      operation: 'CommunityUserAgendaEntryDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserAgendaEntryFullFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        CommunityUserAgendaEntryDelete: CommunityUserAgendaEntry;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          CommunityUserAgendaEntryDelete: CommunityUserAgendaEntry;
        }>) => response.data?.CommunityUserAgendaEntryDelete));
  }
}
