import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const CATEGORY_ENTITY_LIST_FRAGMENT = `
  fragment categoryEntityListFragment on Category {
    id
    uid
    __typename
    schemaCode
    name
    description
    displaySequence
    bannerFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
